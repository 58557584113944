
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Equipment, Facility } from "@/survey";
import { getEquipmentType } from "@/store";
import haversine from "s-haversine";
import EquipmentForm from "@/views/EquipmentForm.vue";

declare const google: any;
@Component({
  components: {
    EquipmentForm
  }
})
export default class FacilityEquipmentMap extends Vue {
  @Prop({ default: [] }) equipment!: Equipment[];
  @Prop(Object) facility!: Facility;
  @Prop({ default: "satellite" }) mapType!: string;
  @Prop({ default: 17 }) zoom!: number;
  private map: any | undefined;
  private centerMarker: any | undefined;
  private markers: any[] = [];
  private bounds: any | undefined;
  private fitAndPanCalled = false;

  constructor() {
    super();
    this.bounds = new google.maps.LatLngBounds();
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
  }

  openModal(id: string) {
    this.$bvModal.show(id);
  }

  isWriter() {
    const user = this.$store.state.authentication.currentUser;
    return user === null ? false : user.admin || user.edit;
  }

  // @Watch("facility")
  // onPropertyChangedFacility() {
  //   this.createMap("facility");
  // }

  // @Watch("equipment")
  // onPropertyChangedEquiopment() {
  //   this.createMap("equipment");
  // }

  //
  mounted() {
    this.createMap("mounted");
  }

  //
  // updated() {
  //   this.createMap("updated");
  // }

  fitAndPan() {
    // if (!this.fitAndPanCalled) {
    //   this.map.setCenter(this.centerMarker.position)
    // this.map.fitBounds(this.bounds, 0);
    // const ne = this.bounds.getNorthEast();
    // const sw = this.bounds.getSouthWest();
    //
    // const boundingBox = new google.maps.Polyline({
    //   path: [
    //     ne, new google.maps.LatLng(ne.lat(), sw.lng()),
    //     sw, new google.maps.LatLng(sw.lat(), ne.lng()), ne
    //   ],
    //   strokeColor: '#FF0000',
    //   strokeOpacity: 1.0,
    //   strokeWeight: 2
    // });
    //
    // boundingBox.setMap(this.map);
    // this.map.panToBounds(this.bounds, 0);
    // }
    // google.maps.event.trigger(this.map, 'resize');
  }

  createMap(caller: string) {
    if (this.$props.facility && this.map === undefined) {
      const mapCenter = { lat: this.$props.facility.lat, lng: this.$props.facility.lng };
      console.log("Map center", mapCenter);
      this.map = new google.maps.Map(this.$refs.facilitymap, {
        center: mapCenter,
        tilt: 100,
        zoom: this.zoom,
        mapTypeId: this.mapType
      });

      this.centerMarker = new google.maps.Marker({
        position: {
          lat: this.$props.facility.lat,
          lng: this.$props.facility.lng
        },
        animation: google.maps.Animation.DROP,
        draggable: false,
        map: this.map
      });
      this.bounds.extend(
          new google.maps.LatLng(
              this.$props.facility.lat,
              this.$props.facility.lng
          ),
          0
      );
    }
    if (this.$props.equipment && this.$props.equipment.length > 0) {
      // console.log("Equipment", this.$props.equipment);
      this.markers.forEach(m => {
        m.setMap(null);
        m = null;
      });
      this.markers = [];
      this.$props.equipment.forEach((e: Equipment) => {
        const et = getEquipmentType(e.equipmentType);
        //console.log("ET", et);
        const icon = et.iconSvg === null || et.iconSvg.length === 0 ? null : {
          url: 'data:image/svg+xml;utf-8,' + encodeURIComponent(et.iconSvg),
          scaledSize: new google.maps.Size(25, 25),
          fillColor: et.color,
          optimized: false,
          fillOpacity: 1,
          anchor: new google.maps.Point(0, 0),
          strokeWeight: 0,
          scale: 1.5
        };
        if (e.lat === null || e.lng === null || e.lat === 0 || e.lng === 0) {
          // Pass
          console.log(`Skipping equipment with null lat/lng ${e.name} - ${e.id}`);
          return;
        }

        //Original code for map marker
        /*
        const contentString =
            '<div id="content">' +
            '<div id="siteNotice">' +
            "</div>" +
            '<h1 id="firstHeading" class="firstHeading">' +
            e.name +
            "</h1>" +
            '<div id="bodyContent">' +
            `<a href="javascript:window.app.$bvModal.show('editEquipmentFormOnMap${e.id}')" class="link-opacity-50">Edit Equipment</a>` +
            "</div></div>";
          */

          const contentString =
              '<div id="content">'+
              '<div id="siteNotice">'+
              '</div>'+
              '<h4 id="firstHeading" class="firstHeading">' + e.name + '</h4>'+
              '<div id="bodyContent">' +
                '<table class="table table-sm ">' +
                    '<tbody>' +
                      '<tr>' +
                        '<th scope="row">Model: </th>' +
                        '<td>' + e.modelNo +'</td>' +
                        '</td></tr>' +
                        '<tr>' +
                        '<th colspan="2" scope="row">' +  
                        `<a href="javascript:window.app.$bvModal.show('editEquipmentFormOnMap${e.id}')" class="link-opacity-50">Equipment Detail</a>` +
                        '</th></tr>' +
                      '</tbody>' +
                '</table>' +
                '</div>'+
         '</div>';



        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });
        try {
          const markerPos = { lat: e.lat, lng: e.lng };
          console.log("Marker pos", markerPos);

          const marker = new google.maps.Marker({
            position: markerPos,
            surveyPosition: { lat: e.lat, lng: e.lng },
            surveyTable: "equipment",
            surveyId: e.id,
            map: this.map,
            icon: icon === null ? undefined : icon,
            zIndex: -20,
            draggable: this.$store.getters.canWrite,
            title: e.name
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false
            });
          });

          if (this.isWriter()) {
            marker.addListener("dragend", (event: any) => {
              const newLl = [event.latLng.lat(), event.latLng.lng()] as [
                number,
                number
              ];
              const oldLl = [
                marker.surveyPosition.lat,
                marker.surveyPosition.lng
              ] as [number, number];
              const distanceMeters = haversine.distance(newLl, oldLl);
              const lat = event.latLng.lat();
              const lng = event.latLng.lng();
              if (
                  confirm(
                      `Do you want to update this equipment address to new lat/lng (${lat}, ${lng})?\nMoved ${Math.round(
                          distanceMeters
                      )} Meters`
                  )
              ) {
                this.$store.dispatch(actions.UPDATE_LOCATION, {
                  lat: lat,
                  lng: lng,
                  recordId: marker.surveyId,
                  tableName: marker.surveyTable as
                      | "facility"
                      | "equipment"
                      | "gate"
                      | "fencing"
                });
                //console.log("Done");
              } else {
                marker.setPosition({
                  lat: oldLl[0],
                  lng: oldLl[1]
                });
              }
            });
          }
          this.markers.push(marker);
          this.bounds.extend(
              new google.maps.LatLng(
                  marker.position.lat(),
                  marker.position.lng()
              ),
              0
          );
          this.map.fitBounds(this.bounds);
        } catch (err) {
          console.error(`Error mapping ${err}`, e);
        }
      });
      this.fitAndPan();
    }
  }
}
