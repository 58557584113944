
import { Component, Vue } from "vue-property-decorator";
import { Tag } from "@/survey";
import { mapGetters, mapState } from "vuex";

@Component({
  computed: mapState(['punchTags'])
})
export default class PunchTagTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  punchTags?: Tag[]

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "tag", sortable: true },
      { key: "description", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  // get tags() {
  //   return this.$store.state.;
  // }
}
