
import { Component, Vue } from "vue-property-decorator";
import { DBInfo } from "@/survey";

@Component({
  components: {}
})
export default class DBInfoPage extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  client = Vue.prototype.$surveyClient
  dbinfo: null | DBInfo = null

  get fields() {
    return [
      { key: "t1", sortable: true, label: 'Table Name' },
      { key: "t2", sortable: true, label: 'Schema'},
      { key: "t3", sortable: true, label: 'Size mb'},
      { key: "t4", sortable: true, label: 'Rows' },
    ];
  }
  async created () {
    const dbinfo = await this.client.getDbInfo()
    this.dbinfo = dbinfo
  }

  get totalRows (){
    if(this.dbinfo == null ){ return 0; }
    let sum = 0;
    const dbi = this.dbinfo as DBInfo
    dbi.table_info.forEach( i  => {
      sum = sum + i.t4;
    });
    return sum
  }

  get totalSizeMB (){
    if(this.dbinfo == null ){ return 0; }
    let sum = 0;
    const dbi = this.dbinfo as DBInfo
    dbi.table_info.forEach( i  => {
      sum = sum + i.t3;
    });
    return sum
  }
}
