
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Fencing, Manufacturer, Tag } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";
import TagInput from "@/components/TagInput.vue";
import {
  addLocationToGeoLocatable,
  doNotAddLocationToGeoLocatable
} from "@/store";

function initialData(
  manufacturers: Manufacturer[],
  fencingList: Fencing[],
  id: number | null,
  formKey = 0
) {
  const manufacturerSelectData = manufacturers.map(
    (manufacturer: Manufacturer) => {
      return {
        value: manufacturer.id,
        text: `${manufacturer.company}`
      };
    }
  );
  for (const fencing of fencingList) {
    if (fencing.id === id) {
      return {
        tags: fencing.tags!.map((t: Tag) => t.tag),
        fencing,
        fencingImage: null,
        imageComment: "",
        manufacturerSelectData,
        formKey,
        useCurrentLocation: false
      };
    }
  }
  return {
    tags: [],
    fencing: {
      id: null
    },
    fencingImage: null,
    imageComment: "",
    manufacturerSelectData,
    formKey,
    useCurrentLocation: true
  };
}

@Component({
  components: { InputWithLabel, FileUploadInput, TagInput },
  validations: {
    fencing: {
      name: { required }
    }
  }
})
export default class FencingForm extends Vue {
  @Prop() facility?: number;
  @Prop() fencingId: number | null = null;

  disabledBoolean = false;

  disable() {
    this.disabledBoolean = true;
  }

  enable() {
    this.disabledBoolean = false;
  }

  data() {
    return initialData(
      this.$store.state.manufacturers,
      this.$store.state.fencing,
      this.$props.fencingId
    );
  }

  onSubmit() {
    this.$v.$touch();
    console.log("Invalid: " + this.$v.$invalid);
    console.log("VVVV", this.$v);

    if (this.$v.$invalid) {
      alert("Cannot save Fencing");
    } else {
      this.saveFencing();
    }
  }

  saveFencing() {
    const initialFunction: (item: Fencing) => Promise<Fencing> = this.$data
      .useCurrentLocation
      ? (item: Fencing) => addLocationToGeoLocatable<Fencing>(item)
      : (item: Fencing) => doNotAddLocationToGeoLocatable<Fencing>(item);
    this.disable();
    this.$bvToast.toast(`Saving...`, {
      title: "Saving Fencing",
      variant: "info",
      autoHideDelay: 3000,
      appendToast: true
    });
    initialFunction(this.$data.fencing)
      .then((fencing: Fencing) => {
        this.$store.dispatch(actions.CREATE_OR_UPDATE_FENCING, {
          toSave: { ...fencing, facility: this.$props.facility },
          tags: this.$data.tags,
          image: this.$data.fencingImage,
          imageComment: this.$data.imageComment,
          success: () => {
            this.$bvToast.toast(`Success`, {
              title: "Fencing Saved!",
              variant: "success",
              autoHideDelay: 3000,
              appendToast: true
            });
            this.$store.dispatch(actions.GET_FENCING, {
              facility: this.$props.facility
            });
            this.$emit("complete");
            const getData = () =>
              initialData(
                this.$store.state.manufacturers,
                this.$store.state.fencing,
                parseInt(this.$route.params.id),
                this.$data.formKey + 1
              );
            Object.assign(this.$data, getData.apply(this));
          }
        });
      })
      .catch(positionError => alert(`Error: ${positionError}`));
  }
}
