
import { Component, Prop, Vue } from "vue-property-decorator";
import FacilityList from "@/views/FacilityList.vue";

@Component({
  components: { FacilityList }
})
export default class FacilitiesCard extends Vue {
  @Prop() private msg!: string;
  get facilities() {
    return this.$store.state.facilities;
  }
}
