import XlsxPopulate from "xlsx-populate";
import { getColumnLengths } from "@/export/util";

export const generateSpreadsheetSingleTableAndDownload = (
  data: string[][],
  filename: string
) => {
  XlsxPopulate.fromBlankAsync().then((workbook: any) => {
    const sheet = workbook.sheet("Sheet1");
    workbook
      .sheet(0)
      .cell("A1")
      .value(data);
    getColumnLengths(
      workbook
        .sheet("Sheet1")
        .usedRange()
        .value()
    ).forEach((length, col) => {
      sheet.column(col + 1).width(length + 2);
    });

    workbook.outputAsync("base64").then(function(base64: string) {
      const link = document.createElement("a") as HTMLAnchorElement;
      link.id = "dl";
      link.href = "data:" + XlsxPopulate.MIME_TYPE + ";base64," + base64;
      link.download = `${filename}`;
      link.click();
      link.remove();
    });
  });
};
