
import Vue from "vue";
import Component from "vue-class-component";
import { dateFormatter } from "@/components/formatters";
import { version } from '../../package.json';

@Component({})
export default class About extends Vue {
  get api() {
    return {
      version: this.$store.state.oauth.api_build_version,
      buildTime: dateFormatter()(this.$store.state.oauth.api_build_time)
    };
  }

  get client() {
    return {
      version: version
    };
  }
}
