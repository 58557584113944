
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput },
  validations: {
    selectConfig: {
      label: { required },
      value: { required }
    }
  }
})
export default class ListItemForm extends Vue {
  data() {
    const key = this.$route.params.key;
    const listName = this.$store.getters.listNameFromKey(key);
    try {
      const itemId = parseInt(this.$route.params.id);
      for (const c of this.$store.state.selectConfig) {
        if (c.id === itemId) {
          return {
            key,
            listName,
            selectConfig: { ...c }
          };
        }
      }
      throw "Cannot find select config for id " + itemId;
    } catch (e) {
      return {
        key,
        listName,
        selectConfig: {
          id: null,
          name: listName,
          key,
          label: "",
          value: ""
        }
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }
    this.$store.dispatch(actions.CREATE_OR_UPDATE_SELECT_CONFIG, {
      toSave: this.$data.selectConfig,
      success: () => this.$router.push("/admin/lists/" + this.$route.params.key)
    });
  }
}
