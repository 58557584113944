
import { Component, Vue } from "vue-property-decorator";
import GoogleMap from "@/components/GoogleMap.vue";
import AddressDisplay from "@/components/AddressDisplay.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import {
  actions,
  CalendarAttendee,
  CalendarEntry,
  Contact,
  Facility,
  PersonForMeeting
} from "@/survey";
import { getContactById, getFacilityById, getPeopleFromStore } from "@/store";
import ContactDisplayNameEmailCall from "@/components/ContactDisplayNameEmailCall.vue";
import { dateFormatter, facilityFormatter } from "@/components/formatters";

@Component({
  components: {
    ContactDisplayNameEmailCall,
    GoogleMap,
    AddressDisplay,
    DisplayImagesCarousel: DisplayImagesCarousel //, EventForm,
  }
})
export default class EventList extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  deleteEvent(id: number) {
    if (confirm("Are you sure you want to delete this event?")) {
      this.$store.dispatch(actions.DELETE_CALENDAR_ENTRY, {
        id,
        success: () => this.$router.push("/events")
      });
    }
  }

  data() {
    return {
      filter: ""
    };
  }

  getSubject(event: CalendarEntry): string {
    if (event.facility === undefined) {
      return "";
    }
    const facility = getFacilityById(event.facility);
    if (facility === undefined) {
      return "";
    }
    return (
      "Meeting at " +
      facility.name +
      (event.start !== undefined ? ` - ${dateFormatter()(event.start)}` : "")
    );
  }

  getContactForFacility(value: number): Contact | undefined {
    const facility = getFacilityById(value);
    if (
      facility === undefined ||
      facility.contact === null ||
      facility.contact === undefined
    ) {
      return undefined;
    }
    return this.getContactForId(facility.contact);
  }

  getFacilityForId(value: number): Facility | undefined {
    return getFacilityById(value);
  }

  getContactForId(value: number): Contact | undefined {
    return getContactById(value);
  }

  getAttendee(attendee: CalendarAttendee): PersonForMeeting | null {
    let foundPerson = null;
    getPeopleFromStore().forEach((person: PersonForMeeting) => {
      if (person.type === "contact" && attendee.contact === person.id) {
        foundPerson = person;
      }
      if (person.type === "user" && attendee.user === person.id) {
        foundPerson = person;
      }
    });
    if (foundPerson === null) {
      console.error("Cannot find meeting attendee!");
    }
    return foundPerson;
  }

  get fields() {
    return [
      { key: "detail", sortable: false },
      { key: "start", sortable: true, formatter: dateFormatter() },
      { key: "end", sortable: true, formatter: dateFormatter() },
      {
        key: "facility",
        sortable: true,
        formatter: facilityFormatter(this.getFacilityForId)
      },
      { key: "facility_contact", sortable: true },
      { key: "subject", sortable: true }
    ];
  }

  get events() {
    return this.$store.state.calendar;
  }
}
