
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Tag } from "@/survey";

@Component({})
export default class TagInput extends Vue {
  @Prop() tags?: string[];
  @Prop({ default: false }) readonly!: boolean;

  data() {
    const opts = this.$store.state.tags
      .map((tag: Tag) => tag.tag)
      .sort((a: string, b: string) => (a > b ? 1 : -1));
    return {
      options: opts,
      value: this.$props.tags
    };
  }

  @Watch("value")
  onChildChanged(val: string[], oldVal: string[]) {
    this.$emit("update:tags", val);
  }

  get availableOptions() {
    return this.$store.state.tags
      .map((tag: Tag) => {
        if (this.$data.value.indexOf(tag.tag) === -1) {
          return tag.tag;
        }
      })
      .filter((val: any) => val !== undefined)
      .sort((a: any, b: any) => (a > b ? 1 : -1));
  }
}
