
import { Component, Vue } from "vue-property-decorator";
import PdfGenerator from "@/export/pdf";
import {
  getContactFieldsForExport,
  getContactHeaderFields,
  getFacilityFieldValuesForExport,
  getFacilityHeaderFields
} from "@/export/util";
import { generateSpreadsheetSingleTableAndDownload } from "@/export/excel";

@Component({
  components: {}
})
export default class ContactTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "firstName", sortable: true },
      { key: "lastName", sortable: true },
      { key: "title", sortable: true },
      { key: "company", sortable: true },
      { key: "email", sortable: true },
      { key: "phone", sortable: true },
      { key: "location", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get contacts() {
    return this.$store.state.contacts;
  }

  exportTableData() {
    const table: any = this.$refs.theTable;
    new PdfGenerator()
      .getContactsTable(table.computedItems)
      .then((pdfDoc: any) => {
        pdfDoc.download(`priplan-contacts.pdf`);
      });
  }

  getTableComputedItems() {
    const table: any = this.$refs.theTable;
    return table.computedItems;
  }

  exportTableDataExcel() {
    const data: string[][] = [getContactHeaderFields()].concat(
      getContactFieldsForExport(this.getTableComputedItems())
    );
    generateSpreadsheetSingleTableAndDownload(data, "priplan-contacts.xlsx");
  }
}
