
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ContactTypeTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "type", sortable: true },
      { key: "notes", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get types() {
    return this.$store.state.contactTypes;
  }
}
