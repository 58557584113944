
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Equipment, Facility } from "@/survey";
import EquipmentForm from "@/views/EquipmentForm.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";
import {
  booleanYNFormatter,
  dateFormatter,
  equipmentTypeFormatter,
  facilityFormatter
} from "@/components/formatters";
import ImageGallery from "@/components/ImageGallery.vue";
import { getFacilityById, getEquipmentById } from "@/store";

@Component({
  components: {
    EquipmentForm,
    DisplayImagesCarousel: DisplayImagesCarousel,
    FileUploadInput,
    ImageGallery
  }
})
export default class EquipmentTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  @Prop({ default: [] }) equipment?: Equipment[];
  @Prop({ default: { name: "N/A" } }) facility?: Facility | null;
  @Prop({ default: false }) isReport?: boolean;

  deleteEquipment(id: number, facilityId: number) {
    if (confirm("Confirm delete")) {
      this.$store.dispatch(actions.DELETE_EQUIPMENT, {
        id: id,
        facilityId: facilityId
      });
    }
  }

  get backTo() {
    return encodeURIComponent(this.$route.fullPath);
  }

  addImage(equipment: Equipment) {
    const payload = {
      image: this.$data.image,
      uploadName: this.$data.image.name,
      tableName: "equipment",
      recordId: equipment.id,
      success: () =>
        this.$store.dispatch(actions.GET_EQUIPMENT, {
          facility: equipment.facility
        })
    };
    this.$store.dispatch(actions.UPLOAD_FILE, payload);
  }

  getEquipmentById(id: number) {
    return getEquipmentById(id);
  }

  get tableData() {
    return this.$props.equipment;
  }

  data() {
    return {
      filter: "",
      image: null,
      mainImage: false,
      component: this
    };
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
  }

  get fields() {
    const theFields = [
      { key: "name", sortable: true },
      {
        key: "equipmentType",
        sortable: true,
        formatter: equipmentTypeFormatter(this.$store.state.equipmentTypes)
      },
      { key: "surveyed", sortable: true, formatter: booleanYNFormatter },
      { key: "surveyTeam", sortable: true },
      { key: "standardizedName", sortable: true },
      { key: "modelNo", sortable: true },
      // { key: "ipAddress", sortable: true },
      { key: "labels", sortable: false },
      { key: "tags", sortable: false },
      { key: "created", sortable: true, formatter: dateFormatter() },
      { key: "updated", sortable: true, formatter: dateFormatter() }
    ];
    if (this.$props.isReport) {
      theFields.splice(1, 0, {
        key: "facility",
        sortable: true,
        formatter: facilityFormatter(getFacilityById)
      });
    }
    return theFields;
  }
}
