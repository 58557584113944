
import { Component, Vue } from "vue-property-decorator";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { helpers, minLength, required, sameAs } from "vuelidate/lib/validators";
import { actions } from "@/survey";
import store from "@/store";

const validatePwRules = (value: string) => {
  if(value.length < parseInt(store.state.oauth['survey.password_length'])){
    return false;
  }
  if(store.state.oauth['survey.password_lc_required'] === 'true' && value.match(/[a-z]+/) === null){
    return false;
  }
  if(store.state.oauth['survey.password_uc_required'] === 'true' && value.match(/[A-Z]+/) === null){
    return false;
  }
  if(store.state.oauth['survey.password_num_required'] === 'true' && value.match(/[0-9]+/) === null){
    return false;
  }
  const sc = store.state.oauth['survey.password_chars'];
  let scFound = false;
  // console.log(`SC: ${sc}`)
  if(sc.length > 0){
    sc.split('').forEach((c) => {
      if(value.indexOf(c) > -1){
        scFound = true;
      }
    })
  }
  return scFound;
};

@Component({
  components: { InputWithLabel },
  validations: {
    currentPassword: {
      required
    },
    userPassword: {
      // minLength: minLength(parseInt(store.state.oauth['survey.password_length'])),
      // required,
      // letters: helpers.regex("alpha", /[a-z]+/),
      // numbers: helpers.regex("num", /[0-9]+/),
      // LETTERS: helpers.regex("alpha", /[A-Z]+/),
      // special: helpers.regex("special", /[!@#$%^&*/?]+/),
      validatePwRules
    },
    confirmPassword: {
      sameAsPassword: sameAs("userPassword")
    }
  }
})
export default class Me extends Vue {
  data() {
    return {
      userPassword: "",
      confirmPassword: "",
      currentPassword: ""
    };
  }

  get lc(){
    return this.$store.state.oauth['survey.password_lc_required'] === 'true'
  }
  get uc(){
    return this.$store.state.oauth['survey.password_uc_required'] === 'true'
  }
  get num(){
    return this.$store.state.oauth['survey.password_num_required'] === 'true'
  }
  get passwordLength() {
    return this.$store.state.oauth['survey.password_length'];
  }

  get me() {
    return this.$store.state.authentication.currentUser;
  }

  get specialChars(){
    return this.$store.state.oauth['survey.password_chars']
  }

  changePassword() {
    if (this.$v.$invalid) {
      alert("Invalid password!");
    } else {
      this.$store.dispatch(actions.CHANGE_PASSWORD, {
        userId: this.me.id,
        newPassword: this.$data.userPassword,
        currentPassword: this.$data.currentPassword
      });
    }
  }
}
