import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { actions } from "@/survey";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/sb-admin-2.css";
import store from "@/store";
import { Component } from "vue-property-decorator";
// import VueAuthImage from "vue-auth-image";
import * as VueAuthImage from "@/vue-auth-image"
import Vuelidate from "vuelidate";
import axios from "axios";
import {
  requestInterceptor,
  responseError,
  responseInterceptor
} from "@/client/interceptor";
import JsonViewer from 'vue-json-viewer'
declare const window: any;
Vue.config.productionTip = false;

Component.registerHooks(["validations"]);
// Add a request interceptor


// Import JsonViewer as a Vue.js plugin
Vue.use(JsonViewer)
Vue.use(VueAuthImage);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(responseInterceptor, responseError);

const rolesMixin = {
  computed: {
    isDeleter: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.admin || user.delete;
    },
    isWriter: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.admin || user.edit;
    },
    isAdmin: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.admin;
    },
    isEditor: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.edit;
    },
    isViewer: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.view;
    },
    isPunch: function() {
      const user = store.state.authentication.currentUser;
      return user === null ? false : user.punch;
    }
  }
};

Vue.mixin(rolesMixin);

const a = new Vue({
  mixins: [rolesMixin],
  router,
  store: store,
  render: h => h(App)
});
window.app = a
a.$mount("#app");

store.dispatch(actions.PING);
store.dispatch(actions.POSITION);

const next = router.currentRoute.query.redirect
  ? router.currentRoute.query.redirect + ""
  : "/";
store.dispatch(actions.TRY_GET_ME, {
  success: () => router.push(`${next}`)
});

const doPing = () => {
  try {
    store.dispatch(actions.PING);
  } catch {
    //noop
  } finally {
    setTimeout(() => {
      doPing();
    }, 5000);
  }
};

setTimeout(() => {
  doPing();
}, 5000);
//setInterval(() => store.dispatch(actions.POSITION), 5000);
