var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(
      _vm.id !== null &&
        _vm.id !== undefined &&
        (_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE')
    )?_c('img',{directives:[{name:"auth-image",rawName:"v-auth-image",value:(
      _vm.apiLocation + 'attach/' + _vm.attachmentType + '/' + _vm.table + '/' + _vm.id
    ),expression:"\n      apiLocation + 'attach/' + attachmentType + '/' + table + '/' + id\n    "}],class:{ 'd-none': _vm.previewReady },attrs:{"width":"180","alt":"your image"}}):_vm._e(),(
      _vm.id !== null &&
        _vm.id !== undefined &&
        (_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE')
    )?_c('hr'):_vm._e(),(_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE')?_c('img',{ref:"preview",class:{ 'd-none': !_vm.previewReady },attrs:{"width":"180","src":"http://placehold.it/90","alt":"your image"}}):_vm._e(),(_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE')?_c('br'):_vm._e(),(
      (_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE') &&
        _vm.previewReady &&
        _vm.allowComment
    )?_c('b-form-checkbox',{attrs:{"id":"main_image","name":"main_image"},on:{"change":_vm.emitMainImageChange},model:{value:(_vm.isMainImage),callback:function ($$v) {_vm.isMainImage=$$v},expression:"isMainImage"}},[_vm._v(" Set as main image ")]):_vm._e(),(
      (_vm.attachmentType === 'MAIN_IMAGE' || _vm.attachmentType === 'IMAGE') &&
        _vm.previewReady &&
        _vm.allowComment
    )?_c('b-form-input',{attrs:{"placeholder":"comment","id":_vm.id},on:{"keyup":_vm.emitCommentChange,"change":_vm.emitCommentChange},model:{value:(_vm.theComment),callback:function ($$v) {_vm.theComment=$$v},expression:"theComment"}}):_vm._e(),_c('input',{ref:"fileInput",staticClass:"mt-1",attrs:{"disabled":_vm.disabled,"id":"fi","type":"file"},on:{"change":_vm.readURL}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }