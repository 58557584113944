import { AxiosRequestConfig, AxiosResponse } from "axios";
import { surveyClient } from "@/store";
import { LocalStorageKeys } from "@/survey";

const getInterceptors: any = (maxConcurrentRequests = 5) => {
  let counter = 0;

  const request = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    const sendOrThrottle = (
      config: AxiosRequestConfig,
      readyToExecute: (c: AxiosRequestConfig) => void
    ) => {
      if (counter < maxConcurrentRequests) {
        counter++;
        readyToExecute(config);
      } else {
        setTimeout(() => sendOrThrottle(config, readyToExecute), 200);
      }
    };

    return new Promise(resolve => {
      sendOrThrottle(config, resolve);
    });
  };

  const response = (response: AxiosResponse): AxiosResponse => {
    counter--;
    return response;
  };

  const responseError = (error: any): any => {
    counter--;
    console.error("RESPONSE ERROR: " + (typeof error), JSON.stringify(error));
    const key = localStorage.getItem(LocalStorageKeys.TOKEN_LOCAL_STORAGE_KEY);
    if (
      error.message.indexOf("403") > -1 &&
      typeof key === "string" &&
      key.length > 32
    ) {
      surveyClient.logout();
      alert("You have been automatically logged out due to inactivity");
      window.location.reload();
    }
    return Promise.reject(error);
  };

  return [request, response, responseError];
};

const [
  requestInterceptor,
  responseInterceptor,
  responseError
] = getInterceptors();

export { requestInterceptor, responseInterceptor, responseError };
