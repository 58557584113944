
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Facility, PunchItem } from "@/survey";
import FencingForm from "@/views/FencingForm.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import FileUploadMultiple from "@/components/FileUploadMultiple.vue";
import { dateFormatter } from "@/components/formatters";
import { equipmentNameFromId } from "@/store";
import PunchItemForm from "@/views/PunchItemForm.vue";

@Component({
  components: {
    FencingForm,
    DisplayImagesCarousel,
    FileUploadInput,
    ImageGallery,
    FileUploadMultiple,
    PunchItemForm
  }
})
export default class PunchItemTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  @Prop({ default: [] }) pitems?: PunchItem[];
  @Prop({ default: { name: "N/A" } }) facility?: Facility | null;

  deletePunchItem(id: number, facilityId: number) {
    if (confirm("Confirm delete")) {
      this.$store.dispatch(actions.DELETE_PUNCH_ITEM, {
        id: id,
        facilityId: facilityId
      });
    }
  }

  get backTo() {
    return encodeURIComponent(this.$route.fullPath);
  }

  addImage(punchItem: PunchItem) {
    const payload = {
      image: this.$data.image,
      uploadName: this.$data.image.name,
      tableName: "punch_item",
      recordId: punchItem.id,
      success: () =>
        this.$store.dispatch(actions.GET_PUNCH_ITEMS, {
          facility: punchItem.facility
        })
    };
    this.$store.dispatch(actions.UPLOAD_FILE, payload);
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
  }

  data() {
    return {
      filter: "",
      image: null,
      mainImage: false,
      component: this
    };
  }

  get fields() {
    const theFields: any[] = [
      { key: "equipment", sortable: true, formatter: equipmentNameFromId },
      { key: "surveyor", sortable: true },
      { key: "started", sortable: true, formatter: dateFormatter() },
      { key: "completed", sortable: true, formatter: dateFormatter() },
      { key: "tags", sortable: false },
      { key: "notes", sortable: false }
    ];
    return theFields;
  }
}
