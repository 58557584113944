export interface SelectItem {
  value: string;
  text: string;
}
export interface FacilityProfile extends DBEntity {
  name: string;
  facilities: number[];
}

export interface DBInfo {
  db_product_name: string,
  db_product_version: string,
  driver_name: string,
  driver_version: string,
  db_url: string,
  db_name: string,
  schema_name: string,
  table_info: { t1: string, t2: string, t3: number, t4: number }[]
}

export type UserRole = "create" | "edit" | "view" | "export" | "admin";
export type AttachableStateFields =
  | "gates"
  | "fencing"
  | "equipment"
  | "facilities";

// export type FacilityPriority = "Low" | "Medium" | "High";
// export const facilityPriorities: FacilityPriority[] = ["Low", "Medium", "High"];

export enum LocalStorageKeys {
  TOKEN_LOCAL_STORAGE_KEY = "__SSTOKEN"
}

// export type FacilityStatus =
//   | "pending"
//   | "scheduled"
//   | "in-process"
//   | "completed"
//   | "on-hold"
//   | "cancelled";
//
// export const facilityStatuses: FacilityStatus[] = [
//   "pending",
//   "scheduled",
//   "in-process",
//   "completed",
//   "on-hold",
//   "cancelled"
// ];

// export type FacilitySize = "Small" | "Medium" | "Large";
// export const facilitySizes: FacilitySize[] = ["Small", "Medium", "Large"];

export interface Pair<T, U> {
  first: T;
  second: U;
}

export interface PingResponse {
  available: boolean;
}

export interface LoginAttempt {
  credentials: Credentials;
  success: () => void;
}

export interface Credentials {
  username: string;
  password: string;
}

export interface SelectConfig extends DBEntity {
  name: string,
  key: string,
  label: string,
  value: string
}

export interface Attachable extends DBEntity {
  attachmentMeta?: AttachmentMeta[];
}

export interface GeoLocatable {
  lat?: number;
  lng?: number;
}

export interface Addressable {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface DBEntity {
  id?: number | null;
  tableName: string;
  tags?: Tag[];
}

export interface Manufacturer extends DBEntity, Addressable {
  company: string;
  website: string;
  contact: number;
  contactEmail: string;
  mainPhone: string;
  notes: string;
  contactName: string;
}

export interface Contact extends DBEntity, Addressable {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  phone2: string;
  company: string;
  title: string;
  contactType: string;
  notes: string;
}

export interface EquipmentType extends Attachable {
  id: number;
  name: string;
  iconSvg: string;
  color: string;
}

export interface Tag extends DBEntity {
  tag?: string;
  description?: string;
}

export interface Vendor extends DBEntity {
  name?: string;
}

export type PunchTag = Tag;

export interface PunchFacility extends DBEntity, Attachable {
  facility: number;
  notes: string;
}

export interface PunchItem extends DBEntity, Attachable {
  equipment: number;
  facility: number;
  surveyor: string;
  started: number;
  completed: number;
  notes: string;
}

// export interface TagDTO {
//   tableName: string;
//   tag: string;
//   recordId: number;
// }

export interface User extends DBEntity, Attachable {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateCreated: number;
  active: boolean;
  create: boolean;
  edit: boolean;
  view: boolean;
  delete: boolean;
  export: boolean;
  admin: boolean;
  punch: boolean;
  notes: string;
  changedDate: number;
  needsPasswordChange: boolean;
  localLoginEnabled: boolean;
  facilityProfile: number;
}

export interface Attachment extends GeoLocatable {
  id?: number;
  contenttype?: string;
  name?: string;
  originalfilename?: string;
  sizebytes?: number;
  tablename?: string;
  recordid?: number;
  data?: any;
}

export interface AttachmentMeta extends GeoLocatable {
  id?: number;
  contenttype?: string;
  name?: string;
  originalfilename?: string;
  sizebytes?: number;
  tablename?: string;
  recordid?: number;
  attachmentType?: AttachmentType;
  notes: string;
}

export interface PersonForMeeting {
  name: string;
  email: string;
  company: string;
  type: "contact" | "user";
  id: number;
}

export interface CalendarAttendee extends DBEntity {
  calendarEntry: number;
  contact?: number | null;
  user?: number;
}

export interface CalendarEntry extends DBEntity {
  start?: number;
  end?: number;
  facility?: number;
  subject?: string;
  detail?: string;
  attendees?: CalendarAttendee[];
}

export interface LocationChangeDTO extends GeoLocatable {
  recordId: number;
  tableName: "facility" | "equipment" | "gate" | "fencing";
}

export interface Equipment extends DBEntity, GeoLocatable, Attachable {
  name: string;
  standardizedName: string;
  description: string;
  isLabeled: boolean;
  manufacturer: number;
  weblink: string;
  facility: number;
  modelNo: string;
  serialNo: string;
  ipAddress: string;
  macAddress: string;
  location: string;
  installDate: string;
  damaged: boolean;
  editedBy: number;
  notes: string;
  deviceId: string;
  surveyed: boolean;
  door: number;
  public: number;
  exterior: number;
  doorForced: number;
  doorHeld: number;
  doorRex: number;
  validRead: number;
  surveyTeam: string;
  equipmentType: number;
  relatedEquipmentA: number;
  relatedEquipmentB: number;
  acPowerFailAlarm: number | null;
  lowBatteryAlarm: number | null;
  batteryInstalled: number | null;
  batteryInstallDate: string;
  vendor: number;
}

export interface Facility
  extends DBEntity,
    Addressable,
    GeoLocatable,
    Attachable {
  name: string;
  description?: string;
  status: string;
  priority: string;
  size: string;
  notes?: string;
  secstaffonsite: boolean;
  visitorsignage: boolean;
  privpropsignage: boolean;
  lightingissues: boolean;
  ophours?: string;
  contact?: number;
  facilityCode: string;
  surveyor: string;
  surveyTeam: string;
  hasBathroom: boolean;
  asbestosSignage: boolean;
  qa: boolean;
  survey: boolean;
  region: string;
  qaCompleted: boolean;
  aka: string;
}

export interface Fencing extends DBEntity, Attachable, GeoLocatable {
  name: string;
  surveyTeam?: string;
  isfenced: boolean;
  fencedamaged: boolean;
  vegproblem: boolean;
  climbingaids: boolean;
  notes: string;
  facility: number;
}

export interface ContactType extends DBEntity, Attachable {
  type: string;
  notes: string;
}

export interface GateExample {
  gatetype: string[];
  optype: string[];
  acctype: string[];
  fencing?: number[];
  surveyTeam?: string;
}

export interface Gate extends DBEntity, Attachable, GeoLocatable {
  name: string;
  surveyTeam?: string;
  gatetype: string;
  description: string;
  optype: string;
  acctype: string;
  fencing: number;
  notes: string;
}

export interface Comment {
  id: number;
  comment: string;
}

export interface SurveyState {
  facilityProfiles: FacilityProfile[];
  selectConfig: SelectConfig[];
  loading: number;
  allFencing: Fencing[];
  equipment: Equipment[];
  fencing: Fencing[];
  gates: Gate[];
  punchFacility: PunchFacility;
  punchItems: PunchItem[];
  facilities: Facility[];
  users: User[];
  contacts: Contact[];
  tags: Tag[];
  vendors: Vendor[],
  punchTags: PunchTag[];
  equipmentTypes: EquipmentType[];
  manufacturers: Manufacturer[];
  calendar: CalendarEntry[];
  contactTypes: ContactType[];
  searchResult: SearchResult | null;
  tagUsage: Pair<string, number>[];
  errors: string[];
  missedPings: number;
  version: string;
  position?: Position;
  oauth: OauthSettings,
  authentication: {
    // username: string;
    // password: string;
    token: string | null;
    lastValid: number | null;
    currentUser: User | null;
    calendarEntries: CalendarEntry[];
    loginState: string;
  };
  settings: {
    passwordLength: number;
  };
}

export interface OauthSettings {
  "available": boolean;
  "db": boolean;
  "oauth.enabled": "true" | "false";
  "oauth.client_id": string;
  "oauth.redirect_uri": string;
  "survey.2fa_enabled": "true" | "false";
  "google.client_maps_api_key": string;
  "web.title": string;
  "oauth.server": string;
  "survey.password_length": string;
  "survey.password_lc_required": "true" | "false";
  "survey.password_uc_required": "true" | "false";
  "survey.password_num_required": "true" | "false";
  "survey.password_chars": string;

}

export interface PasswordResetDTO {
  userId: number;
  newPassword: string;
  currentPassword: string;
}

export interface SearchData {
  searchTerm: string;

  searchEquipment: boolean;
  equipmentExample: Equipment;

  searchFencing: boolean;
  fencingExample: Fencing;

  searchGates: boolean;
  gateExample: GateExample;

  selectedTags: number[];
  selectedContacts: number[];
  selectedFacilities: number[];
  selectedManufacturers: number[];
}

export interface SuccessMethod {
  success: () => void;
}

export interface SearchResult {
  equipment: Equipment[];
  gates: Gate[];
  fencing: Fencing[];
  searchData: SearchData;
}

export enum mutations {
  GOT_SELECT_CONFIG = "gotSelectConfig",
  GOT_SEARCH_DATA = "gotSearchData",
  GOT_CONTACT_TYPES = "gotContactTypes",
  DELETE_IMAGE = "deleteImage",
  LOGOUT = "logout",
  UPDATE_ATTACHMENT_NOTES = "updateAttachmentNotes",
  GOT_TAG_USAGE = "gotTagUsage",
  GOT_FACILITIES = "gotFacilities",
  GOT_EQUIPMENT_TYPES = "gotEquipmentTypes",
  GOT_FACILITY = "gotFacility",
  GOT_USERS = "gotUsers",
  GOT_CONTACTS = "gotContacts",
  GOT_TAGS = "gotTags",
  GOT_PUNCH_TAGS = "gotPunchTags",
  GOT_MANUFACTURERS = "gotManufacturers",
  GOT_EQUIPMENT = "gotEquipment",
  GOT_FENCING = "gotFencing",
  GOT_VENDORS = "gotVendors",
  GOT_ALL_FENCING = "gotAllFencing",
  GOT_GATES = "gotGates",
  GOT_USER_CALENDAR_ENTRIES = "gotUserCalendarEntries",
  LOADING = "loadingModal",
  LOADING_DONE = "loadingDone",
  GOT_ALL_CALENDAR_ENTRIES = "gotAllCalendarEntries",
  LOGIN_SUCCESS = "loginSucess",
  CLEAR_ERRORS = "clearErrors",
  ADD_ERROR = "addError",
  PING_SUCCESS = "pingSuccess",
  PING_MISS = "pingMiss",
  GOT_POSITION = "gotPosition",
  UPDATED_LOCATION = "updatedLocation",
  GOT_LOGIN_STATE = "gotLoginState",
  GOT_PUNCH_FACILITY = "gotPunchFacility",
  GOT_PUNCH_ITEMS = "gotPunchItems",
  SET_PUNCH_FACILITY_NOTES = "setPFNotes",
  SET_FACILITY_QA_COMPLETE = "setFacilityQAComplete",
  GOT_CONFIG = "gotConfig",
  GOT_FACILITY_PROFILES = "gotFacilitiyProfiles",
}

export enum actions {
  GET_FACILITY_PROFILES = "getFacilityProfiles",
  CREATE_OR_UPDATE_FACILITY_PROFILE = "createOrUpdateFacilityProfile",
  DELETE_FACILITY_PROFILE = "deleteFacilityProfile",
  DELETE_SELECT_CONFIG = "deleteSelectConfig",
  CREATE_OR_UPDATE_SELECT_CONFIG = "createOrUpdateSelectConfig",
  GET_SELECT_CONFIG = "getSelectConfig",
  GET_CONFIG = "getConfig",
  GET_CONTACT_TYPES = "getContactTypes",
  CHANGE_PASSWORD = "changePassword",
  SEARCH = "search",
  DELETE_IMAGE = "deleteImage",
  LOGOUT = "logout",
  ADD_COMMENT = "addComment",
  GET_TAG_USAGE = "getTagUsage",
  GET_FACILITIES = "getFacilities",
  GET_FACILITY = "getFacility",
  GET_USERS = "getUsers",
  GET_TAGS = "getTags",
  GET_PUNCH_TAGS = "getPunchTags",
  GET_CONTACTS = "getContacts",
  GET_MANUFACTURERS = "getManufacturers",
  GET_EQUIPMENT = "getEquipment",
  GET_EQUIPMENT_TYPES = "getEquipmentTypes",
  GET_FENCING = "getFencing",
  GET_GATES = "getGates",
  GET_VENDORS = "getVendors",
  GET_USER_CALENDAR_ENTRIES = "getUserCalendarEntries",
  GET_ALL_CALENDAR_ENTRIES = "getAllCalendarEntries",
  CREATE_OR_UPDATE_FACILITY = "createOrUpdateFacility",
  CREATE_OR_UPDATE_USER = "createOrUpdateUser",
  CREATE_OR_UPDATE_TAG = "createOrUpdateTag",
  CREATE_OR_UPDATE_MANUFACTURER = "createOrUpdateManufacturer",
  CREATE_OR_UPDATE_CONTACT = "createOrUpdateContact",
  CREATE_OR_UPDATE_CONTACT_TYPE = "createOrUpdateContactType",
  CREATE_OR_UPDATE_EQUIPMENT = "createOrUpdateEquipment",
  UPDATE_LOCATION = "updateLocation",
  CREATE_OR_UPDATE_EQUIPMENT_TYPE = "createOrUpdateEquipmentType",
  CREATE_OR_UPDATE_FENCING = "createOrUpdateFencing",
  CREATE_OR_UPDATE_GATE = "createOrUpdateGate",
  CREATE_OR_UPDATE_CALENDAR_ENTRY = "createOrUpdateCalendarEntry",
  CREATE_OR_UPDATE_VENDOR = "createOrUpdateVendor",
  DELETE_FACILITY = "deleteFacility",
  DELETE_USER = "deleteUser",
  DELETE_TAG = "deleteTag",
  DELETE_PUNCH_TAG = "deletePunchTag",
  DELETE_MANUFACTURER = "deleteManufacturer",
  DELETE_CONTACT = "deleteContact",
  DELETE_CONTACT_TYPE = "deleteContactType",
  DELETE_EQUIPMENT = "deleteEquipment",
  DELETE_EQUIPMENT_TYPE = "deleteEquipmentType",
  DELETE_FENCING = "deleteFencing",
  DELETE_GATE = "deleteGate",
  DELETE_VENDOR = "deleteVendor",
  DELETE_CALENDAR_ENTRY = "deleteCalendarEntry",
  UPLOAD_FILE = "uploadFile",
  ROTATE_IMAGE = "rotateImage",
  TRY_LOGIN = "tryLogin",
  TRY_OAUTH_LOGIN = "tryOauthLogin",
  TRY_2FA = "try2FA",
  TRY_GET_ME = "tryGetMe",
  GOT_LOGGED_IN_USER = "gotLoggedInUser",
  CLEAR_ERRORS = "clearErrors",
  ADD_ERROR = "addError",
  PING = "ping",
  POSITION = "position",
  REFRESH_USER_DATA = "refreshUserData",
  GET_PUNCH_FACILITY = "getPunchFacility",
  SAVE_PUNCH_FACILITY = "savePunchFacility",
  GET_PUNCH_ITEMS = "getPunchItems",
  DELETE_PUNCH_ITEM = "deletePunchItem",
  CREATE_OR_UPDATE_PUNCH_ITEM = "createOrUpdatePunchItem",
  CREATE_OR_UPDATE_PUNCH_TAG = "createOrUpdatePunchTag"
}

export enum AttachmentType {
  MAIN_IMAGE = "MAIN_IMAGE",
  IMAGE = "IMAGE",
  FLOOR_PLAN = "FLOOR_PLAN",
  SITE_PLAN = "SITE_PLAN",
  OTHER = "OTHER"
}
