
import { Component, Vue } from "vue-property-decorator";
import FacilitiesCard from "@/views/FacilitiesCard.vue";
import FacilityList from "@/views/FacilityList.vue";
import EventList from "@/views/events/EventList.vue";
import {
  Facility,
  Pair
} from "@/survey";
import FacilityStatusBar from "@/views/charts/FacilityStatusBar.vue";
import FacilityPriorityBar from "@/views/charts/FacilityPriorityBar.vue";
import FacilitySizePie from "@/views/charts/FacilitySizePie.vue";
import TagUsagePie from "@/views/charts/TagUsagePie.vue"; // @ is an alias to /src

@Component({
  components: {
    FacilityList,
    FacilitiesCard: FacilitiesCard,
    EventList,
    FacilityStatusBar,
    FacilityPriorityBar,
    TagUsagePie,
    FacilitySizePie
  }
})
export default class Home extends Vue {
  get facilities() {
    return this.$store.state.facilities;
  }
  get completedFacilities(): Facility[] {
    const completed: Facility[] = [];
    this.$store.state.facilities.forEach((facility: Facility) => {
      if (facility.status === "completed") {
        completed.push(facility);
      }
    });
    return completed;
  }
}
