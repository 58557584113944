
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Facility, Fencing } from "@/survey";
import FencingForm from "@/views/FencingForm.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import FileUploadMultiple from "@/components/FileUploadMultiple.vue";
import { facilityFormatter } from "@/components/formatters";
import { getFacilityById } from "@/store";

@Component({
  components: {
    FencingForm,
    DisplayImagesCarousel,
    FileUploadInput,
    ImageGallery,
    FileUploadMultiple
  }
})
export default class FencingTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  @Prop({ default: [] }) fencing?: Fencing[];
  @Prop({ default: { name: "N/A" } }) facility?: Facility | null;
  @Prop({ default: false }) isReport?: boolean;

  deleteFencing(id: number, facilityId: number) {
    if (confirm("Confirm delete")) {
      this.$store.dispatch(actions.DELETE_FENCING, {
        id: id,
        facilityId: facilityId
      });
    }
  }

  get backTo() {
    return encodeURIComponent(this.$route.fullPath);
  }

  addImage(fencing: Fencing) {
    const payload = {
      image: this.$data.image,
      uploadName: this.$data.image.name,
      tableName: "fencing",
      recordId: fencing.id,
      success: () =>
        this.$store.dispatch(actions.GET_FENCING, {
          facility: fencing.facility
        })
    };
    this.$store.dispatch(actions.UPLOAD_FILE, payload);
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
  }

  data() {
    return {
      filter: "",
      image: null,
      mainImage: false,
      component: this
    };
  }

  get fields() {
    const theFields: any[] = [
      { key: "name", sortable: true },
      { key: "surveyTeam", sortable: true },
      { key: "labels", sortable: false },
      { key: "tags", sortable: false }
    ];
    if (this.$props.isReport) {
      theFields.splice(1, 0, {
        key: "facility",
        sortable: true,
        formatter: facilityFormatter(getFacilityById)
      });
    }
    return theFields;
  }
}
