
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Gate, Manufacturer, Tag } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";
import TagInput from "@/components/TagInput.vue";
import {
  addLocationToGeoLocatable,
  doNotAddLocationToGeoLocatable,
  fencingForSelect,
  gateAcctypeChoicesStore,
  gateOptypeChoicesStore,
  gateTypeChoicesStore
} from "@/store";

function initialData(
  manufacturers: Manufacturer[],
  gateList: Gate[],
  id: number | null,
  formKey = 0
) {
  const manufacturerSelectData = manufacturers.map(
    (manufacturer: Manufacturer) => {
      return {
        value: manufacturer.id,
        text: `${manufacturer.company}`
      };
    }
  );
  for (const gate of gateList) {
    if (gate.id === id) {
      return {
        gate,
        gateImage: null,
        imageComment: "",
        manufacturerSelectData,
        formKey,
        useCurrentLocation: false,
        tags: gate.tags!.map((t: Tag) => t.tag)
      };
    }
  }
  return {
    gate: {
      id: null,
      gatetype: gateTypeChoicesStore()[0].value,
      optype: gateOptypeChoicesStore()[0].value,
      acctype: gateAcctypeChoicesStore()[0].value,
      fencing: fencingForSelect()[0].value
    },
    gateImage: null,
    imageComment: "",
    manufacturerSelectData,
    formKey,
    useCurrentLocation: true,
    tags: []
  };
}

@Component({
  components: { InputWithLabel, FileUploadInput, TagInput },
  validations: {
    gate: {
      name: { required },
      gatetype: { required },
      optype: { required },
      acctype: { required },
      fencing: { required }
    }
  }
})
export default class GateForm extends Vue {
  @Prop() facility?: number;
  @Prop({ default: 0 }) gateId?: number;
  disabledBoolean = false;

  disable() {
    this.disabledBoolean = true;
  }

  enable() {
    this.disabledBoolean = false;
  }

  data() {
    return initialData(
      this.$store.state.manufacturers,
      this.$store.state.gates,
      this.gateId || 0
    );
  }

  get gateTypeChoices() {
    return gateTypeChoicesStore();
  }

  get gateOptypeChoices() {
    return gateOptypeChoicesStore();
  }

  get gateAcctypeChoices() {
    return gateAcctypeChoicesStore();
  }

  get fencingChoices() {
    return fencingForSelect();
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save Gate");
    } else {
      this.savegate();
    }
  }

  savegate() {
    const initialFunction: (item: Gate) => Promise<Gate> = this.$data
      .useCurrentLocation
      ? (item: Gate) => addLocationToGeoLocatable<Gate>(item)
      : (item: Gate) => doNotAddLocationToGeoLocatable<Gate>(item);
    this.disable();
    this.$bvToast.toast(`Saving...`, {
      title: "Saving Gate",
      variant: "info",
      autoHideDelay: 3000,
      appendToast: true
    });
    initialFunction(this.$data.gate).then((gate: Gate) => {
      this.$store
        .dispatch(actions.CREATE_OR_UPDATE_GATE, {
          toSave: { ...gate, facility: this.$props.facility },
          tags: this.$data.tags,
          image: this.$data.gateImage,
          imageComment: this.$data.imageComment,
          success: () => {
            this.$bvToast.toast(`Success`, {
              title: "Gate Saved!",
              variant: "success",
              autoHideDelay: 3000,
              appendToast: true
            });

            this.$store.dispatch(actions.GET_GATES, {
              facility: this.$props.facility
            });
            this.$emit("complete");
            // const getData = () =>
            //   initialData(
            //     this.$store.state.manufacturers,
            //     this.$store.state.gates,
            //     parseInt(this.$route.params.id),
            //     this.$data.formKey + 1
            //   );
            //Object.assign(this.$data, getData.apply(this));
          }
        })
        .catch(positionError => {
          alert(`Error ${positionError}`);
        }); //.finally(this.enable);
      // }
    });
  }
}
