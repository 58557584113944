
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PasswordWithLabel extends Vue {
  @Prop() private id?: string;
  @Prop() private label?: string;
  @Prop() private model?: any;

  data() {
    return {
      lclModel: this.model
    };
  }
}
