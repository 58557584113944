
import { Component, Vue } from "vue-property-decorator";
import { actions, FacilityProfile, Tag } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

function uniqueIfNew(value: string, record: Tag) {
  if (record.id != null) {
    return true;
  }
  const values = store.state.tags.map((t: Tag) => t.tag);
  console.log(`Creating fun with values ${values.join(", ")}`);
  const v = values.includes(value);
  console.log(`${v} - ${value} is in ${values.join(", ")}`);
  return !v;
}
@Component({
  components: {
    InputWithLabel, FileUploadInput: FileUploadInput
  },
  validations: {
    tag: { tag: { required, unique: uniqueIfNew }, description: { required } }
  }
  })
export default class AdminTagForm extends Vue {
  data() {
    try {
      const tagId = parseInt(this.$route.params.id);
      for (const tag of this.$store.state.tags) {
        if (tag.id === tagId) {
          return {
            tag: { ...tag }
          };
        }
      }
      throw "Cannot find tag for id " + tagId;
    } catch (e) {
      return {
        tag: {
          id: null,
          tag: "",
          description: ""
        }
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }

    this.$store.dispatch(actions.CREATE_OR_UPDATE_TAG, {
      toSave: this.$data.tag,
      success: () => this.$router.push("/admin/tags")
    });
  }

  onCancel() {
    this.$router.push("/admin/tags");
  }

}
