
import { Component, Vue } from "vue-property-decorator";
import { actions, LoginAttempt } from "@/survey";
import InputWithLabel from "@/components/InputWithLabel.vue";
import PasswordWithLabel from "@/components/PasswordWithLabel.vue";
import { apiLocation } from "@/store";

@Component({
  components: { InputWithLabel, PasswordWithLabel }
})
export default class LoginForm extends Vue {
  _oauthWindow: any = null;
  _oauthInterval: any = null;
  state: any = (Math.random() + 1).toString(36).substring(7);
  returnState = null;

  get ssoEnabled(){
    return this.$store.state.oauth['oauth.enabled'] === 'true'
  }
  data() {
    return {
      username: "",
      password: "",
      showImage: false,
      apiLocation: apiLocation,
      authCode: "",
      trying: false,
      sessionState: null,
      code: null
    };
  }

  oauthpopup(options: any) {
    options.windowName = options.windowName || 'ConnectWithOAuth'; // should not include space for IE
    options.windowOptions = options.windowOptions || 'location=0,status=0,width=800,height=700';
    options.callback = options.callback || function () {
      window.location.reload();
    };
    // console.log(options.path);
    this._oauthWindow = window.open(options.path, options.windowName, options.windowOptions);
    this._oauthInterval = window.setInterval(function (this: any) {
      const windowParams = new URLSearchParams(this._oauthWindow.location.search);
      if (windowParams.has('state')) {
        windowParams.forEach(function (a: any, b: any) {
          console.log(a + ' -> ' + b);
        });
        this.returnState = windowParams.get('state');
        this.sessionState = windowParams.get('session_state');
        this.code = windowParams.get('code');
        this._oauthWindow.close();
        window.clearInterval(this._oauthInterval);
        options.callback();
      }
    }.bind(this), 1000);
  };

  doOauth() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const component = this;
    this.oauthpopup({
      path: `${component.$store.state.oauth['oauth.server']}?client_id=${component.$store.state.oauth['oauth.client_id']}&response_type=code&state=${this.state}&redirect_uri=${encodeURIComponent(component.$store.state.oauth['oauth.redirect_uri'])}`,
      callback: function () {
        if (component.state !== component.returnState) {
          alert("Looks like a bad request - states don't match");
          throw("Looks like a bad request - states don't match");
        }
        // fetch('http://localhost:8081/oauth2/' + component.$data.code).then(data => window.xxdata = data).catch(e => console.error(e))
        // console.log(component.$data.code)
        component.$store
            .dispatch(actions.TRY_OAUTH_LOGIN, {
                  code: component.$data.code,
                  success: () => {
                    component.$router.push(component.getNext());
                  }
                },
            )
            .finally(() => (component.$data.trying = false));
      }
    });
  }

  get loginState() {
    return this.$store.state.authentication.loginState;
  }

  get title() {
    return this.$store.state.oauth['web.title'];
  }

  returnFalse() {
    return false;
  }

  getNext() {
    const next = this.$route.query.redirect
        ? this.$route.query.redirect + ""
        : "/";
    return next;
  }

  tryLogin() {
    this.$data.trying = true;
    const loginAttempt: LoginAttempt = {
      credentials: {
        username: this.$data.username,
        password: this.$data.password
      },
      success: () => {
        this.$router.push(this.getNext());
      }
    };
    this.$store
        .dispatch(actions.TRY_LOGIN, loginAttempt)
        .finally(() => (this.$data.trying = false));
  }

  tryAuthCode() {
    this.$data.trying = true;
    this.$store.dispatch(actions.TRY_2FA, {
      code: this.$data.authCode,
      success: () => {
        this.$router
            .push(this.getNext())
            .finally(() => (this.$data.trying = false));
      }
    });
  }
}
