
import { Component, Vue } from "vue-property-decorator";
import { actions, Facility, FacilityProfile } from "@/survey";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

function uniqueIfNew(value: string, record: FacilityProfile) {
  if (record.id != null) {
    return true;
  }
  const values = store.state.facilityProfiles.map((fp: FacilityProfile) => fp.name);
  console.log(`Creating fun with values ${values.join(", ")}`);
  const v = values.includes(value);
  console.log(`${v} - ${value} is in ${values.join(", ")}`);
  return !v;
}

@Component({
  components: { InputWithLabel },
  validations: {
    facilityProfile: {
      name: { required, unique: uniqueIfNew }
    }
  }
})
export default class AdminFacilityProfileForm extends Vue {
  formKey = new Date().getTime() + "";
  facilityProfile: FacilityProfile = {
    id: null,
    name: "",
    facilities: [],
    tableName: "facility_profile"
  };

  constructor() {
    super();
    try {
      const id = parseInt(this.$route.params.id);
      this.$store.state.facilityProfiles.forEach((fp: FacilityProfile) => {
        if (fp.id === id) {
          this.facilityProfile = fp;
        }
      });
    } catch (e) {
      console.log("Not editing");
    }
  }


  data() {
    return {
      t: "",
      creating: false
    };
  }

  get facilitiesForOptions() {
    return this.$store.state.facilities.map((f: Facility) => {
      return {
        value: f.id,
        text: f.name
      };
    });
  }

  onCancel() {
    this.$router.push({ path: "/admin/facility-profiles" });
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }
    if (this.$store.state)
      this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY_PROFILE, {
        toSave: this.facilityProfile,
        success: () => this.$router.push({ path: "/admin/facility-profiles" })
      });
  }
}
