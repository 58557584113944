
import { Component, Prop, Vue } from "vue-property-decorator";
import BarChart from "@/components/BarChart.vue";
import {
  Facility, SelectItem,
} from "@/survey";
import colorArray from "@/components/Colors";
@Component({
  components: {
    BarChart
  }
})
export default class FacilityPriorityBar extends Vue {
  @Prop({ default: "200px" }) height?: string;

  get facilities() {
    return this.$store.state.facilities;
  }

  get facilityStatusChoices(): SelectItem[]{
    return this.$store.getters.selectData('facility.status')
  }

  get facilityPriorityChoices(): SelectItem[]{
    return this.$store.getters.selectData('facility.priority')
  }

  get facilitiesPriorityChartData() {
    const dataPriorities: any = {};
    const data: any = {};
    const labels: string[] = [];
    this.facilityStatusChoices.forEach((status: SelectItem) => {
      data[status.value] = 0;
      labels.push(status.text);
      this.facilityPriorityChoices.forEach((priority: SelectItem) => {
        if (dataPriorities[priority.value] === undefined) {
          dataPriorities[priority.value] = {};
        }
        dataPriorities[priority.value][status.value] = 0;
      });
    });

    this.facilities.forEach(({ status, priority }: Facility) => {
      data[status] = data[status] + 1;
      dataPriorities[priority][status] = dataPriorities[priority][status] + 1;
    });
    return {
      labels,
      datasets: this.facilityPriorityChoices.map((fp: SelectItem, idx) => {
          return {
            label: fp.text,
            data: this.facilityStatusChoices.map((st: SelectItem) => dataPriorities[fp.value][st.value]),
            backgroundColor: colorArray[idx * 3]
          }
      })
      //     [
      //   {
      //     label: "Low",
      //     data: this.facilityStatusValues.map((st: string) => dataPriorities.Low[st]),
      //     backgroundColor: colorArray[6]
      //   },
      //   {
      //     label: "Medium",
      //     data: this.facilityStatusValues.map((st: string) => dataPriorities.Medium[st]),
      //     backgroundColor: colorArray[8]
      //   },
      //   {
      //     label: "High",
      //     data: this.facilityStatusValues.map((st: string) => dataPriorities.High[st]),
      //     backgroundColor: colorArray[10]
      //   }
      // ]
    };
  }
  get facilitiesStatusChartOptions() {
    return {
      legend: {
        display: true
      },
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [
          {
            stacked: true,
            ticks: {
              min: 0, // it is for ignoring negative step.
              beginAtZero: true,
              callback: function(value: number) {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          }
        ]
      },
      responsive: true,
      maintainAspectRatio: false
    };
  }
}
