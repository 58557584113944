
import Vue from "vue";
import Component from "vue-class-component";
import LoginStatusIndicator from "@/components/LoginStatusIndicator.vue";
import ErrorDisplay from "@/components/ErrorDisplay.vue";
import StatusBar from "@/components/StatusBar.vue";
import { actions } from "@/survey";

@Component({
  components: { ErrorDisplay, LoginStatusIndicator, StatusBar }
})
export default class App extends Vue {
  data() {
    return {
      refreshing: false
    };
  }

  get state() {
    return this.$store.state;
  }

  get user() {
    return this.$store.state.authentication.currentUser;
  }

  get authenticated() {
    return this.$store.state.authentication.currentUser !== null;
  }

  get loading() {
    return this.$store.state.loading;
  }

  get oconfig(){
    return this.$store.state.oauth;
  }
  refresh() {
    this.$data.refreshing = true;
    this.$store
        .dispatch(
            actions.REFRESH_USER_DATA,
            this.$store.state.authentication.currentUser.id
        )
        .then(() => (this.$data.refreshing = false));
  }

  async created() {
    try {
      this.$data.refreshing = true;
      await this.$store.dispatch(actions.GET_CONFIG);
    } finally {
      this.$data.refreshing = false;
    }
  }

  // get isAdmin() {
  //   return (
  //     this.authenticated && this.$store.state.authentication.currentUser.admin
  //   );
  // }
}
