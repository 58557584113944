
import { Component, Vue } from "vue-property-decorator";
import { actions, Contact } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { validatePhone } from "@/validators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput },
  validations: {
    manufacturer: {
      company: { required },
      website: { required },
      mainPhone: { required, validatePhone }
    }
  }
})
export default class AdminManufacturerForm extends Vue {
  data() {
    const contactSelectData = this.$store.state.contacts.map(
      (contact: Contact) => {
        return {
          value: contact.id,
          text: `${contact.company}`
        };
      }
    );
    try {
      const manufacturerId = parseInt(this.$route.params.id);
      for (const manufacturer of this.$store.state.manufacturers) {
        if (manufacturer.id === manufacturerId) {
          return {
            manufacturer: { ...manufacturer },
            contactSelectData
          };
        }
      }
      throw "Cannot find manufacturer for id " + manufacturerId;
    } catch (e) {
      return {
        manufacturer: {
          id: null
        },
        contactSelectData
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }

    this.$store.dispatch(actions.CREATE_OR_UPDATE_MANUFACTURER, {
      toSave: this.$data.manufacturer,
      success: () => this.$router.push("/admin/manufacturers")
    });
  }
}
