
import { Component, Prop, Vue } from "vue-property-decorator";
import { Facility } from "@/survey";
import router from "@/router";

declare const google: any;
declare global {
  interface Window {
    router: any;
  }
}

@Component({})
export default class FacilityEquipmentMap extends Vue {
  @Prop({ default: [] }) facilities!: Facility[];
  @Prop({ default: "roadmap" }) mapType!: string;
  @Prop({ default: 17 }) zoom!: number;
  private colorBy: 'Size' | 'Priority' | 'Status' = 'Size';
  private map: any | undefined;
  private centerMarker: any | undefined;
  private markers: any[] = [];
  private bounds: any | undefined;
  private fitAndPanCalled = false;

  constructor() {
    super();
    this.bounds = new google.maps.LatLngBounds();
  }

  get colorByChoices() {
    return [
      { value: 'Size', text: 'Size' },
      { value: 'Priority', text: 'Priority' },
      { value: 'Status', text: 'Status' }
    ];
  }

  mounted() {
    this.createMap("mounted");
    window.router = router;
  }

  getMapIcon(f: Facility): string {
    const RED = "https://maps.google.com/mapfiles/marker.png";
    const YELLOW = "https://maps.google.com/mapfiles/marker_yellow.png";
    const GREEN = "https://maps.google.com/mapfiles/marker_green.png";
    const GREY = "https://maps.google.com/mapfiles/marker_grey.png";
    //These are too big, but potentially can be scaled and color in script
    const SMALL = "https://maps.google.com/mapfiles/kml/paddle/S.png";
    const MEDIUM = "https://maps.google.com/mapfiles/kml/paddle/M.png";
    const LARGE = "http://maps.google.com/mapfiles/kml/paddle/L.png";
    const HIGH = "http://maps.google.com/mapfiles/kml/paddle/S.png"

    if (this.colorBy === "Size") {
      if (f.size === "Small") {
        return GREEN;
      }
      if (f.size === "Medium") {
        return YELLOW;
      }
      if (f.size === "Large") {
        return RED;
      }
    }
    if (this.colorBy === "Status") {
      if (f.status === "on-hold") {
        return RED;
      }
      if (f.status === "scheduled") {
        return YELLOW;
      }
      if (f.status === "completed") {
        return GREEN;
      }
      return GREY;
    }
    if (this.colorBy === "Priority") {
      if (f.priority === "Low") {
        return GREEN;
      }
      if (f.priority === "Medium") {
        return YELLOW;
      }
      if (f.priority === "High") {
        return RED;
      }
    }
    return GREY;
  }

  changeColor() {
    this.createMap('cc');
  }

  createMap(caller: string) {
    if (this.map === undefined) {
      const mapCenter = {
        lat: 37.0902,
        lng: 95.7129
      };
      console.log("Map center", mapCenter);
      this.map = new google.maps.Map(this.$refs.facilitymap, {
        center: mapCenter,
        zoom: this.zoom,
        mapTypeId: this.mapType
      });
    }
    if (this.$props.facilities && this.$props.facilities.length > 0) {
      // console.log("Equipment", this.$props.equipment);
      this.markers.forEach(m => {
        m.setMap(null);
        m = null;
      });
      this.markers = [];
      this.$props.facilities.forEach((f: Facility) => {
        //console.log("ET", et);
        // const icon = et.iconSvg.length === 0 ? null : {
        //   path: et.iconSvg,
        //   fillColor: et.color,
        //   fillOpacity: 1,
        //   anchor: new google.maps.Point(0, 0),
        //   strokeWeight: 0,
        //   scale: 1.5
        // };

        if (f.lat === null || f.lng === null) {
          // Pass
          console.log(`Skipping facility with null lat/lng ${f.name} - ${f.id}`);
          return;
        }

        /*  //Moved to more compact dialog box.  Keep this in case Zack broke something...
        const contentString =
            '<div id="content" class="card">' +
            '<div class="card-body">' +
            '<h5 id="firstHeading" class="firstHeading">' +
            f.name +
            "</h5>" +
            "</div>" +
            '<ul class="list-group list-group-flush">' +
            '<li class="list-group-item">' +
            f.address1 + '<br/>' +
            ((typeof f.address2 === 'string' && f.address2.length > 0) ? f.address2 + '<br/>' : '') +
            f.city + ' ' + f.state + ', ' + f.zip +
            '</li>' +
            '<li class="list-group-item">' +
            '<br/>Size: ' + f.size +
            '</li>' +
            '<li class="list-group-item">Priority: ' + f.priority + '</li>' +
            '</ul>' +
            '<div class="card-body">' +
            `<a href="javascript:router.push('/survey/${f.id}')" class="card-link">Survey</a>` +
            '</div>' +
            "</div>";
        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });
        try {
          const markerPos = { lat: f.lat, lng: f.lng };
          console.log("Marker pos", markerPos);
        */

        const contentString =
        '<div id="content">'+
              '<div id="siteNotice">'+
              '</div>'+
              '<h4 id="firstHeading" class="firstHeading">' + f.name + '</h4>'+
              '<div id="bodyContent">' +
                '<table class="table table-sm ">' +
                    '<tbody>' +
                      '<tr>' +
                        '<th scope="row">Address: </th>' +
                        '<td>' + f.address1 + ((typeof f.address2 === 'string' && f.address2.length > 0) ? + ' ' + f.address2 + ' ' : '') +', '+  f.city + ', ' + f.state + ' ' + f.zip +'</td>' +
                        '</td></tr>' +
                      '<tr>' +
                        '<th scope="row">Status: </th>' +
                        '<td>' +  f.status + 
                        '</td></tr>' +
                        '<tr>' +
                        '<th scope="row">Priority: </th>' +
                        '<td>' +  f.priority + 
                        '</td></tr>' +
                        '<tr>' +
                        '<th scope="row">Size: </th>' +
                        '<td>' +  f.size + 
                        '</td></tr>' +
                        '<tr>' +
                        '<th colspan="2" scope="row">' +  
                        `<a href="javascript:router.push('/survey/${f.id}')" class="link-opacity-50">View Site Details</a>` +
                        '</th></tr>' +
                      '</tbody>' +
                '</table>' +
                '</div>'+
         '</div>';
 
        const infowindow = new google.maps.InfoWindow({
          content: contentString
        });
        try {
          const markerPos = { lat: f.lat, lng: f.lng };
          console.log("Marker pos", markerPos);

          const marker = new google.maps.Marker({
            position: markerPos,
            surveyPosition: { lat: f.lat, lng: f.lng },
            surveyTable: "equipment",
            surveyId: f.id,
            map: this.map,
            icon: this.getMapIcon(f),
            zIndex: -20,
            draggable: false,
            title: f.name
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false,
              title: f.name
            });
          });

          this.markers.push(marker);
          this.bounds.extend(
              new google.maps.LatLng(
                  marker.position.lat(),
                  marker.position.lng()
              ),
              0
          );
          this.map.fitBounds(this.bounds);
        } catch (err) {
          console.error(`Error mapping ${err}`, f);
        }
      });
    }
  }
}
