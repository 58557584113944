
import { Component, Vue } from "vue-property-decorator";
import GoogleMap from "@/components/GoogleMap.vue";
import AddressDisplay from "@/components/AddressDisplay.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import { Contact, Facility } from "@/survey";
import PdfGenerator from "@/export/pdf";
import { getContactById, surveyClient } from "@/store";
import ContactDisplayNameEmailCall from "@/components/ContactDisplayNameEmailCall.vue";
import RolesMixinClass from "@/components/RolesMixinClass.vue";
import { mixins } from "vue-class-component";

@Component({
  components: {
    GoogleMap,
    AddressDisplay,
    DisplayImagesCarousel,
    ContactDisplayNameEmailCall
  }
})
export default class FacilityList extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  internalRowCount: number | null = null;

  get isPunch(){
    return this.$store.state.authentication.currentUser.punch;
  }
  facilityPunchReport(facility: Facility) {
    const tid = `T_${facility.facilityCode}_${new Date().getTime()}`;
    this.$bvToast.toast(`Creating PDF punch list report for ${facility.name}`, {
      id: tid,
      title: `${facility.facilityCode} Punch List Report`,
      variant: "info",
      noAutoHide: true,
      noCloseButton: true,
      appendToast: true
    });
    surveyClient.getEquipment({ facility: facility.id }).then(equipment => {
      surveyClient.getPunchItems({ facility: facility.id! }).then(punchItems => {
        surveyClient.getPunchFacility({ facility: facility.id! }).then(punchFacility => {
          new PdfGenerator().generatePunchReport(facility, equipment, punchItems, punchFacility).then(
              (pdfDoc: any) => {
                pdfDoc.download(`${facility.name}-punch.pdf`);
              })
              .finally(() => {
                this.$bvToast.hide(tid);
              });
        });
      });
    });
  }

  facilityReport(facility: Facility) {
    if (
        confirm(
            "Generating a report can take some time. Please click OK to continue and you will be notified when PDF is ready."
        )
    ) {
      const tid = `T_${facility.facilityCode}_${new Date().getTime()}`;
      this.$bvToast.toast(`Creating PDF report for ${facility.name}`, {
        id: tid,
        title: `${facility.facilityCode} Report`,
        variant: "info",
        noAutoHide: true,
        noCloseButton: true,
        appendToast: true
      });

      surveyClient.getEquipment({ facility: facility.id }).then(equipment => {
        surveyClient.getFencing({ facility: facility.id }).then(fencing => {
          surveyClient.getGates({ facility: facility.id }).then(gates => {
            new PdfGenerator()
                .generateFacilityReport(
                    facility,
                    equipment,
                    fencing,
                    gates,
                    this.$store.state.contacts,
                    this.$store.state.equipmentTypes
                )
                .then((pdfDoc: any) => {
                  pdfDoc.download(`${facility.name}.pdf`);
                })
                .finally(() => {
                  this.$bvToast.hide(tid);
                });
          });
        });
      });
    }
  }

  data() {
    return {
      filter: "",
      perPage: 25,
      currentPage: 1
    };
  }

  get fields() {
    return [
      { key: "name", sortable: true },
      { key: "aka", sortable: true, label: "AKA" },
      { key: "facilityCode", sortable: true, label: "Code" },
      { key: "surveyTeam", sortable: true, label: "Survey team" },
      { key: "surveyor", sortable: true, label: "Surveyor" },
      { key: "description", sortable: false },
      {
        key: "fullContact",
        label: "Contact",
        sortable: true,
        filterByFormatted: true,
        formatter: (value: Contact | null) => {
          return value?.firstName === undefined || value?.firstName === null
              ? `Unknown`
              : `${value?.firstName} ${value?.lastName}`;
        }
        // thClass: 'd-none', tdClass: 'd-none'
      },
      { key: "status", sortable: true },
      {
        key: "qa", sortable: true, label: "QAQC",
        formatter: (value: boolean | null) => {
          return value === true ? "Yes" : "No";
        },
      },
      {
        key: "qaCompleted", sortable: true, label: "QA Completed",
        formatter: (value: boolean | null) => {
          return value === true ? "Yes" : "No";
        },
      }
      // { key: "priority", sortable: true },
      // { key: "size", sortable: true }
    ];
  }

  get rowCount() {
    if (this.internalRowCount === null) {
      this.internalRowCount = this.facilities.length;
    }
    return this.internalRowCount;
  }

  get facilities() {
    return this.$store.state.facilities.filter((f: Facility) => {
      return f.survey || this.isPunch; // Remove if user is not punch user and facility is not survey facility
    }).map((f: Facility) => {
      return { ...f, fullContact: f.contact ? this.getContact(f.contact) : {} };
    });
  }

  getContact(value: number): Contact | undefined {
    return getContactById(value);
  }

  onFiltered(filteredItems: any[]) {
    this.internalRowCount = filteredItems.length;
  }
}
