
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  actions, Facility,
  User
} from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { contactsForSelect, surveyorsForSelect } from "@/store";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import { required } from "vuelidate/lib/validators";

function qaAndOrSurvey(val: string, all: Facility) {
  console.log("All", all);
  return all.survey || all.qa;
}

@Component({
  components: {
    InputWithLabel,
    FileUploadInput: FileUploadInput,
    VueBootstrapTypeahead
  },
  validations: {
    facility: {
      name: { required },
      facilityCode: { required },
      size: { required },
      priority: { required },
      status: { required },
      address1: { required },
      city: { required },
      state: { required },
      zip: { required },
      survey: { qaAndOrSurvey },
      qa: { qaAndOrSurvey }
    }
  }
})
export default class AdminFacilityForm extends Vue {
  @Prop({ default: false }) redirect?: boolean;
  @Prop({ default: true }) showFileInputs?: boolean;
  hours: any;
  hoursLoaded: any;
  typeahead?: any;
  facility?: any;
  show = true;

  // reRender() {
  // }

  get facilitySizeChoices(): { value: string; text: string }[] {
    return this.$store.getters.selectData("facility.size");
  }

  get facilityStatusChoices(): {
    value: string;
    text: string;
  }[] {
    return this.$store.getters.selectData("facility.status");
  }

  get facilityHoursChoices(): {
    value: string;
    text: string;
  }[] {
    return this.$store.getters.selectData("facility.ophours");
  }

  get facilityPriorityChoices(): {
    value: string;
    text: string;
  }[] {
    return this.$store.getters.selectData("facility.priority");
    //
    // return facilityPriorities.map(size => {
    //   return {
    //     value: size,
    //     text: size
    //   };
    // });
  }

  // created() {
  // }

  // loadHours() {
  //   Vue.prototype.$surveyClient.getHoursValues().then((vals: string[]) => {
  //     this.hours = vals.filter((h: string | null) => h !== null);
  //     this.hoursLoaded = true;
  //     this.$nextTick(
  //         () =>
  //             ((this.$refs.typeahead as any).inputValue = this.facility?.ophours)
  //     );
  //   });
  // }

  // mounted() {
  //   this.loadHours();
  // }

  data() {
    const contactSelectData = contactsForSelect();
    const surveyorSelectData = surveyorsForSelect();
    try {
      const facilityId = parseInt(this.$route.params.id);
      for (const facility of this.$store.state.facilities) {
        if (facility.id === facilityId) {
          return {
            show: true,
            hoursLoaded: false,
            hours: [],
            facility: { ...facility },
            facilityImage: null,
            imageComment: null,
            facilityFloorPlan: null,
            facilitySitePlan: null,
            contactSelectData,
            surveyorSelectData
          };
        }
      }
      throw "Cannot find facility for id " + facilityId;
    } catch (e) {
      return {
        hoursLoaded: false,
        hours: [],
        show: true,
        facility: {
          id: null,
          name: "",
          description: "",
          status: "Pending",
          priority: "Medium",
          size: "Small",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          secstaffonsite: false,
          visitorsignage: false,
          privpropsignage: false,
          lightingissues: false,
          ophours: "",
          notes: "",
          lat: "",
          lng: "",
          facilityCode: "",
          surveyTeam: "",
          aka: "",
          businessArea: "",
          region: "",
          qa: false,
          survey: false
        },
        facilityImage: null,
        imageComment: null,
        facilityFloorPlan: null,
        facilitySitePlan: null,
        contactSelectData,
        surveyorSelectData
      };
    }
  }

  mounted() {
    this.$v.$touch();
  }

  updated() {
    this.$v.$touch();
  }

  get readonly(): boolean {
    if (this.$store.state.authentication.currentUser === null) {
      return true;
    }
    const user = this.$store.state.authentication.currentUser as User;
    return !(user.admin || user.edit);
    //return false;
  }

  backToList() {
    this.$router.push({ path: "/admin/facilities" });
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }

    this.$bvToast.toast(`Saving...`, {
      title: "Saving Facility",
      variant: "info",
      autoHideDelay: 3000,
      appendToast: true
    });
    this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY, {
      toSave: this.$data.facility,
      image: this.$data.facilityImage,
      sitePlan: this.$data.facilitySitePlan,
      floorPlan: this.$data.facilityFloorPlan,
      success: () => {
        this.$bvToast.toast(`Success`, {
          title: "Facility Saved!",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: true
        });
        if (this.$data.facility.id == null) {
          this.$router.push({ path: "/admin/facilities" });
        }
      }
    });
  }
}
