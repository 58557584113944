
import { Component, Vue } from "vue-property-decorator";
import InputWithLabel from "@/components/InputWithLabel.vue";
import {
  allFencingForSelect,
  contactsForSelect,
  facilitiesForSelect,
  gateAcctypeChoicesStore,
  gateOptypeChoicesStore,
  gateTypeChoicesStore,
  manufacturersForSelect,
  tagsForSelect
} from "@/store";
import { actions, Equipment, Fencing, GateExample } from "@/survey";
import EquipmentTable from "@/views/EquipmentTable.vue";
import GateTable from "@/views/GateTable.vue";
import FencingTable from "@/views/FencingTable.vue";

function initialFormData() {
  return {
    tagOptions: tagsForSelect(),
    showTags: false,

    contactOptions: contactsForSelect(),
    showContacts: false,

    facilityOptions: facilitiesForSelect(),
    showFacilities: true,

    manufacturerOptions: manufacturersForSelect(),
    showManufacturers: false,

    criteria: {
      searchTerm: "",

      searchEquipment: true,
      equipmentExample: {} as Equipment,

      searchFencing: false,
      fencingExample: {} as Fencing,

      searchGates: false,
      gateExample: {
        gatetype: [],
        optype: [],
        acctype: [],
        fencing: []
      } as GateExample,

      selectedTags: [],
      selectedContacts: [],
      selectedFacilities: [],
      selectedManufacturers: []
    }
  };
}

@Component({
  components: { InputWithLabel, EquipmentTable, GateTable, FencingTable }
})
export default class ReportForm extends Vue {
  doReset() {
    Object.assign(this.$data, initialFormData());
  }

  doSearch() {
    this.$store.dispatch(actions.SEARCH, {
      ...this.$data.criteria,
      success: () => this.$router.push("/report-display")
    });
  }

  get gateTypeChoices() {
    return gateTypeChoicesStore();
  }

  get gateOptypeChoices() {
    return gateOptypeChoicesStore();
  }

  get gateAcctypeChoices() {
    return gateAcctypeChoicesStore();
  }

  get fencingChoices() {
    return allFencingForSelect();
  }

  data() {
    return initialFormData();
  }
}
