import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  Attachable,
  AttachmentMeta,
  Contact,
  Equipment,
  EquipmentType,
  Facility,
  Fencing,
  Gate,
  GeoLocatable,
  Manufacturer,
  PunchFacility,
  PunchItem,
  User
} from "@/survey";
import Vue from "vue";
import axios from "axios";
import { getAddressAsText, getFacilityHeaderFields, getUserHeaderFields } from "@/export/util";
import { getEquipmentType, getFacilityById, getFencingById } from "@/store";
import { equipmentTypeFormatter } from "@/components/formatters";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface MetaAndData {
  meta: AttachmentMeta;
  data: string;
}

export default class PdfGenerator {
  getBooleanClassFromBoolean(value = false): string {
    return `b${value}`;
  }

  fetchAndAddImages(
    promisesToWaitFor: Promise<any>[],
    items: Attachable[],
    imagesContent: any[],
    getName: (x: any) => string
  ) {
    items.forEach(item => {
      if (item.attachmentMeta && item.attachmentMeta.length > 0) {
        // dd.content.push({
        promisesToWaitFor.push(
          this.getImageData(item.attachmentMeta).then(imageUrls => {
            imagesContent.push({
              text: `${getName(item)} images`,
              style: "subheader",
              pageBreak: "before"
            });
            // this.addImagesToContent(imageUrls, dd, facility.name);
            this.addImagesToContent(imageUrls, imagesContent, getName(item));
            //dd.content.push({
            imagesContent.push({
              text: `End of ${getName(item)} images`,
              style: "subheader"
            });
          })
        );
      }
    });
  }

  tableWithHeaderRow(title: string, headerRow: any[], content: any[], pageSize = "A2", pageOrientation = "landscape"): any {
    const dd: any = {
      pageSize: pageSize,
      pageOrientation: pageOrientation,
      content: [
        {
          text: title,
          style: "header"
        },
        {
          columns: [
            { width: "*", text: "" },
            {
              width: "auto",
              style: "headerLineOnly",
              table: {
                headerRows: 1,
                body: [headerRow].concat(content)
              }
            }
          ]
        }
      ],
      styles: this.getStyles()
    };
    return dd;
  }

  async getManufacturersTable(
    manufacturers: Manufacturer[],
    contacts: Contact[]
  ): Promise<unknown> {
    return new Promise(resolve => {
      const manufacturersTableArray = manufacturers.map(m => {
        const contact = contacts.reduce((acc: Contact, current: Contact) => {
          if (current.id === m.contact) {
            return current;
          }
          return acc;
        });
        return [
          this.getStyledText(`${m.company}`, "tableText"),
          this.getStyledText(`${m.website}`, "tableText"),
          this.getStyledText(
            `${contact.firstName} ${contact.lastName}`,
            "tableText"
          ),
          this.getStyledText(`${m.contactEmail}`, "tableText"),
          this.getStyledText(`${m.mainPhone}`, "tableText"),
          this.getStyledText(`${m.address1}\n${m.address2}`, "tableText"),
          this.getStyledText(`${m.city}, ${m.state} ${m.zip}`, "tableText")
        ];
      });
      const headerRow: any[] = [
        this.getStyledText("Company", "tableHeader"),
        this.getStyledText("Website", "tableHeader"),
        this.getStyledText("Contact", "tableHeader"),
        this.getStyledText("Contact Email", "tableHeader"),
        this.getStyledText("Main Phone", "tableHeader"),
        this.getStyledText("Address", "tableHeader"),
        this.getStyledText("Address", "tableHeader")
      ];

      resolve(
        pdfMake.createPdf(
          this.tableWithHeaderRow(
            "Manufacturers",
            headerRow,
            manufacturersTableArray
          )
        )
      );
    });
  }

  async getUsersTable(users: User[]): Promise<unknown> {
    return new Promise(resolve => {
      const usersTableArray = users.map(u => {
        return [
          this.getStyledText(`${u.username}`, "tableText"),
          this.getStyledText(`${u.firstName} ${u.lastName}`, "tableText"),
          this.getStyledText(`${u.email}`, "tableText"),
          this.getStyledText(`${u.phone}`, "tableText"),
          this.getStyledText(`${u.active}`, "tableText"),
          this.getStyledText(`${u.dateCreated}`, "tableText"),
          this.getStyledText(`${u.create}`, "tableText"),
          this.getStyledText(`${u.edit}`, "tableText"),
          this.getStyledText(`${u.view}`, "tableText"),
          this.getStyledText(`${u.delete}`, "tableText"),
          this.getStyledText(`${u.export}`, "tableText"),
          this.getStyledText(`${u.admin}`, "tableText")
        ];
      });
      const headerRow = getUserHeaderFields().map(f => {
        return this.getStyledText(f, "tableHeader");
      });
      resolve(
        pdfMake.createPdf(
          this.tableWithHeaderRow("Users", headerRow, usersTableArray)
        )
      );
    });
  }

  async getUserActivityTable(activity: any[]): Promise<unknown> {
    return new Promise(resolve => {
      const tableArray: any = [];
      activity.forEach((c: any) => {
        tableArray.push([
          this.getStyledText(c.user, "tableHeader"),
          this.getStyledText(c.action, "tableHeader"),
          this.getStyledText(c.objectType, "tableHeader"),
          this.getStyledText(this.formatDate(c.happenedTimestamp), "tableHeader")
        ]);
      });
      const headerRow = [
        // this.getStyledText("Id", "tableHeader"),
        this.getStyledText("User", "tableHeader"),
        this.getStyledText("Action", "tableHeader"),
        this.getStyledText("Object type", "tableHeader"),
        this.getStyledText("Date", "tableHeader")
      ];
      resolve(
        pdfMake.createPdf(
          this.tableWithHeaderRow("User Activity Report", headerRow, tableArray, "A4", "portrait")
        )
      );
    });
  }

  async getContactsTable(contacts: Contact[]): Promise<unknown> {
    return new Promise(resolve => {
      const contactTableArray: any = [];
      contacts.forEach((c: Contact) => {
        contactTableArray.push([
          this.getStyledText(`${c.firstName} ${c.lastName}`, "tableHeader"),
          this.getStyledText(c.email, "tableHeader"),
          this.getStyledText(c.phone, "tableHeader"),
          this.getStyledText(c.phone2, "tableHeader"),
          this.getStyledText(c.company, "tableHeader"),
          this.getStyledText(c.title, "tableHeader"),
          this.getStyledText(`${c.address1}\n${c.address2}`, "tableHeader"),
          this.getStyledText(`${c.city}, ${c.state} ${c.zip}`, "tableHeader"),
          this.getStyledText(c.contactType, "tableHeader"),
          this.getStyledText(c.notes, "tableHeader")
        ]);
      });
      const headerRow = [
        // this.getStyledText("Id", "tableHeader"),
        this.getStyledText("Name", "tableHeader"),
        this.getStyledText("Email", "tableHeader"),
        this.getStyledText("Phone", "tableHeader"),
        this.getStyledText("Phone 2", "tableHeader"),
        this.getStyledText("Company", "tableHeader"),
        this.getStyledText("Title", "tableHeader"),
        this.getStyledText("Address", "tableHeader"),
        this.getStyledText("Address", "tableHeader"),
        this.getStyledText("Type", "tableHeader"),
        this.getStyledText("Notes", "tableHeader")
      ];
      resolve(
        pdfMake.createPdf(
          this.tableWithHeaderRow("Contacts", headerRow, contactTableArray)
        )
      );
    });
  }

  async getFacilityTable(
    facilities: Facility[],
    contacts: Contact[],
    users: User[]
  ): Promise<unknown> {
    return new Promise(resolve => {
      const facilityTableArray = facilities.map(facility => {
        const contact = contacts.reduce((acc: Contact, current: Contact) => {
          if (current.id === facility.contact) {
            return current;
          }
          return acc;
        });

        return [
          this.getStyledText(facility.facilityCode, "tableText"),
          this.getStyledText(facility.name, "tableText"),
          this.getStyledText(facility.aka, "tableText"),
          this.getStyledText(facility.surveyTeam, "tableText"),
          this.getStyledText(facility.surveyor, "tableText"),
          this.getStyledText(
            `${contact.firstName} ${contact.lastName}`,
            "tableText"
          ),
          this.getStyledText(facility.status, "tableText"),
          this.getStyledText(facility.priority, "tableText"),
          this.getStyledText(facility.size, "tableText"),
          this.getStyledText(getAddressAsText(facility), "tableText"),
          this.getStyledText(facility.ophours, "tableText"),
          this.getStyledText(
            facility.secstaffonsite.toString(),
            this.getBooleanClassFromBoolean(facility.secstaffonsite)
          ),
          this.getStyledText(
            facility.visitorsignage.toString(),
            this.getBooleanClassFromBoolean(facility.visitorsignage)
          ),
          this.getStyledText(
            facility.privpropsignage.toString(),
            this.getBooleanClassFromBoolean(facility.privpropsignage)
          ),
          this.getStyledText(
            facility.lightingissues.toString(),
            this.getBooleanClassFromBoolean(facility.lightingissues)
          ),
          this.getStyledText(
            facility.hasBathroom.toString(),
            this.getBooleanClassFromBoolean(facility.lightingissues)
          ),
          this.getStyledText(
            facility.asbestosSignage.toString(),
            this.getBooleanClassFromBoolean(facility.lightingissues)
          )
        ];
      });
      const headerRow = getFacilityHeaderFields().map(f => {
        return this.getStyledText(f, "tableHeader");
      });
      resolve(
        pdfMake.createPdf(
          this.tableWithHeaderRow("Facilities", headerRow, facilityTableArray)
        )
      );
    });
  }

  async generateReportReport(
    facilities: Facility[],
    equipment: Equipment[],
    fencing: Fencing[],
    gates: Gate[],
    contacts: Contact[],
    equipmentTypes: EquipmentType[]
  ): Promise<unknown> {
    return new Promise(resolve => {
      const date = new Date();
      const dd: any = {
        pageOrientation: "landscape",
        pageSize: "A2",
        content: [
          {
            text: `Priplan Report - ${this.padNumber(date.getMonth() + 1)}/${this.padNumber(date.getDate())}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`,
            style: "header"
          },
          {
            text: `(${equipment.length}) Equipment`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getEquipmentTable(equipment, equipmentTypes, facilities)
          },
          {
            text: `(${fencing.length}) Fencing`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getFencingTable(fencing, facilities)
          },
          {
            text: `(${gates.length}) Gates`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getGateTable(gates, facilities)
          }
        ],
        styles: this.getStyles()
      };
      const promisesToWaitFor = [] as any[];
      const equipmentImagesContent: any[] = [];
      const fencingImagesContent: any[] = [];
      const gateImagesContent: any[] = [];

      this.fetchAndAddImages(
        promisesToWaitFor,
        equipment,
        equipmentImagesContent,
        (x: any) => x.name
      );
      this.fetchAndAddImages(
        promisesToWaitFor,
        fencing,
        fencingImagesContent,
        (x: any) => x.name
      );
      this.fetchAndAddImages(
        promisesToWaitFor,
        gates,
        gateImagesContent,
        (x: any) => x.name
      );

      Promise.all(promisesToWaitFor).then(() => {
        dd.content = dd.content
          .concat(equipmentImagesContent)
          .concat(fencingImagesContent)
          .concat(gateImagesContent);
        resolve(pdfMake.createPdf(dd));
      });
    });
  }

  async generatePunchReport(facility: Facility, equipment: Equipment[], punchItems: PunchItem[], punchFacility: PunchFacility[])
    : Promise<unknown> {
    return new Promise(resolve => {
      const getEquipmentName = ((id: number) => {
        let ret = "Unknown";
        equipment.forEach(e => {
          if (e.id === id) {
            ret = e.name;
          }
        });
        return ret;
      });

      const piArray = punchItems.map((i: PunchItem) => {
        return [
          this.getStyledText(getEquipmentName(i.equipment), "tableText"),
          this.getStyledText(i.surveyor, "tableText"),
          this.getStyledText(this.formatDate(i.started + ""), "tableText"),
          this.getStyledText(this.formatDate(i.completed + ""), "tableText"),
          this.getStyledText(i.notes, "tableText"),
          i.tags!.map(t => t.tag)
        ];
      });
      const promisesToWaitFor = [] as Promise<any>[];
      const date = new Date();
      const dd: any = {
        pageOrientation: "landscape",
        content: [
          {
            text: `Punch List for ${facility.name} - ${
              facility.facilityCode
            } - ${this.padNumber(date.getMonth() + 1)}/${this.padNumber(date.getDate())}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()} Surveyor: ${facility.surveyor ||
            "Unknown"}`,
            style: "header"
          },
          {
            text: `${punchFacility.length > 0 && punchFacility[0].notes ? punchFacility[0].notes : "No punch list notes for facility"}`,
            style: "header"
          },
          // {
          //   text: `${JSON.stringify(punchItems)}`
          // },
          {
            columns: [
              { width: "*", text: "" },
              {
                width: "auto",
                style: "headerLineOnly",
                table: {
                  headerRows: 1,
                  body: [
                    [
                      { text: "Equipment", style: "tableHeader" },
                      { text: "Surveyor", style: "tableHeader" },
                      { text: "Started", style: "tableHeader" },
                      { text: "Completed", style: "tableHeader" },
                      { text: "Notes", style: "tableHeader" },
                      { text: "Tags", stule: "tableHeader" }
                    ]
                  ].concat(
                    piArray as any
                  )
                }
              },
              { width: "*", text: "" }
            ]
          }
        ],
        styles: this.getStyles()
      };
      const punchItemsImagesContent = [] as any[];
      this.fetchAndAddImages(
        promisesToWaitFor,
        punchItems,
        punchItemsImagesContent,
        (x: any) => getEquipmentName(x.equipment)
      );
      Promise.all(promisesToWaitFor).then(() => {
        dd.content = dd.content
          .concat({
            text: `Punch list represents site visit by consultant to determine and document specific device checklists and general workmanship, and does not represent a comprehensive review of completeness of installation, functional testing, or contract compliance or performance.`,
            // pageBreak: "before",
            style: "disclaimer"
          })
          .concat(punchItemsImagesContent);
        // .concat(facilityImagesContent)
        // .concat(equipmentImagesContent)
        // .concat(fencingImagesContent)
        // .concat(gateImagesContent);
        resolve(pdfMake.createPdf(dd));
      });
    });
  }

  async generateFacilityReport(
    facility: Facility,
    equipment: Equipment[],
    fencing: Fencing[],
    gates: Gate[],
    contacts: Contact[],
    equipmentTypes: EquipmentType[]
  ): Promise<unknown> {
    let c: Contact;
    contacts.forEach(contact => {
      if (contact.id === facility.contact) {
        c = contact;
      }
    });
    const date = new Date();

    // let equipmentMapData: any = {
    //   text: `No map available`,
    //   style: "header"
    // };
    // try {
    //     equipmentMapData = {
    //     image: await this.getStaticMapByGeoLocatable(facility),
    //     width: 600
    //   };
    // } catch (e) {
    //   alert("Error getting map from Google");
    // }

    let facilityMapData: any = {
      text: `No map available`,
      style: "header"
    };
    try {
      facilityMapData = {
        image: await this.getStaticMapByGeoLocatable(facility),
        width: 600
      };
    } catch (e) {
      console.error("Error getting map from Google");
    }
    return new Promise(resolve => {
      const dd: any = {
        pageOrientation: "landscape",
        content: [
          {
            text: `${facility.name} - ${
              facility.facilityCode
            } ${facility.aka ? `(${facility.aka})` : ""} - ${this.padNumber(date.getMonth() + 1)}/${this.padNumber(date.getDate())}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()} Surveyor: ${facility.surveyor ||
            "Unknown"}`,
            style: "header"
          },
          {
            text: facility.description,
            style: "subheader"
          },
          {
            columns: [
              { width: "*", text: "" },
              {
                width: "auto",
                style: "headerLineOnly",
                table: {
                  headerRows: 1,
                  body: [
                    [
                      { text: "Contact", style: "tableHeader" },
                      { text: "Address", style: "tableHeader" },
                      { text: "Site", style: "tableHeader" },
                      {
                        text: "Attributes",
                        style: "tableHeader"
                      }
                    ],
                    [
                      {
                        text:
                          c === undefined
                            ? `Unknown`
                            : `${c.firstName} ${c.lastName}\n${c.email}\n${c.phone}`,
                        style: "tableCell"
                      },
                      {
                        text: getAddressAsText(facility),
                        style: "tableCell"
                      },
                      {
                        table: {
                          body: [
                            [{ text: "Status", bold: true }, facility.status],
                            [
                              { text: "Priority", bold: true },
                              facility.priority
                            ],
                            [{ text: "Size", bold: true }, facility.size]
                          ]
                        },
                        style: "tableCell"
                      },
                      {
                        table: {
                          body: [
                            [
                              { text: "Security Staff", bold: true },
                              {
                                text: facility.secstaffonsite,
                                style: this.getBooleanClassFromBoolean(
                                  facility.secstaffonsite
                                )
                              }
                            ],
                            [
                              { text: "Visitor Signage", bold: true },
                              {
                                text: facility.visitorsignage,
                                style: this.getBooleanClassFromBoolean(
                                  facility.visitorsignage
                                )
                              }
                            ],
                            [
                              { text: "Private Property Signage", bold: true },
                              {
                                text: facility.privpropsignage,
                                style: this.getBooleanClassFromBoolean(
                                  facility.privpropsignage
                                )
                              }
                            ],
                            [
                              { text: "Lighting Issues", bold: true },
                              {
                                text: facility.lightingissues,
                                style: this.getBooleanClassFromBoolean(
                                  facility.lightingissues
                                )
                              }
                            ],
                            [
                              { text: "Hours", bold: true },
                              {
                                text: facility.ophours
                              }
                            ]
                          ],
                          style: "tableCell"
                        }
                      }
                    ]
                  ]
                }
              },
              { width: "*", text: "" }
            ] // End columns
          },
          {
            text: `Coordinates: (${
              facility.lat && facility.lng
                ? facility.lat + ", " + facility.lng
                : "Unknown"
            })`,
            style: "subheader"
          },
          {
            text: "Notes",
            style: "subheader"
          },
          {
            text: facility.notes ? facility.notes : "None.",
            style: "text"
          },
          facilityMapData,
          {
            text: `(${equipment.length}) Equipment for ${facility.name}`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getEquipmentTable(equipment, equipmentTypes)
          },
          {
            text: `(${fencing.length}) Fencing for ${facility.name}`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getFencingTable(fencing)
          },
          {
            text: `(${gates.length}) Gates for ${facility.name}`,
            pageBreak: "before",
            style: "subheader"
          },
          {
            table: this.getGateTable(gates)
          }
        ],
        styles: this.getStyles()
      };

      const promisesToWaitFor = [] as any[];
      const facilityImagesContent: any[] = [];
      const equipmentImagesContent: any[] = [];
      const fencingImagesContent: any[] = [];
      const gateImagesContent: any[] = [];

      this.fetchAndAddImages(
        promisesToWaitFor,
        [facility],
        facilityImagesContent,
        (x: any) => x.name
      );
      this.fetchAndAddImages(
        promisesToWaitFor,
        equipment,
        equipmentImagesContent,
        (x: any) => x.name
      );
      this.fetchAndAddImages(
        promisesToWaitFor,
        fencing,
        fencingImagesContent,
        (x: any) => x.name
      );
      this.fetchAndAddImages(
        promisesToWaitFor,
        gates,
        gateImagesContent,
        (x: any) => x.name
      );

      Promise.all(promisesToWaitFor).then(() => {
        dd.content = dd.content
          .concat(facilityImagesContent)
          .concat(equipmentImagesContent)
          .concat(fencingImagesContent)
          .concat(gateImagesContent);
        resolve(pdfMake.createPdf(dd));
      });
    });
  }

  getStyledText(text: string | null | undefined | number, style: string) {
    if (text === null || text === undefined) {
      text = "";
    }
    return { text: `${text}`, style };
  }

  padNumber(num: number) {
    return num > 9 ? "" + num : "0" + num;
  }

  formatDate(dateString: string | null): string {
    if (dateString === null) {
      return "Unknown";
    }
    const t = parseInt(dateString);
    if (Number.isNaN(t)) {
      return "";
    }
    const date = new Date(t);
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  getGateTable(gates: Gate[], facilities?: Facility[]): any {
    let showFacility = false;
    let colspanOffset = 0;
    if (facilities !== undefined && facilities.length > 0) {
      showFacility = true;
      colspanOffset = 1;
    }
    if (gates.length === 0) {
      return {
        headerRows: 1,
        body: [[this.getStyledText("No Gates", "tableHeader")]]
      };
    }
    const gateArray: any[] = [];
    gates.forEach((g: Gate) => {
      const toAdd = [
        this.getStyledText(g.name, "tableText"),
        this.getStyledText(`${g.surveyTeam}`, "tableText"),
        this.getStyledText(g.description, "tableText"),
        this.getStyledText(g.gatetype, "tableText"),
        this.getStyledText(g.optype, "tableText"),
        this.getStyledText(g.acctype, "tableText")
      ];
      if (showFacility) {
        const fencing = getFencingById(g.fencing);
        const facility = getFacilityById(fencing?.facility!);
        toAdd.splice(1, 0, this.getStyledText(facility!.name, "tableText"));
      }
      gateArray.push(toAdd);
      if (g.notes && g.notes.length > 0) {
        gateArray.push([
          this.getStyledText("Notes", "tableHeader"),
          { text: g.notes, style: "tableText", colSpan: 5 + colspanOffset }
        ]);
      }
      gateArray.push([
        { text: "", colSpan: 6 + colspanOffset, style: "greyFill" }
      ]);
    });
    const headerRow = [
      // Header
      this.getStyledText("Name", "tableHeader"),
      this.getStyledText("Survey Team", "tableHeader"),
      this.getStyledText("Description", "tableHeader"),
      this.getStyledText("Gate Type", "tableHeader"),
      this.getStyledText("Operation", "tableHeader"),
      this.getStyledText("Access Control", "tableHeader")
    ];
    if (showFacility) {
      headerRow.splice(1, 0, this.getStyledText("Facility", "tableHeader"));
    }
    const table = {
      headerRows: 1,
      body: [headerRow].concat(gateArray)
    };
    return table;
  }

  getFencingTable(fencing: Fencing[], facilities?: Facility[]): any {
    if (fencing.length === 0) {
      return {
        headerRows: 1,
        body: [[this.getStyledText("No Fencing", "tableHeader")]]
      };
    }

    let showFacility = false;
    let colspanOffset = 0;
    if (facilities !== undefined && facilities.length > 0) {
      showFacility = true;
      colspanOffset = 1;
    }
    const fencingArray: any[] = [];
    fencing.forEach(f => {
      const toAdd = [
        this.getStyledText(f.name, "tableText"),
        this.getStyledText(`${f.surveyTeam}`, "tableText"),
        this.getStyledText(
          `${f.isfenced || false}`,
          this.getBooleanClassFromBoolean(f.isfenced || false)
        ),
        this.getStyledText(
          `${f.fencedamaged || false}`,
          this.getBooleanClassFromBoolean(!f.fencedamaged)
        ),
        this.getStyledText(
          `${f.vegproblem || false}`,
          this.getBooleanClassFromBoolean(!f.vegproblem)
        ),
        this.getStyledText(
          `${f.climbingaids || false}`,
          this.getBooleanClassFromBoolean(!f.climbingaids)
        )
      ];
      if (showFacility) {
        const facility = getFacilityById(f.id!);
        toAdd.splice(1, 0, this.getStyledText(facility!.name, "tableText"));
      }
      fencingArray.push(toAdd);
      if (f.notes && f.notes.length > 0) {
        fencingArray.push([
          this.getStyledText("Notes", "tableHeader"),
          { text: f.notes, style: "tableText", colSpan: 5 + colspanOffset }
        ]);
      }
      fencingArray.push([
        { text: "", colSpan: 6 + colspanOffset, style: "greyFill" }
      ]);
    });
    const headerRow = [
      // Header
      this.getStyledText("Name", "tableHeader"),
      this.getStyledText("Survey Team", "tableHeader"),
      this.getStyledText("Fenced", "tableHeader"),
      this.getStyledText("Damaged", "tableHeader"),
      this.getStyledText("Vegetation", "tableHeader"),
      this.getStyledText("Climbing Aids", "tableHeader")
    ];
    if (showFacility) {
      headerRow.splice(1, 0, this.getStyledText("Facility", "tableHeader"));
    }
    const table = {
      headerRows: 1,
      body: [headerRow].concat(fencingArray as any)
    };
    return table;
  }

  getEquipmentTable(
    equipment: Equipment[],
    equipmentTypes: EquipmentType[],
    facilities?: Facility[]
  ): any {
    if (equipment.length === 0) {
      return {
        headerRows: 1,
        body: [[this.getStyledText("No Equipment", "tableHeader")]]
      };
    }
    const equipmentArray: any[] = [];
    let showFacility = false;
    let colspanOffset = 0;
    if (facilities !== undefined && facilities.length > 0) {
      showFacility = true;
      colspanOffset = colspanOffset + 1;
    }
    const equipmentTypeRenderer = equipmentTypeFormatter(equipmentTypes);
    equipment.forEach(equipment => {
      const toAdd = [
        this.getTypeInfo(equipment, equipmentTypeRenderer)
        ,
        this.getStyledText(
          [equipment.name, equipment.standardizedName].join("\n\n"),
          "tableText"
        ),
        this.getStyledText(
          equipment.surveyTeam === null ? "" : equipment.surveyTeam,
          "tableText"
        ),
        this.getStyledText(
          `${equipment.surveyed === true ? "Y" : "N"}`,
          this.getBooleanClassFromBoolean(equipment.surveyed === true)
        ),
        this.getStyledText(equipment.description, "tableText"),
        this.getStyledText(
          [equipment.modelNo, equipment.serialNo].join("\n\n"),
          "tableText"
        ),
        this.getStyledText(
          [
            // equipment.ipAddress || "No IP",
            equipment.macAddress || "No MAC",
            equipment.lat && equipment.lng
              ? "(" + equipment.lat + ", " + equipment.lng + ")"
              : ""
          ].join("\n\n"),
          "tableText"
        ),
        this.getStyledText(equipment.location, "tableText"),
        this.getStyledText([this.formatDate(equipment.installDate), equipment.vendor].join("\n\n"), "tableText"),
        // this.getStyledText(equipment.vendor, "tableText"),
        this.getStyledText(
          `${equipment.isLabeled || false}`,
          this.getBooleanClassFromBoolean(equipment.isLabeled || false)
        ),
        this.getDoorInfo(equipment)
      ];
      if (showFacility) {
        const facility = getFacilityById(equipment.facility!);
        toAdd.splice(1, 0, this.getStyledText(facility!.name, "tableText"));
      }
      equipmentArray.push(toAdd);
      if (equipment.notes && equipment.notes.length > 0) {
        equipmentArray.push([
          this.getStyledText("Notes", "tableHeader"),
          {
            text: equipment.notes,
            style: "tableText",
            colSpan: 10 + colspanOffset
          }
        ]);
      }
      equipmentArray.push([
        { text: "", colSpan: 11 + colspanOffset, style: "greyFill" }
      ]);
    });

    const headerRow = [
      // Header
      this.getStyledText("Type", "tableHeader"),
      this.getStyledText("Name", "tableHeader"),
      this.getStyledText("Survey Team", "tableHeader"),
      this.getStyledText("Surveyed", "tableHeader"),
      this.getStyledText("Description", "tableHeader"),
      this.getStyledText("Model/Serial", "tableHeader"),
      this.getStyledText("IP/MAC", "tableHeader"),
      this.getStyledText("Location", "tableHeader"),
      this.getStyledText("Installed/Installer", "tableHeader"),
      // this.getStyledText("Installer", "tableHeader"),
      this.getStyledText("Labeled", "tableHeader"),
      this.getStyledText("Door", "tableHeader")
    ]; // End header
    if (showFacility) {
      headerRow.splice(1, 0, this.getStyledText("Facility", "tableHeader"));
    }
    const table = {
      headerRows: 1,
      body: [headerRow].concat(equipmentArray as any)
    };
    console.log("Equipment table", table);
    console.log(JSON.stringify(table, null, 2));
    return table;
  }

  getTypeInfo(equipment: Equipment, equipmentTypeRenderer: any): any {
    if (getEquipmentType(equipment.equipmentType).name !== "Node") {
      return this.getStyledText(
        equipmentTypeRenderer(equipment.equipmentType),
        "tableText"
      );
    }
    const table = {
      table: {
        body: [
          ["Node"],
          [
            this.getStyledText(
              `AC PF Alarm`,
              this.getBooleanClassFromBoolean(
                equipment.acPowerFailAlarm === 2 || false
              )
            )
          ],
          [
            this.getStyledText(
              `Battery`,
              this.getBooleanClassFromBoolean(
                equipment.batteryInstalled === 2 || false
              )
            )
          ],
          [
            this.getStyledText(
              `Low bat Alarm`,
              this.getBooleanClassFromBoolean(
                equipment.lowBatteryAlarm === 2 || false
              )
            )
          ]
        ]
      }
    };
    if (equipment.batteryInstalled === 2) {
      table.table.body.push([this.getStyledText(this.formatDate(equipment.batteryInstallDate), "tableText")]);
    }
    return table;
  }

  getDoorInfo(equipment: Equipment): any {
    if (!(equipment.door === 2)) {
      return this.getStyledText("Not door", "tableText");
    } else {
      return {
        table: {
          body: [
            [equipment.exterior === 2 ? "Exterior" : "Interior"],
            [equipment.public === 2 ? "Public" : "Not Public"],
            [
              this.getStyledText(
                `Valid read`,
                this.getBooleanClassFromBoolean(
                  equipment.validRead === 2 || false
                )
              )
            ],
            [
              this.getStyledText(
                `Forced`,
                this.getBooleanClassFromBoolean(
                  equipment.doorForced === 2 || false
                )
              )
            ],
            [
              this.getStyledText(
                `Held`,
                this.getBooleanClassFromBoolean(
                  equipment.doorHeld === 2 || false
                )
              )
            ],
            [
              this.getStyledText(
                `REX`,
                this.getBooleanClassFromBoolean(
                  equipment.doorRex === 2 || false
                )
              )
            ]
          ]
        }
      };
    }
  }

  addImagesToContent(imageUrls: MetaAndData[], dd: any, caption: string): void {
    const cols = 1;
    const colWidth = 750 / cols;
    let start = 0;
    let end = cols;

    let arr = imageUrls.slice(start, end);
    while (arr.length > 0) {
      // dd.content.push({
      dd.push({
        style: "text",
        unbreakable: true,
        table: {
          body: [
            arr.map((img) => {
              return [
                img.meta.notes ? img.meta.notes : "No comment",
                {
                  image: img.data,
                  fit: [600, 600]
                },
                caption
              ];
            })
          ]
        }
      });
      start = start + cols;
      end = end + cols;
      arr = imageUrls.slice(start, end);
    }
  }

  async getStaticMapByGeoLocatable(
    geoLocatable: GeoLocatable
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const url = `${Vue.prototype.$apiLocation}maps?lat=${geoLocatable.lat}&lng=${geoLocatable.lng}&height=800&width=800`;
      return axios
        .get<any>(url, {
          responseType: "arraybuffer"
        })
        .then(data => {
          const image = Buffer.from(data.data, "binary").toString("base64");
          const contentType = data.headers["content-type"];
          if (contentType.indexOf("image") > -1) {
            resolve(`data:${data.headers["content-type"]};base64,${image}`);
          } else {
            reject(`Invalid content type: ${contentType}`);
          }
        })
        .catch(error => alert("Cannot fetch map for report!"));
    });
  }

  // async getStaticMapByAddress(addressable: Addressable): Promise<any> {
  //     <img src="https://maps.googleapis.com/maps/api/staticmap?center=Berkeley,CA&marker=center=Berkeley,CA&zoom=14&size=400x400&key=AIzaSyC6ygSFTWeIKAXmpYHsqQ1403C3TQQF-Bw"/>
  //     <img src=""/>
  // }

  async getImageData(attachmentMeta: AttachmentMeta[]): Promise<MetaAndData[]> {
    return new Promise<MetaAndData[]>((resolve, reject) => {
      const imageUrls: MetaAndData[] = [];
      let promises: Promise<any>[] = [];

      try {
        promises = attachmentMeta.map(meta => {
          return axios
            .get<any>(`${Vue.prototype.$apiLocation}attach/${meta.id}?table=${meta.tablename}`, {
              responseType: "arraybuffer"
            })
            .then(data => {
              const image = Buffer.from(data.data, "binary").toString("base64");
              if (data.headers["content-type"].indexOf("image") > -1) {
                imageUrls.push({
                  meta,
                  data: `data:${data.headers["content-type"]};base64,${image}`
                });
              }
            });
        });
        Promise.all(promises).then(() => resolve(imageUrls));
      } catch (e) {
        reject(e);
      }
    });
  }

  getStyles() {
    return {
      disclaimer: {
        fontSize: 10,
        marginBottom: 5,
        marginTop: 20,
        fontColor: "red",
        bold: true
      },
      headerLineOnly: {
        alignment: 'center'
      },
      header: {
        fontSize: 18,
        marginBottom: 5,
        bold: true,
        alignment: 'center'
      },
      subheader: {
        fontSize: 15,
        marginBottom: 5,
        bold: true
      },
      text: {
        marginBottom: 5
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black"
      },
      tableCell: {
        padding: 10
      },
      btrue: {
        fillColor: "#00FF00"
      },
      bfalse: {
        fillColor: "#FF0000"
      },
      greyFill: {
        fillColor: "#999999"
      }
    };
  }
}
