var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"form mt-5"},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.title))]),_c('b-row',[(
          _vm.loginState == 'NEEDS_2FA_SETUP' ||
            _vm.loginState == 'NEEDS_2FA_VERIFIED'
        )?_c('b-col',{attrs:{"sm":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[(_vm.loginState == 'NEEDS_2FA_SETUP')?_c('p',{staticClass:"alert alert-success"},[_vm._v(" Please setup your authenticator using the barcode below, and then type in a valid code to complete setting up your two-factor authentication ")]):_vm._e(),_c('div',{staticClass:"text-center"},[_c('div',[_c('InputWithLabel',{attrs:{"cases":false,"focus":"true","id":"auth_code","label":"Authentication code","model":_vm.authCode},on:{"update:model":function($event){_vm.authCode=$event}}}),_c('button',{staticClass:"btn btn-primary mt-2",attrs:{"type":"button"},on:{"click":_vm.tryAuthCode}},[_vm._v(" Send code ")])],1),(_vm.loginState == 'NEEDS_2FA_SETUP')?_c('img',{directives:[{name:"auth-image",rawName:"v-auth-image",value:(_vm.apiLocation + '2fa-setup-image'),expression:"apiLocation + '2fa-setup-image'"}]}):_vm._e()])]):_vm._e(),(
          !(
            _vm.loginState == 'NEEDS_2FA_SETUP' ||
            _vm.loginState == 'NEEDS_2FA_VERIFIED'
          )
        )?_c('b-col',{attrs:{"sm":"12","md":"8","offset-md":"2","lg":"6","offset-lg":"3"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('fieldset',{staticClass:"border p-2"},[_c('legend',{staticClass:"w-auto"},[_vm._v("Login")]),_c('InputWithLabel',{attrs:{"cases":false,"focus":"true","id":"facility_name","label":"Username","model":_vm.username},on:{"update:model":function($event){_vm.username=$event}}}),_c('PasswordWithLabel',{attrs:{"cases":false,"id":"facility_description","label":"Password","model":_vm.password},on:{"update:model":function($event){_vm.password=$event}}})],1),_c('button',{staticClass:"btn btn-primary mt-2",attrs:{"type":"submit"},on:{"click":_vm.tryLogin}},[_vm._v(" Login ")]),(_vm.ssoEnabled)?_c('button',{staticClass:"btn btn-secondary mt-2 ml-2",attrs:{"type":"button"},on:{"click":_vm.doOauth}},[_vm._v(" SSO Login ")]):_vm._e()])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }