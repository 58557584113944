
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ListOfListsToEdit extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  get lists() {
    return this.$store.getters.listOfSelects;
  }
}
