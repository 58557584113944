<template>
  <div>
    <p class="alert alert-warning">You are in the administration section</p>
    <router-view></router-view>
  </div>
</template>

<script type="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class AdminSection extends Vue {}
</script>
