
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  actions,
  CalendarAttendee,
  CalendarEntry,
  Facility,
  PersonForMeeting
} from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { getPeopleFromStore } from "@/store";
import { getEpochFromDateTime } from "@/views/events/index";

@Component({
  components: { InputWithLabel, FileUploadInput }
})
export default class EventForm extends Vue {
  @Prop() eventId?: number | null;

  get fields() {
    return [
      { key: "actions", sortable: false },
      { key: "name", sortable: true },
      { key: "company", sortable: true },
      { key: "email", sortable: true }
    ];
  }

  get people(): PersonForMeeting[] {
    return getPeopleFromStore();
  }

  get facilityChoices() {
    return this.$store.state.facilities.map((facility: Facility) => {
      return {
        value: facility.id,
        text: facility.name
      };
    });
  }

  data() {
    const lclData = {
      sEvent: {
        id: null,
        start: undefined,
        startTime: "08:00:00",
        end: undefined,
        facility: undefined,
        subject: undefined,
        detail: undefined,
        tableName: "calendar"
      } as any,
      attendees: [] as PersonForMeeting[],
      formKey: 0,
      filter: ""
    };
    if (this.$route.params.id) {
      this.$store.state.calendar.forEach((evt: CalendarEntry) => {
        if (evt.id === parseInt(this.$route.params.id)) {
          lclData.sEvent = evt;
          console.log("Event", evt);
          const start = new Date(lclData.sEvent.start);
          lclData.sEvent.startDate =
            start.getFullYear() +
            "-" +
            (start.getMonth() + 1) +
            "-" +
            start.getDate();
          lclData.sEvent.startTime =
            start.getHours() +
            ":" +
            start.getMinutes() +
            ":" +
            start.getSeconds();
          const end = new Date(lclData.sEvent.end);
          lclData.sEvent.endDate =
            end.getFullYear() +
            "-" +
            (end.getMonth() + 1) +
            "-" +
            end.getDate();
          lclData.sEvent.endTime =
            end.getHours() + ":" + end.getMinutes() + ":" + end.getSeconds();

          if (evt.attendees && evt.attendees.length > 0) {
            evt.attendees.forEach((attendee: CalendarAttendee) => {
              getPeopleFromStore().forEach((pfm: PersonForMeeting) => {
                if (
                  (pfm.type === "contact" && attendee.contact === pfm.id) ||
                  (pfm.type === "user" && attendee.user === pfm.id)
                ) {
                  lclData.attendees.push(pfm);
                }
              });
            });
          }
        }
      });
    }
    return lclData;
  }

  isAttending(person: PersonForMeeting): boolean {
    let ret = false;
    this.$data.attendees.forEach((attendee: PersonForMeeting, inx: number) => {
      if (attendee.id === person.id && attendee.type === person.type)
        ret = true;
    });
    return ret;
  }

  removeAttendee(person: PersonForMeeting) {
    this.$data.attendees.forEach((attendee: PersonForMeeting, idx: number) => {
      if (attendee.id === person.id && attendee.type === person.type)
        this.$data.attendees.splice(idx, 1);
    });
  }

  addAttendee(person: PersonForMeeting) {
    this.$data.attendees.push(person);
  }

  deleteEvent() {
    if (confirm("Are you sure you want to delete this event?")) {
      this.$store.dispatch(actions.DELETE_CALENDAR_ENTRY, {
        id: this.$route.params.id,
        success: () => this.$router.push("/events")
      });
    }
  }

  saveEvent() {
    this.$data.sEvent.start = getEpochFromDateTime(
      this.$data.sEvent.startDate,
      this.$data.sEvent.startTime
    );
    this.$data.sEvent.end = getEpochFromDateTime(
      this.$data.sEvent.endDate,
      this.$data.sEvent.endTime
    );

    this.$store.dispatch(actions.CREATE_OR_UPDATE_CALENDAR_ENTRY, {
      toSave: this.$data.sEvent,
      attendees: this.$data.attendees,
      success: () => this.$router.push("/events")
    });
    this.$emit("complete");
  }

  getAttendee(attendee: PersonForMeeting): PersonForMeeting | null {
    let foundPerson = null;
    getPeopleFromStore().forEach((person: PersonForMeeting) => {
      if (attendee.type === person.type && attendee.id === person.id) {
        foundPerson = person;
      }
    });
    if (foundPerson === null) {
      console.error("Cannot find meeting attendee!");
    }
    return foundPerson;
  }
}
