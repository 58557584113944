
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { ColorPicker, ColorPanel } from "one-colorpicker";
import { required } from "vuelidate/lib/validators";

@Component({
  validations: {
    equipmentType: {
      name: { required },
      iconSvg: { required }
    }
  },
  components: { InputWithLabel, ColorPanel }
})
export default class AdminEquipmentTypeForm extends Vue {
  data() {
    try {
      const etId = parseInt(this.$route.params.id);
      for (const et of this.$store.state.equipmentTypes) {
        if (et.id === etId) {
          return {
            equipmentType: { ...et }
          };
        }
      }
      throw "Cannot find equipment type for id " + etId;
    } catch (e) {
      return {
        equipmentType: {
          id: null,
          name: "",
          color: "",
          iconSvg: ""
        }
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }
    this.$store.dispatch(actions.CREATE_OR_UPDATE_EQUIPMENT_TYPE, {
      toSave: this.$data.equipmentType,
      success: () => this.$router.push("/admin/equipment-types")
    });
  }

  readURL() {
    const input = this.$refs.fileInput as HTMLInputElement;
    if (input.files && input.files[0]) {
      const type = input.files[0].type;
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target !== null) {
          console.log("Read", e.target);
          this.$data.equipmentType.iconSvg = atob(`${e.target.result!!}`.split(",")[1]);
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
}
