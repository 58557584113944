;(function () {
    const imgBase64 = {};
    const mimeType = {};
  const vueAuthImage = {};
  const axios = typeof require === 'function'
  ? require('axios')
  : window.Axios;

  if (!axios) {
    throw new Error('[vue-auth-image] cannot locate Axios');
  }

  function setImgSrc(el, binding) {
      if(typeof imgBase64[binding.value] === "string" && imgBase64[binding.value].length > 0){
          // console.log("Got data alread")
          el.src = 'data:' + mimeType[binding.value] + ';base64,' + imgBase64[binding.value];
          return;
      }
      // console.log("Getting data")
      // if(fetching){
      //     return;
      // }
    // if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
      const imageUrl = binding.value;
      // fetching = true;
      axios({
        method: 'get',
        url: imageUrl,
        responseType: 'arraybuffer'
      })
      .then(function(resp) {
        mimeType[binding.value] = resp.headers['content-type'].toLowerCase();
        imgBase64[binding.value] = new Buffer(resp.data, 'binary').toString('base64');
        el.src = 'data:' + mimeType[binding.value] + ';base64,' + imgBase64[binding.value];
        console.log(`Got data from ${imageUrl}`, imgBase64[binding.value])

          // fetching = false;
      }).catch((function() {
        el.src = imageUrl;
          // fetching = false;
      }));
    // }
  }

  vueAuthImage.install = function(Vue) {
    Vue.directive('auth-image', {
      bind: function(el, binding) {
        setImgSrc(el, binding);
      },
      componentUpdated: function(el, binding) {
        setImgSrc(el, binding);
      }
    });
  };

  if (typeof exports == 'object') {
    module.exports = vueAuthImage;
  }
  // } else if (typeof define == 'function' && define.amd) {
  //   define([], function() {
  //     return vueAuthImage;
  //   });
  // } else if (window.Vue) {
  //   window.VueAuthImage = vueAuthImage;
  //   Vue.use(vueAuthImage);
  // }
})();
