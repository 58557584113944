
import { Component, Vue } from "vue-property-decorator";
import AddressDisplay from "../components/AddressDisplay.vue";
import { actions, Contact, Facility } from "@/survey";
import GoogleMap from "@/components/GoogleMap.vue";
import EquipmentForm from "@/views/EquipmentForm.vue";
import EquipmentTable from "@/views/EquipmentTable.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";
import { addLocationToGeoLocatable, getContactById, noop } from "@/store";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import FencingTable from "@/views/FencingTable.vue";
import FencingForm from "@/views/FencingForm.vue";
import GateForm from "@/views/GateForm.vue";
import GateTable from "@/views/GateTable.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import FileUploadMultiple from "@/components/FileUploadMultiple.vue";
import AdminFacilityForm from "@/views/admin/AdminFacilityForm.vue";
import ContactDisplayNameEmailCall from "@/components/ContactDisplayNameEmailCall.vue";
import FacilityEquipmentMap from "@/components/FacilityEquipmentMap.vue";
import PunchFacilityForm from "@/views/PunchFacilityForm.vue";
import PunchItemTable from "@/views/PunchItemTable.vue";
import PunchItemForm from "@/views/PunchItemForm.vue";

@Component({
  components: {
    AdminFacilityForm,
    ImageGallery,
    AddressDisplay,
    DisplayImagesCarousel,
    GoogleMap,
    EquipmentForm,
    EquipmentTable,
    FileUploadInput,
    FencingForm,
    FencingTable,
    GateTable,
    GateForm,
    FileUploadMultiple,
    ContactDisplayNameEmailCall,
    FacilityEquipmentMap,
    PunchFacilityForm,
    PunchItemTable,
    PunchItemForm
  }
})
export default class SurveyFacility extends Vue {
  private mapKey = 1;

  get draggable() {
    return this.$store.getters.canWrite;
  }

  get key() {
    return this.mapKey;
  }

  showMap() {
    this.mapKey++;
  }


  /*get punchFacility(){
    return this.$store.state.punchFacility
  }*/

  getContact(value: number): Contact | undefined {
    return getContactById(value);
  }

  toggleFacilityDetail() {
    this.$data.showFacilityDetail = !this.$data.showFacilityDetail;
  }

  get backTo() {
    return encodeURIComponent(this.$route.fullPath);
  }

  get punchItems() {
    return this.$store.state.punchItems;
  }

  get equipment() {
    return this.$store.state.equipment;
  }

  get fencing() {
    return this.$store.state.fencing;
  }

  get gates() {
    return this.$store.state.gates;
  }

  centerChanged(center: { lat: number; lng: number }) {
    this.facility.lat = center.lat;
    this.facility.lng = center.lng;
    this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY, {
      toSave: this.facility,
      success: noop
    });
  }

  setAddingImage(tf: boolean) {
    this.$data.addingImage = tf;
    this.$data.imageInputKey = this.$data.imageInputKey + 1;
  }

  // get fac() {
  //   const facilityId = parseInt(this.$route.params.id);
  //   for (const facility of this.$store.state.facilities) {
  //     if (facility.id === facilityId) {
  //       return facility;
  //     }
  //   }
  //   return {};
  // }

  get attachmentMeta() {
    const facilityId = parseInt(this.$route.params.id);
    for (const facility of this.$store.state.facilities) {
      if (facility.id === facilityId) {
        return facility.attachmentMeta;
      }
    }
    return [];
  }

  get facility() {
    const facilityId = parseInt(this.$route.params.id);
    for (const facility of this.$store.state.facilities) {
      if (facility.id === facilityId) {
        return facility;
      }
    }
    return {};
  }

  data() {
    return {
      facilityImage: null,
      imageComment: "",
      showFacilityDetail: true,
      addingImage: false,
      imageInputKey: 0
    };
  }

  saveImage() {
    this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY, {
      toSave: this.facility,
      image: this.$data.facilityImage,
      imageComment: this.$data.imageComment,
      success: () => {
        Object.assign(this.$data, { ...this.$data, facilityImage: null });
        this.$data.addingImage = false;
      }
    });
  }

  recordLocation() {
    addLocationToGeoLocatable<Facility>(this.facility)
      .then(toSave =>
        this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY, {
          toSave,
          success: noop
        })
      )
      .catch(positionError => {
        alert(`Error getting position ${JSON.stringify(positionError)}`);
        console.error(positionError);
      });
  }
}
