
import { Component, Prop, Vue } from "vue-property-decorator";
import { AttachmentMeta } from "@/survey";
import VueAuthImage from "vue-auth-image";
import DisplayImageId from "@/components/DisplayImageId.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";

@Component({
  components: { VueAuthImage, DisplayImageId, FileUploadInput }
})
export default class ImageGallery extends Vue {
  @Prop({ default: [] }) private attachmentMeta?: AttachmentMeta[];
  apiLocation = Vue.prototype.$apiLocation;

  get images() {
    if (!Array.isArray(this.attachmentMeta)) {
      return []
    }
    return this.attachmentMeta.filter((m: AttachmentMeta) => {
      if (m === undefined || m.contenttype == undefined) {
        return false;
      }
      return m.contenttype?.indexOf("image") > -1;
    });
  }

  get other(){
    if (!Array.isArray(this.attachmentMeta)) {
      return []
    }
    return this.attachmentMeta.filter((m: AttachmentMeta) => {
      if (m === undefined || m.contenttype == undefined) {
        return false;
      }
      return m !== undefined && m.contenttype?.indexOf("image") === -1;
    })
  }

}
