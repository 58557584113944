
import { Component, Prop, Vue } from "vue-property-decorator";
import BarChart from "@/components/BarChart.vue";
import { Facility, SelectItem } from "@/survey";
import colorArray from "@/components/Colors";

@Component({
  components: {
    BarChart
  }
})
export default class FacilityStatusBar extends Vue {
  @Prop({ default: "200px" }) height?: string;

  get facilitySizeChoices(): { value: string; text: string }[] {
    return this.$store.getters.selectData('facility.size');
  }

  get facilityStatusValues(): SelectItem[]{
    return this.$store.getters.selectData('facility.status')
  }

  get facilities(): Facility[] {
    return this.$store.state.facilities;
  }

  get facilitiesStatusChartData() {
    const dataSizes: any = {};
    const data: any = {};
    this.facilityStatusValues.forEach((fsts: SelectItem) => {
      const status = fsts.value
      data[status] = 0;
      this.facilitySizeChoices.forEach((v: SelectItem) => {
        const size = v.value
        if (dataSizes[size] === undefined) {
          dataSizes[size] = {};
        }
        dataSizes[size][status] = 0;
      });
    });

    this.facilities.forEach(({ status, size }: Facility) => {
      data[status] = data[status] + 1;
      dataSizes[size][status] = dataSizes[size][status] + 1;
    });

    const rdata = {
      labels: this.facilityStatusValues.map((v: SelectItem) => {return v.text}),
      datasets: this.facilitySizeChoices.map((v: SelectItem, idx) => {
        return {
          label: v.text,
          data: this.facilityStatusValues.map((st: SelectItem) => dataSizes[v.value][st.value]),
          backgroundColor: colorArray[idx * 2]
        }
      })
    };
    return rdata;
  }

  get facilitiesStatusChartOptions() {
    return {
      legend: {
        display: true
      },
      scales: {
        xAxes: [{ stacked: true }],
        yAxes: [
          {
            stacked: true,
            ticks: {
              min: 0, // it is for ignoring negative step.
              beginAtZero: true,
              callback: function (value: number) {
                if (Math.floor(value) === value) {
                  return value;
                }
              }
            }
          }
        ]
      },
      responsive: true,
      maintainAspectRatio: false
    };
  }
}
