
import { Component, Prop, Vue } from "vue-property-decorator";
import { AttachmentMeta } from "@/survey";
import VueAuthImage from "vue-auth-image";
import ImageGallery from "@/components/ImageGallery.vue";

@Component({
  components: { VueAuthImage, ImageGallery }
})
export default class DisplayImagesCarousel extends Vue {
  @Prop() private attachmentMeta?: AttachmentMeta[];

  get currentAttachment() {
    const currentfm = this.filteredMeta[this.$data.idx];
    return currentfm;
  }

  incrementIdx() {
    if (this.$data.idx === this.filteredMeta.length - 1) {
      this.$data.idx = 0;
      return;
    }
    this.$data.idx += 1;
  }

  decrementIdx() {
    if (this.$data.idx === 0) {
      this.$data.idx = this.filteredMeta.length - 1;
    } else {
      this.$data.idx -= 1;
    }
  }

  get filteredMeta(){
    return this.attachmentMeta === undefined
        ? []
        : this.attachmentMeta.filter(
            meta =>
                meta.attachmentType === "IMAGE" ||
                meta.attachmentType === "MAIN_IMAGE"
        );
  }
  data() {
    return {
      idx: 0,
    };
  }

  apiLocation = Vue.prototype.$apiLocation;
}
