
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Facility, Gate } from "@/survey";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import FileUploadInput from "@/components/FileUploadInput.vue";
import GateForm from "@/views/GateForm.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import { facilityFormatter } from "@/components/formatters";
import { getFacilityByFencingId } from "@/store";

@Component({
  components: { GateForm, DisplayImagesCarousel, ImageGallery, FileUploadInput }
})
export default class GateTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  @Prop({ default: { name: "N/A" } }) facility?: Facility | null;
  @Prop({ default: [] }) gates?: Gate[];
  @Prop({ default: false }) isReport?: boolean;

  deleteGate(id: number) {
    if (confirm("Confirm delete")) {
      this.$store.dispatch(actions.DELETE_GATE, {
        id: id,
        facilityId: this.$route.params.id
      });
    }
  }

  get backTo() {
    return encodeURIComponent(this.$route.fullPath);
  }

  addImage(gate: Gate, facility: Facility) {
    const payload = {
      image: this.$data.image,
      uploadName: this.$data.image.name,
      tableName: "gate",
      recordId: gate.id,
      success: () =>
        this.$store.dispatch(actions.GET_GATES, { facility: facility.id })
    };
    this.$store.dispatch(actions.UPLOAD_FILE, payload);
  }

  closeModal(id: string) {
    this.$bvModal.hide(id);
  }

  get tableData() {
    return this.$props.gates;
  }

  data() {
    return {
      filter: "",
      image: null,
      mainImage: false,
      component: this
    };
  }

  get fields() {
    const theFields: any[] = [
      { key: "name", sortable: true },
      { key: "surveyTeam", sortable: true },
      { key: "gatetype", sortable: true },
      { key: "optype", sortable: true },
      { key: "acctype", sortable: true },
      { key: "tags", sortable: false }
    ];
    if (this.$props.isReport) {
      theFields.splice(1, 0, {
        label: "Facility",
        key: "fencing",
        sortable: true,
        formatter: facilityFormatter(getFacilityByFencingId)
      });
    }
    return theFields;
  }
}
