
import { Component, Vue } from "vue-property-decorator";
import AdminEditDeleteLinks from "@/components/admin/AdminEditDeleteLinks.vue";
import PdfGenerator from "@/export/pdf";
import {
  getFacilityFieldValuesForExport,
  getFacilityHeaderFields,
  getUserFieldsForExport,
  getUserHeaderFields
} from "@/export/util";
import { generateSpreadsheetSingleTableAndDownload } from "@/export/excel";

@Component({
  components: {
    TableLinks: AdminEditDeleteLinks
  }
})
export default class UserTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get users() {
    return this.$store.state.users;
  }

  get fields() {
    return [
      { key: "firstName", sortable: true },
      { key: "lastName", sortable: true },
      { key: "username", sortable: true },
      { key: "email", sortable: true },
      { key: "phone", sortable: true }
    ];
  }

  getTableComputedItems() {
    const table: any = this.$refs.theTable;
    return table.computedItems;
  }

  exportTableData() {
    new PdfGenerator()
      .getUsersTable(this.getTableComputedItems())
      .then((pdfDoc: any) => {
        pdfDoc.download(`priplan-users.pdf`);
      });
  }

  exportTableDataExcel() {
    const data: string[][] = [getUserHeaderFields()].concat(
      getUserFieldsForExport(this.getTableComputedItems())
    );
    generateSpreadsheetSingleTableAndDownload(data, "priplan-users.xlsx");
  }
}
