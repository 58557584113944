
import { Component, Vue } from "vue-property-decorator";
import InputWithLabel from "@/components/InputWithLabel.vue";
import EquipmentTable from "@/views/EquipmentTable.vue";
import GateTable from "@/views/GateTable.vue";
import FencingTable from "@/views/FencingTable.vue";
import PdfGenerator from "@/export/pdf";
import XlsxPopulate from "xlsx-populate";
import {
  getColumnLengths,
  getEquipmentFieldsForExport,
  getEquipmentHeaderFields,
  getFencingFieldsForExport,
  getFencingHeaderFields,
  getGateFieldsForExport,
  getGateHeaderFields
} from "@/export/util";

@Component({
  components: { InputWithLabel, EquipmentTable, GateTable, FencingTable }
})
export default class ReportDisplay extends Vue {
  get searchResult() {
    return this.$store.state.searchResult;
  }

  pdf() {
    if (
      confirm(
        "Generating a report can take some time. Please click OK to continue and you will be notified when PDF is ready."
      )
    ) {
      const tid = `T_rrpptt_${new Date().getTime()}`;
      this.$bvToast.toast(`Creating PDF of report`, {
        id: tid,
        title: `Report to PDF`,
        variant: "info",
        noAutoHide: true,
        noCloseButton: true,
        appendToast: true
      });
      new PdfGenerator()
        .generateReportReport(
          this.$store.state.facilities,
          this.$store.state.searchResult.equipment,
          this.$store.state.searchResult.fencing,
          this.$store.state.searchResult.gates,
          this.$store.state.contacts,
          this.$store.state.equipmentTypes
        )
        .then((pdfDoc: any) => {
          pdfDoc.download(`priplan-report.pdf`);
        })
        .finally(() => {
          this.$bvToast.hide(tid);
        });
    }
  }

  excel() {
    let records = false;
    XlsxPopulate.fromBlankAsync().then((workbook: any) => {
      if (this.$store.state.searchResult.equipment?.length > 0) {
        records = true;
        const equipmentSheet = workbook.addSheet("Equipment");
        const d = getEquipmentFieldsForExport(
          this.$store.state.searchResult.equipment,
          this.$store.state.equipmentTypes
        );
        const data = [getEquipmentHeaderFields()].concat(d);
        equipmentSheet.cell("A1").value(data);
        getColumnLengths(equipmentSheet.usedRange().value()).forEach(
          (length, col) => {
            equipmentSheet.column(col + 1).width(length + 2);
          }
        );
      }

      if (this.$store.state.searchResult.fencing?.length > 0) {
        records = true;
        const fencingSheet = workbook.addSheet("Fencing");
        const d = getFencingFieldsForExport(
          this.$store.state.searchResult.fencing
        );
        const data = [getFencingHeaderFields()].concat(d);
        fencingSheet.cell("A1").value(data);
        getColumnLengths(fencingSheet.usedRange().value()).forEach(
          (length, col) => {
            fencingSheet.column(col + 1).width(length + 2);
          }
        );
      }
      if (this.$store.state.searchResult.gates?.length > 0) {
        records = true;
        const gateSheet = workbook.addSheet("Gates");
        const d = getGateFieldsForExport(this.$store.state.searchResult.gates);
        const data = [getGateHeaderFields()].concat(d);
        gateSheet.cell("A1").value(data);
        getColumnLengths(gateSheet.usedRange().value()).forEach(
          (length, col) => {
            gateSheet.column(col + 1).width(length + 2);
          }
        );
      }
      if (!records) {
        alert("No records to export");
        return;
      }
      workbook.deleteSheet("Sheet1");
      workbook.outputAsync("base64").then(function(base64: string) {
        const link = document.createElement("a") as HTMLAnchorElement;
        link.id = "dl";
        link.href = "data:" + XlsxPopulate.MIME_TYPE + ";base64," + base64;
        link.download = `report-export.xlsx`;
        link.click();
        link.remove();
      });
    });
  }
}
