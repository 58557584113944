
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, Equipment, EquipmentType, Manufacturer, Tag, User, Vendor } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import TagInput from "@/components/TagInput.vue";
import { macAddress, required } from "vuelidate/lib/validators";
import { addLocationToGeoLocatable, doNotAddLocationToGeoLocatable, getEquipmentType, noop } from "@/store";
import GoogleMap from "@/components/GoogleMap.vue";

@Component({
  components: {
    InputWithLabel,
    FileUploadInput: FileUploadInput,
    TagInput,
    GoogleMap
  },
  validations: {
    equipment: {
      equipmentType: {
        required
      },
      manufacturer: {
        required
      },
      standardizedName: {
        required
      },
      name: {
        required
      },
      macAddress: {
        macAddress: macAddress(':')
      }
    }
  }
})
export default class EquipmentForm extends Vue {
  @Prop() facility?: number;
  @Prop() equipmentId?: number;
  disabledBoolean = false;
  show = false;

  get draggable() {
    return this.$store.getters.canWrite
  }
  get readonly(): boolean {
    if (this.$store.state.authentication.currentUser === null) {
      return true;
    }
    const user = this.$store.state.authentication.currentUser as User;
    return !(user.admin || user.edit);
    //return false;
  }

  disable() {
    this.disabledBoolean = true;
  }

  enable() {
    this.disabledBoolean = false;
  }

  initialData(
      manufacturers: Manufacturer[],
      equipmentList: Equipment[],
      id: number | null,
      formKey = 0
  ) {
    const manufacturerSelectData = manufacturers
        .sort((a, b) => (a.company > b.company ? 1 : -1))
        .map((manufacturer: Manufacturer) => {
          return {
            value: manufacturer.id,
            text: `${manufacturer.company}`
          };
        });
    let initialData: any = null;

    for (const equipment of equipmentList) {
      if (equipment.id === id) {
        const myEquipment = {
          ...equipment,
          installDate: new Date(equipment.installDate),
          batteryInstallDate: new Date(equipment.batteryInstallDate)
        };
        initialData = {
          equipment: myEquipment,
          equipmentImage: null,
          imageComment: "",
          equipmentFile: null,
          fileComment: "",
          manufacturerSelectData,
          formKey,
          useCurrentLocation:
              myEquipment.lat === null ||
              myEquipment.lat === undefined ||
              myEquipment.lng === null ||
              myEquipment.lng === undefined,
          tags: myEquipment.tags!.map((t: Tag) => t.tag)
        };
      }
    }
    if (initialData === null) {
      initialData = {
        equipment: {
          id: null,
          lat: this.$store.state.position.coords.latitude,
          lng: this.$store.state.position.coords.longitude,
          door: 0,
          public: 0,
          exterior: 0,
          doorForced: 0,
          doorHeld: 0,
          doorRex: 0,
          validRead: 0,
          relatedEquipmentA: null,
          relatedEquipmentB: null
        },
        equipmentImage: null,
        imageComment: "",
        equipmentFile: null,
        fileComment: "",
        manufacturerSelectData,
        formKey,
        useCurrentLocation: true,
        tags: []
      };
    }
    //console.log("Initial data", JSON.stringify(initialData, null, 2));
    return initialData;
  }

  get overlay() {
    return this.show;
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save Equipment");
    } else {
      this.saveEquipment();
    }
  }

  data() {
    return this.initialData(
        this.$store.state.manufacturers,
        this.$store.state.equipment,
        this.$props.equipmentId
    );
  }

  centerChanged(center: { lat: number; lng: number }) {
    this.$data.equipment.lat = center.lat;
    this.$data.equipment.lng = center.lng;
    this.$store.dispatch(actions.CREATE_OR_UPDATE_EQUIPMENT, {
      toSave: this.$data.equipment,
      success: noop
    });
  }

  get relatedEquipmentSelectData() {
    const ret = this.$store.state.equipment.map((e: Equipment) => {
      return {
        text: `${getEquipmentType(e.equipmentType).name} - ${e.name}`,
        value: `${e.id}`
      };
    });
    ret.push({ value: null, text: "None" });
    return ret;
  }

  get equipmentTypeOptions() {
    return this.$store.state.equipmentTypes.map((t: EquipmentType) => {
      return {
        text: t.name,
        value: t.id
      };
    });
  }

  get vendorSelectData(){
    return (this.$store.state.vendors as Vendor[])
        .sort((a, b) => (a.name! > b.name! ? 1 : -1))
        .map((vendor) => {
          return {
            value: vendor.id,
            text: `${vendor.name}`
          };
        });
  }
  get yesNoOptions() {
    return [
      { text: "Y", value: 2 },
      { text: "N", value: 3 }
    ];
  }

  get yesNoNAOptions() {
    return [
      { text: "Y", value: 2 },
      { text: "N", value: 3 },
      { text: "N/A", value: 1 }
    ];
  }

  get naFailPassOptions() {
    return [
      { text: "N/A", value: 1 },
      { text: "Fail", value: 3 },
      { text: "Pass", value: 2 }
    ];
  }

  get enabled() {
    return this.disabledBoolean;
  }

  getEquipmentType(t: number): EquipmentType {
    return getEquipmentType(t);
  }

  saveEquipment() {
    this.disable();
    this.$bvToast.toast(`Saving...`, {
      title: "Saving Equipment",
      variant: "info",
      autoHideDelay: 3000,
      appendToast: true
    });
    const initialFunction: (item: Equipment) => Promise<Equipment> = this.$data
        .useCurrentLocation
        ? (item: Equipment) => addLocationToGeoLocatable<Equipment>(item)
        : (item: Equipment) => doNotAddLocationToGeoLocatable<Equipment>(item);
    this.$data.equipment.editedBy = this.$store.state.authentication.currentUser.id;
    initialFunction(this.$data.equipment)
        .then(() => {
          this.$store
              .dispatch(actions.CREATE_OR_UPDATE_EQUIPMENT, {
                toSave: { ...this.$data.equipment, facility: this.$props.facility },
                image: this.$data.equipmentImage,
                imageComment: this.$data.imageComment,
                tags: this.$data.tags,
                file: this.$data.equipmentFile,
                fileComment: this.$data.fileComment,
                toastGenerator: this.$bvToast,
                success: () => {
                  this.$bvToast;
                  this.$store.dispatch(actions.GET_EQUIPMENT, {
                    facility: this.$props.facility
                  });
                  this.$emit("complete");
                  const getData = () =>
                      this.initialData(
                          this.$store.state.manufacturers,
                          this.$store.state.equipment,
                          parseInt(this.$route.params.id),
                          this.$data.formKey + 1
                      );
                  Object.assign(this.$data, getData.apply(this));
                }
              })
              .then(r => {
                console.log(`Done in equipment form 1 ${r}`);
              })
              .catch(e => {
                alert(`Error in dispatch: ${e}`);
              });
        })
        .catch(positionError => alert(`Error ${positionError}`))
        .finally();
  }
}
