
import { Component, Prop, Vue } from "vue-property-decorator";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import TagInput from "@/components/TagInput.vue";
import { actions, mutations } from "@/survey";

@Component({
  components: { InputWithLabel, FileUploadInput, TagInput },
  validations: {}
})
export default class PunchFacilityForm extends Vue {
  // @Prop({ type: String, default: () => "" }) initialNotes!: string;
  @Prop({ type: Boolean, default: () => false }) initialQaComplete!: boolean;

  get notes(){
    return this.$store.state.punchFacility.notes
  }

  set notes(v: string){
    this.$store.commit(mutations.SET_PUNCH_FACILITY_NOTES, v)
  }

  get qaComplete() {
    const facilityId = parseInt(this.$route.params.id);
    let qac = false;
    for (const facility of this.$store.state.facilities) {
      if (facility.id === facilityId) {
        qac = facility.qaCompleted;
      }
    }
    return qac;
  }

  set qaComplete(c: boolean){
    this.$store.commit(mutations.SET_FACILITY_QA_COMPLETE, {
      facility: parseInt(this.$route.params.id),
      qaCompleted: c
    })
  }

  onSubmitPunchListNotes() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      //alert("Cannot save Gate");
    } else {
      this.$bvToast.toast(`Saving...`, {
        title: "Saving Note",
        variant: "info",
        autoHideDelay: 3000,
        appendToast: true
      });
      const facilityId = parseInt(this.$route.params.id);
      for (const facility of this.$store.state.facilities) {
        if (facility.id === facilityId) {
          const toSave = { ...facility };
          toSave.qaCompleted = this.qaComplete;
          this.$store.dispatch(actions.CREATE_OR_UPDATE_FACILITY, {
            toSave,
            success: () => {
              this.$bvToast.toast(`Success`, {
                title: "Facility Saved!",
                variant: "success",
                autoHideDelay: 3000,
                appendToast: true
              });
            },
          });
        }
      }
      this.$store.dispatch(actions.SAVE_PUNCH_FACILITY, {
        notes: this.notes,
        facility: facilityId
      }).then(() => {
        this.$bvToast.toast(`Saved...`, {
          title: "Saved note",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: true
        });
      });
    }
  }
}
