<template>
  <div class="mt-2">
    <div class="mb-2">
      <router-link class="btn btn-success pull-right" to="/event/new"
        >New
        <b-icon icon="plus-circle"></b-icon>
      </router-link>
    </div>

    <VueCal
      class="vuecal--blue-theme"
      :events="events"
      :selected-date="selectedDate"
      :on-event-click="eventClicked"
      @event-drop="onEventDropped"
      :time-from="10 * 60"
      events-count-on-year-view="events-count-on-year-view"
      events-on-month-view="short"
      editable-events
      style="height: 600px"
      :disable-views="['years', 'year']"
      :time-cell-height="60"
      active-view="month"
      :start-week-on-sunday="true"
    >
      <template v-slot:no-event=""></template>
    </VueCal>
  </div>
</template>

<script type="ts">
import Component from "vue-class-component";
import Vue from "vue";
import "vue-cal/dist/vuecal.css";
import VueCal from "vue-cal";
import 'vue-cal/dist/drag-and-drop.es.js';
import { actions } from "@/survey";

@Component({
  components: { VueCal }
})
export default class Calendar extends Vue {
  get selectedDate() {
    return new Date();
  }

  onEventDropped(drop) {
    const dt = drop.newDate;
    const eventId = parseInt(drop.event.eventId);
    let existingEvent = null;
    this.$store.state.calendar.forEach((e) => {
      if (e.id === eventId) {
        existingEvent = { ...e };
      }
    });
    const duration = existingEvent.end - existingEvent.start;
    const newStart = new Date(existingEvent.start);
    newStart.setDate(dt.getDate());
    newStart.setUTCFullYear(dt.getUTCFullYear());
    newStart.setMonth(dt.getMonth());
    const newEnd = new Date(newStart.getTime() + duration);

    existingEvent.start = newStart.getTime();
    existingEvent.end = newEnd.getTime();
    this.$store.dispatch(actions.CREATE_OR_UPDATE_CALENDAR_ENTRY, {
      toSave: existingEvent, success: () => {
        console.log("Saved");
      }
    });
  }

  eventClicked(event, e) {
    this.$router.push(`/event/edit/${event.eventId}`);
  }

  get events() {
    return this.$store.state.calendar.map(event => {
      return {
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.subject,
        class: "bg-success text-white",
        eventId: event.id
      };
    });
  }
}
</script>

<style>
.vuecal {
  margin: auto;
  max-width: 1300px;
  max-height: 662px;
}
</style>
