
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import haversine from "s-haversine";

declare const google: any;
@Component({})
export default class GoogleMap extends Vue {
  @Prop(Number) lat?: number;
  @Prop(Number) lng?: number;
  @Prop(Number) initialZoom?: number;
  private map?: any;
  private marker?: any;
  @Prop(Boolean) private draggable?: boolean;
  @Prop(String) mapType?: string;

  get mapTypeId() {
    return this.mapType ? this.mapType : "satellite";
  }

  get zoom() {
    return this.initialZoom ? this.initialZoom : 15;
  }

  @Watch("lat")
  onPropertyChangedLat(value: string) {
    if (this.map === undefined) {
      this.createMap();
    } else {
      this.map.panTo({ lat: value, lng: this.$props.lng });
      this.marker.setPosition({ lat: value, lng: this.$props.lng });
    }
  }

  @Watch("lng")
  onPropertyChanged(value: string) {
    if (this.map === undefined) {
      this.createMap();
    } else {
      this.map.panTo({ lng: value, lat: this.$props.lat });
      this.marker.setPosition({ lng: value, lat: this.$props.lat });
    }
  }

  createMap() {
    if (this.$props.lat && this.$props.lng && this.map === undefined) {
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: this.$props.lat, lng: this.$props.lng },
        zoom: this.zoom,
        mapTypeId: this.mapTypeId
      });
      this.marker = new google.maps.Marker({
        position: { lat: this.$props.lat, lng: this.$props.lng },
        animation: google.maps.Animation.DROP,
        draggable: this.draggable,
        map: this.map
      });

      if (this.draggable) {
        this.marker.addListener("dragend", (event: any) => {
          const newLl = [event.latLng.lat(), event.latLng.lng()] as [
            number,
            number
          ];
          const oldLl = [this.$props.lat, this.$props.lng] as [number, number];
          const distanceMeters = haversine.distance(newLl, oldLl);
          const lat = event.latLng.lat();
          const lng = event.latLng.lng();
          if (
            confirm(
              `Do you want to update this address to new lat/lng (${lat}, ${lng})?\nMoved ${Math.round(
                distanceMeters
              )} Meters`
            )
          ) {
            const center = { lat, lng };
            this.map.setCenter(center);
            this.$emit("center", center);
          } else {
            this.marker.setPosition({
              lat: this.$props.lat,
              lng: this.$props.lng
            });
          }
        });
      }
      //console.log(this.map);
    }
  }

  mounted() {
    this.createMap();
  }

  beforeDestroy() {
    //console.log("Destory", this.map);
  }
}
