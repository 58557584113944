
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class InputWithLabel extends Vue {
  @Prop() private id?: string;
  @Prop() private label?: string;
  @Prop() private model?: any;
  @Prop() private focus?: boolean;
  @Prop({ default: "text" }) private type?: boolean;
  @Prop({ default: null }) private state?: boolean | null = null;
  @Prop() private error?: string;
  @Prop() private title?: string;
  @Prop({ default: false }) private readonly!: boolean;
  @Prop({ default: true }) cases!: boolean;

  change() {
    // TODO - first char of every word UC ONLY
    if (this.cases) {
      let data = `${this.$data.lclModel}`;
      if (data.length !== 0) {
        // console.log(`Data: ${data}`);
        data = data
            .split(" ")
            .map(d => d[0] !== undefined ? `${d[0].toUpperCase()}${d.substr(1)}` : '')
            .join(" ");
        this.$data.lclModel = `${data}`;
      }
    }
    this.$emit("update:model", `${this.$data.lclModel}`);
  }

  get errorValue() {
    if (this.$props.error === undefined) {
      return "Invalid value";
    } else {
      return this.$props.error;
    }
  }

  data() {
    return {
      lclModel: this.model
    };
  }
}
