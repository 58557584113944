<template>
  <div style="height: 100vh;">
    <router-view></router-view>
  </div>
</template>

<script type="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class FullPageChart extends Vue {
}
</script>
