
import { Component, Vue } from "vue-property-decorator";
import { dateFormatter } from "../../components/formatters";
import PdfGenerator from "@/export/pdf";
import { getContactFieldsForExport, getContactHeaderFields, getUserActivityFieldsForExport } from "@/export/util";
import { generateSpreadsheetSingleTableAndDownload } from "@/export/excel";

@Component({
  methods: { dateFormatter },
  components: {}
})
export default class UserActivity extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  client = Vue.prototype.$surveyClient;
  activity: null | any[] = null;

  get fields() {
    return [
      { key: "user", sortable: true },
      { key: "action", sortable: true },
      { key: "objectType", sortable: true },
      { key: "happenedTimestamp", sortable: true, label: "Date" }
    ];
  }

  get formattedStart() {
    if(this.$data.start === null){
      return "Beginning";
    }
    return dateFormatter("MM/DD/YYYY")(this.$data.start);
  }

  get formattedEnd() {
    return dateFormatter("MM/DD/YYYY")(this.$data.end);
  }
  data() {
    const d = { filter: "", start: this.defaultStart(), end: this.defaultEnd() };
    return d;
  }

  defaultStart() {
    return new Date(2023, 0, 1)
  }

  defaultEnd() {
    return new Date();
  }
  get username() {
    return this.$route.params.username;
  }

  async created() {
    // const userActivity = await this.client.getAllUserActivity(
    //   this.defaultStart().getTime(),
    //   this.defaultEnd().getTime()
    // );
    // this.activity = userActivity;
    await this.refresh();
  }

  async refresh() {
    const userActivity = await this.client.getAllUserActivity(
      this.$data.start.getTime(),
      this.$data.end.getTime()
    );
    this.activity = userActivity;
  }

  exportTableData() {
    const table: any = this.$refs.theTable;
    new PdfGenerator()
      .getUserActivityTable(table.computedItems)
      .then((pdfDoc: any) => {
        pdfDoc.download(`user-activity.pdf`);
      });
  }

  exportTableDataExcel() {
    const table: any = this.$refs.theTable;
    const data: string[][] = [['User', 'Action', 'Object Type', 'Date']].concat(
      getUserActivityFieldsForExport(table.computedItems)
    );
    generateSpreadsheetSingleTableAndDownload(data, "priplan-activity.xlsx");
  }
}
