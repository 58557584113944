
import { Component, Prop, Vue } from "vue-property-decorator";
import { Contact } from "@/survey";

@Component({})
export default class ContactDisplayNameEmailCall extends Vue {
  @Prop() contact!: Contact;
  @Prop({ default: "" }) subject!: string;
  @Prop({ default: "" }) emailBody!: string;
}
