export const getEpochFromDateTime = (dt: string, tm: string): number => {
  const [yr, mon, day] = dt.split("-"); // yyyy-mm-dd
  const [hr, min, sec] = tm.split(":"); // hh:mm:ss
  return new Date(
    parseInt(yr),
    parseInt(mon) - 1,
    parseInt(day),
    parseInt(hr),
    parseInt(min),
    parseInt(sec)
  ).getTime();
};

// Returns [yr, mon, day, hr, min, sec]
export const parseDroppedDate = (droppedDateString: string): number[] => {
  const [dateString, timeStringWithMS] = droppedDateString.split("T");
  const timeStringHMS = timeStringWithMS.split(".")[0];
  const [y, m, d] = dateString.split("-");
  const [h, min, s] = timeStringHMS.split(":");
  return [y, m, d, h, min, s].map(x => parseInt(x));
};
