
import { Component, Prop, Vue } from "vue-property-decorator";
import PieChart from "../../components/PieChart.vue";
import { Facility, SelectItem } from "@/survey";
import colorArray from "@/components/Colors";

@Component({
  components: {
    PieChart
  }
})
export default class FacilitySizePie extends Vue {
  @Prop({ default: "200px" }) height?: string;

  get facilities() {
    return this.$store.state.facilities;
  }

  get facilitySizeValues(){
    return this.$store.getters.selectData('facility.size')
  }

  get facilitiesSizePieData() {
    const data: any = {};

    const labels: string[] = [];

    this.facilitySizeValues.forEach((size: SelectItem) => {
      data[size.value] = 0;
      labels.push(size.text);
    });

    this.facilities.forEach(({ size }: Facility) => {
      data[size] = data[size] + 1;
    });

    return {
      labels,
      datasets: [
        {
          data: this.facilitySizeValues.map((st: SelectItem) => data[st.value]),
          backgroundColor: colorArray.slice(7, 7 + this.facilitySizeValues.length)
        }
      ]
    };
  }
  get facilitiesSizePieOptions() {
    return {
      legend: {
        display: true
      },
      responsive: true,
      maintainAspectRatio: false
    };
  }
}
