
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class StatusBar extends Vue {
  get missedPings() {
    return this.$store.state.missedPings;
  }

  get text(){
    return this.missedPings >= 3 ? "DB Offline" : "Protective Resources, Inc."
  }
  get version() {
    return this.$store.state.version;
  }
}
