
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";

@Component({
  components: {}
})
export default class ListEditor extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  get fields() {
    return [
      { key: "label", sortable: true },
      { key: "value", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get list() {
    return this.$store.getters.listData(this.listId);
  }

  get listId() {
    return this.$route.params.key;
  }

  del(id: string) {
    if(this.list.length < 2){
      alert('You cannot delete the last list item...');
      return;
    }
    this.$store.dispatch(actions.DELETE_SELECT_CONFIG, {id})
  }
}
