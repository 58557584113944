
import { Component, Prop, Vue } from "vue-property-decorator";
import { AttachmentType } from "@/survey";

const initialData = () => {
  return {
    previewReady: false,
    isImage: false
  };
};

@Component({})
export default class FileUploadInput extends Vue {
  @Prop() private id?: string;
  @Prop() private table?: string;
  @Prop() private attachmentType?: AttachmentType;
  @Prop() private mainImage?: boolean;
  @Prop() private imageComment?: string;
  @Prop({ default: false }) private disabled?: boolean;
  @Prop({ default: true }) private allowComment?: boolean;
  apiLocation = Vue.prototype.$apiLocation;

  emitCommentChange() {
    this.$emit("update:imageComment", this.$data.theComment);
  }

  emitMainImageChange() {
    this.$emit("update:mainImage", !this.$data.isMainImage);
  }

  get isMainImage() {
    return this.mainImage;
  }

  // get theComment() {
  //   return this.$props.imageComment;
  // }

  data() {
    return {
      ...initialData(),
      theComment: this.$props.imageComment
    };
  }

  // mounted(){
  //   const preview = this.$refs.preview as HTMLImageElement;
  //   preview.src = "http://placehold.it/90";
  //   alert("ps: " + preview.src)
  //   this.$data.isImage = false;
  //   this.$data.previewReady = false;
  // }

  readURL() {
    const input = this.$refs.fileInput as HTMLInputElement;
    if (input.files && input.files[0]) {
      //console.log("File", input.files[0]);
      const type = input.files[0].type;
      this.$emit("update:file", input.files[0]);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const preview = this.$refs.preview as HTMLImageElement;
        if (e.target !== null) {
          if (typeof e.target.result === "string") {
            if (type.indexOf("image") > -1) {
              preview.src = e.target.result;
              this.$data.isImage = true;
              this.$data.previewReady = true;
            } else {
              preview.src = "http://placehold.it/90";
              this.$data.isImage = false;
              this.$data.previewReady = false;
            }
          }
        }
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
}
