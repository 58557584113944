

import { Component, Vue } from "vue-property-decorator";
import FacilityMap from "@/components/FacilityMap.vue";

@Component({
  components: { FacilityMap }
})
export default class MyFacilityMap extends Vue {
  get facilities(){
    return this.$store.state.facilities;
  }
}
