
import { Component, Prop, Vue } from "vue-property-decorator";
import PieChart from "../../components/PieChart.vue";
import { Pair } from "../../survey";
import colorArray from "../../components/Colors";

@Component({
  components: {
    PieChart
  }
})
export default class TagUsagePie extends Vue {
  @Prop({ default: "200px" }) height?: string;

  get facilities() {
    return this.$store.state.facilities;
  }
  get tagUsagePieData() {
    const data: any = {};
    const labels: string[] = [];
    this.$store.state.tagUsage.forEach((pair: Pair<string, number>) => {
      data[pair.first] = pair.second;
      labels.push(pair.first);
    });

    //console.log("Data", data);
    return {
      labels,
      datasets: [
        {
          data: labels.map((key: string) => data[key]),
          backgroundColor: colorArray.slice(0, labels.length)
        }
      ]
    };
  }
  get facilitiesSizePieOptions() {
    return {
      legend: {
        display: true
      },
      responsive: true,
      maintainAspectRatio: false
    };
  }
}
