<script>
import { Doughnut } from "vue-chartjs";

export default {
  props: ["pieChartData", "pieChartOptions"],
  extends: Doughnut,
  mounted() {
    this.renderChart(this.pieChartData, this.pieChartOptions);
  }
};
</script>

<style></style>
