
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput }
})
export default class AdminContactTypeForm extends Vue {
  data() {
    try {
      const contactTypeId = parseInt(this.$route.params.id);
      for (const contactType of this.$store.state.contactTypes) {
        if (contactType.id === contactTypeId) {
          return {
            contactType: { ...contactType }
          };
        }
      }
      throw "Cannot find contact type for id " + contactTypeId;
    } catch (e) {
      return {
        contactType: {
          id: null,
          type: "",
          notes: ""
        }
      };
    }
  }

  saveContactType() {
    this.$store.dispatch(actions.CREATE_OR_UPDATE_CONTACT_TYPE, {
      toSave: this.$data.contactType,
      success: () => this.$router.push("/admin/contact-types")
    });
  }
}
