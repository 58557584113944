
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, AttachmentMeta } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";

@Component({
  components: { FileUploadInput }
})
export default class DisplayImageId extends Vue {
  @Prop() private meta?: AttachmentMeta;
  apiLocation = Vue.prototype.$apiLocation;

  get imageWidth() {
    return this.$data.editingComment ? 360 : 180;
  }

  data() {
    return {
      image: null,
      editingComment: false,
      replacingImage: false,
      rotatingImage: false,
      lclComments: this.$props.meta.notes,
      rotation: 0,
      imageKey: 0
    };
  }

  showImage(){
    console.log("Show: " + this.$refs.image)
  }

  toggleReplacing() {
    this.$data.replacingImage = !this.$data.replacingImage;
  }

  toggleEdit() {
    this.$data.editingComment = !this.$data.editingComment;
  }

  deleteImage() {
    if (confirm("This will delete the image, are you sure?")) {
      this.$store.dispatch(actions.DELETE_IMAGE, { id: this.$props.meta.id });
    }
  }

  saveComment() {
    this.$store.dispatch(actions.ADD_COMMENT, {
      table: "attach",
      id: this.$props.meta.id,
      comment: this.$data.lclComments,
      recordTable: this.$props.meta.tablename
    });
    this.toggleEdit();
  }

  getExtension() {
    return this.$props.meta.contenttype.split("/")[1];
  }

  isImage() {
    return this.getContentType() === "image";
  }

  getContentType() {
    return this.$props.meta.contenttype.split("/")[0];
  }

  replaceImage() {
    const meta: AttachmentMeta = this.$props.meta;

    this.$store.dispatch(actions.UPLOAD_FILE, {
      image: this.$data.image,
      imageComment: meta.notes,
      uploadName: this.$data.image.name,
      tableName: meta.tablename,
      recordId: meta.recordid,
      id: meta.id,
      // rotation: this.$data.rotation,
      success: () => {
        this.toggleReplacing();
      },
      attachmentType: meta.attachmentType
    });
  }

  downloadImage() {
    const image = this.$refs.image as HTMLImageElement;
    const url = image.src.replace(
        /^data:[a-zA-Z]+\/[^;]+/,
        "data:application/octet-stream"
    );
    const link = document.createElement("a") as HTMLAnchorElement;
    link.id = "dl";
    link.href = url;
    link.download = `${this.$props.meta.name}`;
    link.click();
    link.remove();
  }

  rotateImage() {
    this.$data.rotatingImage = true;
    this.$data.rotation = this.$data.rotation + 90;
    (this.$refs.image as any).style.transform = `rotate(${this.$data.rotation}deg)`;
    this.$store.dispatch(actions.ROTATE_IMAGE, {
      tableName: this.meta!!.tablename, imageId: this.meta!!.id, rotation: this.$data.rotation
    }).then(() => {
      this.$data.imageKey = new Date().getTime();
      this.$data.rotatingImage = false;
    }).catch(() => this.$data.rotatingImage = false);
  }
}
