
import { Component, Vue } from "vue-property-decorator";
import { actions, Tag, Vendor } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { required } from "vuelidate/lib/validators";
import store from "@/store";

function uniqueIfNew(value: string, record: Vendor) {
  if (record.id != null) {
    return true;
  }
  const values = store.state.vendors.map((t: Vendor) => t.name);
  const v = values.includes(value);
  console.log(`${v} - ${value} is in ${values.join(", ")}`);
  return !v;
}

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput },
  validations: {
    vendor: {
      name: { required, uniqueIfNew }
    }
  }
})
export default class AdminVendorForm extends Vue {
  data() {
    try {
      const vendorId = parseInt(this.$route.params.id);
      for (const vendor of this.$store.state.vendors) {
        if (vendor.id === vendorId) {
          return {
            vendor: { ...vendor }
          };
        }
      }
      throw "Cannot find vendor for id " + vendorId;
    } catch (e) {
      return {
        vendor: {
          id: null,
          name: ""
        }
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }

    this.$store.dispatch(actions.CREATE_OR_UPDATE_VENDOR, {
      toSave: this.$data.vendor,
      success: () => this.$router.push("/admin/vendors")
    });
  }

  onCancel() {
    this.$router.push("/admin/vendors");
  }
}
