<script>
import { Bar } from "vue-chartjs";

export default {
  props: ["barChartData", "barChartOptions"],
  extends: Bar,
  mounted() {
    this.renderChart(this.barChartData, this.barChartOptions);
  }
};
</script>

<style></style>
