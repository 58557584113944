
import { Component, Vue } from "vue-property-decorator";
import AddressDisplay from "@/components/AddressDisplay.vue";
import GoogleMap from "@/components/GoogleMap.vue";
import AdminEditDeleteLinks from "@/components/admin/AdminEditDeleteLinks.vue";
import DisplayImagesCarousel from "@/components/DisplayImagesCarousel.vue";
import { Contact, Facility, User } from "@/survey";
import { getContactById, getUserByUsername } from "@/store";
import ContactDisplayNameEmailCall from "@/components/ContactDisplayNameEmailCall.vue";
import PdfGenerator from "@/export/pdf";
import XlsxPopulate from "xlsx-populate";
import {
  getColumnLengths,
  getFacilityFieldValuesForExport,
  getFacilityHeaderFields
} from "@/export/util";
import { generateSpreadsheetSingleTableAndDownload } from "@/export/excel";

@Component({
  components: {
    AddressDisplay,
    GoogleMap,
    TableLinks: AdminEditDeleteLinks,
    DisplayImagesCarousel,
    ContactDisplayNameEmailCall
  }
})
export default class FacilityTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  getTableComputedItems() {
    const table: any = this.$refs.theTable;
    return table.computedItems;
  }

  exportTableDataExcel() {
    const data: string[][] = [getFacilityHeaderFields()].concat(
      getFacilityFieldValuesForExport(
        this.getTableComputedItems(),
        this.$store.state.contacts,
        false
      )
    );
    generateSpreadsheetSingleTableAndDownload(data, "priplan-facilities.xlsx");
  }

  exportTableDataPDF() {
    new PdfGenerator()
      .getFacilityTable(
        this.getTableComputedItems(),
        this.$store.state.contacts,
        this.$store.state.users
      )
      .then((pdfDoc: any) => {
        pdfDoc.download(`priplan-facilities.pdf`);
      });
  }

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "name", sortable: true },
      { key: "aka", sortable: true, label: "AKA" },
      { key: "facilityCode", sortable: true, label: "Code" },
      { key: "surveyor", sortable: true, label: "Surveyor" },
      { key: "surveyTeam", sortable: true, label: "Survey team" },
      { key: "description", sortable: false },
      {
        key: "fullContact",
        label: "Contact",
        sortable: true,
        filterByFormatted: true,
        formatter: (value: Contact | null) => {
          return value?.firstName === undefined || value?.firstName === null
            ? `Unknown`
            : `${value?.firstName} ${value?.lastName}`;
        }
        // thClass: 'd-none', tdClass: 'd-none'
      },
      { key: "status", sortable: true },
      { key: "priority", sortable: true },
      { key: "size", sortable: true }
    ];
  }

  get users() {
    return this.$store.state.users.map((u: User) => {
      return { ...u };
    });
  }

  get facilities() {
    return this.$store.state.facilities.map((f: Facility) => {
      return {
        ...f,
        fullContact: f.contact ? getContactById(f.contact) : {},
        fullSurveyor: f.surveyor ? getUserByUsername(f.surveyor) : {}
      };
    });
  }
}
