import axios, { AxiosInstance, AxiosResponse } from "axios";

import {
  Attachment,
  AttachmentType,
  CalendarAttendee,
  CalendarEntry,
  Contact,
  ContactType,
  Credentials, DBInfo,
  Equipment,
  EquipmentType,
  Facility,
  Fencing,
  Gate,
  LocalStorageKeys,
  Manufacturer, OauthSettings,
  Pair,
  PasswordResetDTO,
  PingResponse,
  PunchFacility,
  PunchItem,
  SearchData,
  SearchResult,
  Tag,
  User,
  Vendor,
  SelectConfig, FacilityProfile, DBEntity
} from "@/survey";
import { requestInterceptor, responseError, responseInterceptor } from "@/client/interceptor";

export default class SurveyClient {
  private client: AxiosInstance;
  private readonly baseURL: string;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    // This is the initial client, once we get a good login
    // we replace this with one that has BASIC auth enabled
    const k = localStorage.getItem(LocalStorageKeys.TOKEN_LOCAL_STORAGE_KEY);
    if (k) {
      this.client = axios.create({
        baseURL: this.baseURL,
        timeout: 20000,
        headers: { Authorization: "Bearer " + k }
      });
      axios.defaults.headers.common["Authorization"] = "Bearer " + k;
    } else {
      this.client = axios.create({
        baseURL: this.baseURL,
        timeout: 20000
      });
    }
    this.client.interceptors.request.use(requestInterceptor);
    this.client.interceptors.response.use(responseInterceptor, responseError);
  }

  changePassword(passwordResetDTO: PasswordResetDTO) {
    return new Promise<User>((resolve, reject) => {
      this.client
        .post<PasswordResetDTO, AxiosResponse<User>>(
          "/user/p",
          passwordResetDTO
        )
        .then((r: any) => resolve(r.data))
        .catch((e: any) => reject(`Error with password reset: ${e}`));
    });
  }

  logout() {
    localStorage.removeItem(LocalStorageKeys.TOKEN_LOCAL_STORAGE_KEY);
    this.client = axios.create({
      baseURL: this.baseURL,
      timeout: 20000
    });
    this.client.interceptors.request.use(requestInterceptor);
    this.client.interceptors.response.use(responseInterceptor, responseError);
    delete axios.defaults.headers.common["Authorization"];
  }

  ping(): Promise<PingResponse> {
    return new Promise<PingResponse>((resolve, reject) => {
      this.client
        .get<null, AxiosResponse<PingResponse>>("/")
        .then((r: AxiosResponse<PingResponse>) => resolve(r.data))
        .catch((e: any) => reject(`Error with ping: ${e}`));
    });
  }
  getConfig(): Promise<OauthSettings> {
    return new Promise<OauthSettings>((resolve, reject) => {
      this.client
        .get<null, AxiosResponse<OauthSettings>>("/")
        .then((r: AxiosResponse<OauthSettings>) => resolve(r.data))
        .catch((e: any) => reject(`Error with get config: ${e}`));
    });
  }

  _getObjectFromEndpoint<T>(
    endpoint: string,
    params: any = undefined
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.client
        .get<null, AxiosResponse<T>>(endpoint, { params })
        .then((response: AxiosResponse<T>) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
  }

  _getListOfObjectsFromEndpoint<T>(
    endpoint: string,
    params: any = undefined
  ): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      this.client
        .get<null, AxiosResponse<T[]>>(endpoint, { params })
        .then((response: AxiosResponse<T[]>) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
  }

  _deleteGeneric<T>(endpoint: string): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.client
        .request<T, AxiosResponse<T>>({
          url: `${endpoint}`,
          method: "DELETE"
        })
        .then((response: AxiosResponse<T>) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
  }

  _createOrUpdateGeneric<T extends DBEntity>(
    toSave: T,
    endpoint: string
  ): Promise<T> {
    const method = toSave.id === null ? "put" : "post";
    return new Promise<T>((resolve, reject) => {
      this.client
        .request<T, AxiosResponse<T>>({
          url: `${endpoint}`,
          data: toSave,
          method
        })
        .then((response: AxiosResponse<T>) => resolve(response.data))
        .catch((error: any) => reject(error));
    });
  }

  search(criteria: SearchData): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.client
        .get<any, AxiosResponse<SearchResult>>("/search", {
          params: criteria,
          paramsSerializer: (params: any) => {
            //return qs.stringify(params);
            return `json=${encodeURIComponent(JSON.stringify(criteria))}`;
          }
        })
        .then((response: AxiosResponse<SearchResult>) => {
          resolve(response.data);
        })
        .catch((error: any) => reject(error));
    });
  }

  tryGetMe(): Promise<User> {
    return new Promise<User>((resolve, reject) => {
      this.client
        .get<any, AxiosResponse<User>>("/user/me")
        .then((response: AxiosResponse<User>) => {
          resolve(response.data);
        })
        .catch((error: any) => reject(error));
    });
  }

  send2FaToken(code: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.client
        .request<any, AxiosResponse<any>>({
          method: "POST",
          url: "/verify-code",
          headers: {
            "Content-Type": "text/plain"
          },
          data: code
        })
        .then(r => {
          this.client
            .get<any, AxiosResponse<string>>("/login-state")
            .then((response: AxiosResponse<string>) => {
              // console.log(`Login state`, response)
              resolve(response.data);
            })
            .catch(e => {
              alert(`Error getting login-state ${e}`);
              reject(e);
            });
        })
        .catch(e => reject(e));
    });
  }

  tryOauthLogin(ssoToken: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.client.request<any, AxiosResponse<string>>({
        method: "GET",
        url: `/oauth2/${ssoToken.code}`
      })
        .then((tokenResponse: AxiosResponse<string>) => {
          // console.log("TR", tokenResponse)
          this.client = axios.create({
            baseURL: this.baseURL,
            timeout: 20000,
            headers: { Authorization: "Bearer " + tokenResponse.data }
          });
          this.client.interceptors.request.use(requestInterceptor);
          this.client.interceptors.response.use(
            responseInterceptor,
            responseError
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + tokenResponse.data;
          localStorage.setItem(
            LocalStorageKeys.TOKEN_LOCAL_STORAGE_KEY,
            tokenResponse.data
          );
          resolve("LOGGED_IN");
        }).catch((e: any) => {
        reject(e);
      });
    });
  }

  tryLogin(credentials: Credentials): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const auth = {
        username: credentials.username,
        password: credentials.password
      };
      this.client
        .request<any, AxiosResponse<string>>({
          method: "POST",
          url: "/login",
          data: auth
        })
        .then((tokenResponse: AxiosResponse<string>) => {
          this.client = axios.create({
            baseURL: this.baseURL,
            timeout: 20000,
            headers: { Authorization: "Bearer " + tokenResponse.data }
          });
          this.client.interceptors.request.use(requestInterceptor);
          this.client.interceptors.response.use(
            responseInterceptor,
            responseError
          );
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + tokenResponse.data;
          localStorage.setItem(
            LocalStorageKeys.TOKEN_LOCAL_STORAGE_KEY,
            tokenResponse.data
          );
          this.client
            .get<any, AxiosResponse<string>>("/login-state")
            .then((response: AxiosResponse<string>) => {
              // console.log(`Login state`, response)
              resolve(response.data);
            })
            .catch(e => {
              alert(`Error getting login-state ${e}`);
              reject(e);
            });
        })
        .catch((e: any) => {
          reject(e);
        });
    });
  }

  uploadAttachment(data: {
    file: any;
    uploadName: string;
    tableName: string;
    recordId: string;
    attachmentType: AttachmentType;
    notes: string;
    id?: number;
    rotation?: number;
    uploadProgressCallback?: (progress: number) => void;
  }) {
    return new Promise<number>((accept, reject) => {
      const formData = new FormData();
      formData.append("file", data.file);
      formData.append("upload_name", data.uploadName);
      formData.append("table_name", data.tableName);
      formData.append("record_id", "" + data.recordId);
      formData.append("attachment_type", "" + data.attachmentType);
      formData.append("notes", "" + data.notes);
      data.rotation !== undefined ? formData.append("rotation", `${data.rotation}`) : undefined;

      const config = {
        onUploadProgress: function (progressEvent: ProgressEvent) {
          if (data.uploadProgressCallback != undefined) {
            data.uploadProgressCallback(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          } else {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            console.log(percentCompleted);
          }
        },
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      let promise = null;
      if (data.id !== undefined) {
        formData.append("id", `${data.id}`);
        promise = this.client.post("/attach", formData, config);
      } else {
        promise = this.client.put("/attach", formData, config);
      }
      return promise
        .then((result: any) => {
          accept(result.data.id);
        })
        .catch(e => {
          alert(`Error uploading attachment [client]: ${e}`);
          reject(e);
        });
    });
  }

  deleteVendor(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/vendor/${id}`);
  }

  deleteFacility(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/facility/${id}`);
  }

  deleteEquipment(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/equipment/${id}`);
  }

  deleteEquipmentType(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/equipment-type/${id}`);
  }

  deleteContactType(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/contact-type/${id}`);
  }

  deleteContact(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/contact/${id}`);
  }

  deletePunchItem(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/punch-item/${id}`);
  }

  deleteFencing(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/fencing/${id}`);
  }

  deleteGate(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/gate/${id}`);
  }

  deleteAttachment(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/attach/${id}`);
  }

  deleteTag(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/tag/${id}`);
  }

  deletePunchTag(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/punch-tag/${id}`);
  }

  deleteUser(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/user/${id}`);
  }

  deleteSelectConfig(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/select-config/${id}`);
  }

  deleteCalendarEntry(id: number): Promise<string> {
    return this._deleteGeneric<string>(`/calendar/${id}`);
  }

  deleteCalendarAttendeesFor(id: number): Promise<number> {
    return this._deleteGeneric<number>("/calendar/attendee/" + id);
  }

  createOrUpdateVendor(
    entryToSave: Vendor
  ): Promise<Vendor> {
    return this._createOrUpdateGeneric<Vendor>(
      entryToSave,
      "/vendor"
    );
  }

  createOrUpdateSelectConfig(
    entryToSave: SelectConfig
  ): Promise<SelectConfig> {
    return this._createOrUpdateGeneric<SelectConfig>(
      entryToSave,
      "/select-config"
    );
  }

  createOrUpdatePunchFacility(
    entryToSave: PunchFacility
  ): Promise<PunchFacility> {
    return this._createOrUpdateGeneric<PunchFacility>(
      entryToSave,
      "/punch-facility"
    );
  }

  createOrUpdateCalendarAttendee(
    entryToSave: CalendarAttendee
  ): Promise<CalendarAttendee> {
    return this._createOrUpdateGeneric<CalendarAttendee>(
      entryToSave,
      "/calendar/attendee"
    );
  }

  createOrUpdateCalendarEntry(
    entryToSave: CalendarEntry
  ): Promise<CalendarEntry> {
    return this._createOrUpdateGeneric<CalendarEntry>(entryToSave, "/calendar");
  }

  createOrUpdateUser(userToSave: User): Promise<User> {
    return this._createOrUpdateGeneric<User>(userToSave, "/user");
  }

  createOrUpdateFacility(facilityToSave: Facility): Promise<Facility> {
    return this._createOrUpdateGeneric<Facility>(facilityToSave, "/facility");
  }

  createOrUpdateContact(contactToSave: Contact): Promise<Contact> {
    return this._createOrUpdateGeneric<Contact>(contactToSave, "/contact");
  }

  createOrUpdateTag(tagToSave: Tag): Promise<Tag> {
    return this._createOrUpdateGeneric<Tag>(tagToSave, "/tag");
  }

  createOrUpdatePunchTag(tagToSave: Tag): Promise<Tag> {
    return this._createOrUpdateGeneric<Tag>(tagToSave, "/punch-tag");
  }

  createOrUpdateManufacturer(
    manufacturerToSave: Manufacturer
  ): Promise<Manufacturer> {
    return this._createOrUpdateGeneric<Manufacturer>(
      manufacturerToSave,
      "/manufacturer"
    );
  }

  createOrUpdateEquipment(equipmentToSave: Equipment): Promise<Equipment> {
    return this._createOrUpdateGeneric<Equipment>(
      equipmentToSave,
      "/equipment"
    );
  }

  createOrUpdateEquipmentType(toSave: EquipmentType): Promise<EquipmentType> {
    return this._createOrUpdateGeneric<EquipmentType>(
      toSave,
      "/equipment-type"
    );
  }

  createOrUpdateContactType(toSave: ContactType): Promise<ContactType> {
    return this._createOrUpdateGeneric<ContactType>(
      toSave,
      "/contact-type"
    );
  }

  createOrUpdateFencing(fencingToSave: Fencing): Promise<Fencing> {
    return this._createOrUpdateGeneric<Fencing>(fencingToSave, "/fencing");
  }

  createOrUpdatePunchItem(piToSave: PunchItem): Promise<PunchItem> {
    return this._createOrUpdateGeneric<PunchItem>(piToSave, "/punch-item");
  }

  createOrUpdateGate(gateToSave: Gate): Promise<Gate> {
    return this._createOrUpdateGeneric<Gate>(gateToSave, "/gate");
  }

  deleteFacilitiyProfile(id: number) {
    return this._deleteGeneric<FacilityProfile>(`/facility-profile/${id}`)
  }

  createOrUpdateFacilityProfile(fp: FacilityProfile): Promise<FacilityProfile> {
    return this._createOrUpdateGeneric<FacilityProfile>(fp, '/facility-profile')
  }

  getFacilityProfiles(): Promise<FacilityProfile[]> {
    return this._getListOfObjectsFromEndpoint<FacilityProfile>('/facility-profile')
  }
  // getAttachments(tableName: string, id: number): Promise<Attachment[]> {
  //   return this._getListOfObjectsFromEndpoint<Attachment>("/attach/" + tableName + "/" + id);
  // }

  getFacility(facilityId: number): Promise<Facility> {
    return this._getObjectFromEndpoint<Facility>(`/facility/${facilityId}`);
  }

  getFacilities(): Promise<Facility[]> {
    return this._getListOfObjectsFromEndpoint<Facility>("/facility");
  }

  getHoursValues(): Promise<string[]> {
    return this._getListOfObjectsFromEndpoint<string>("/facility/hours-values");
  }

  getUsers(): Promise<User[]> {
    return this._getListOfObjectsFromEndpoint<User>("/user");
  }

  getVendors(): Promise<Vendor[]> {
    return this._getListOfObjectsFromEndpoint<User>("/vendor");
  }

  getContactTypes(): Promise<ContactType[]> {
    return this._getListOfObjectsFromEndpoint<ContactType>("/contact-type");
  }

  getContacts(): Promise<Contact[]> {
    return this._getListOfObjectsFromEndpoint<Contact>("/contact");
  }

  getTags(): Promise<Tag[]> {
    return this._getListOfObjectsFromEndpoint<Tag>('/tag');
  }

  getDbInfo(): Promise<DBInfo[]> {
    return this._getListOfObjectsFromEndpoint<DBInfo>('/dbinfo');
  }

  getUserActivity(username: string): Promise<any[]> {
    return this._getListOfObjectsFromEndpoint<any>('/user-log/' + username);
  }

  getAllUserActivity(start: number, end: number): Promise<any[]> {
    return this._getListOfObjectsFromEndpoint<any>(`/user-log?start=${start}&end=${end}`);
  }
  
  saveAdminConfig(adminConfig: {}): Promise<{}> {
    return new Promise<{}>((accept, reject) => {
      this.client.post("/admin-config", adminConfig).then(
        (response: AxiosResponse<{}>) => accept(response.data)
      ).catch(e => reject("Error saving config: " + e));
    });
  }

  getSelectConfig(): Promise<SelectConfig[]> {
    return this._getListOfObjectsFromEndpoint<SelectConfig>("/select-config")
  }

  getAdminConfig(): Promise<{}> {
    return this._getListOfObjectsFromEndpoint<{}>("/admin-config");
  }

  getPunchTags(): Promise<Tag[]> {
    return this._getListOfObjectsFromEndpoint<Tag>("/punch-tag");
  }

  getManufacturers(): Promise<Manufacturer[]> {
    return this._getListOfObjectsFromEndpoint<Manufacturer>("/manufacturer");
  }

  getEquipmentTypes(): Promise<EquipmentType[]> {
    return this._getListOfObjectsFromEndpoint<EquipmentType>("/equipment-type");
  }

  getEquipment(params: any = undefined): Promise<Equipment[]> {
    return this._getListOfObjectsFromEndpoint<Equipment>("/equipment", params);
  }

  getPunchFacility(params: { facility: number }): Promise<PunchFacility[]> {
    return this._getListOfObjectsFromEndpoint<PunchFacility>(
      "/punch-facility",
      params
    );
  }

  getPunchItems(params: { facility: number }): Promise<PunchItem[]> {
    return this._getListOfObjectsFromEndpoint<PunchItem>("/punch-item", params);
  }

  getFencing(params: any = undefined): Promise<Fencing[]> {
    return this._getListOfObjectsFromEndpoint<Fencing>("/fencing", params);
  }

  getGates(params: any = undefined): Promise<Gate[]> {
    return this._getListOfObjectsFromEndpoint<Gate>("/gate", params);
  }

  getCalendarEntries(): Promise<CalendarEntry[]> {
    return this._getListOfObjectsFromEndpoint<CalendarEntry>("/calendar");
  }

  getCalendarEntriesForUser(userId: number): Promise<CalendarEntry[]> {
    return this._getListOfObjectsFromEndpoint<CalendarEntry>("/calendar/find", {
      user: userId
    });
  }

  getCalendarEntryAttendees(
    calendarEntryId: number
  ): Promise<CalendarAttendee[]> {
    return this._getListOfObjectsFromEndpoint<CalendarAttendee>(
      `/calendar/attendee/${calendarEntryId}`
    );
  }

  updateItemLocation(
    lat: number,
    lng: number,
    recordId: number,
    tableName: "facility" | "equipment" | "gate" | "fencing"
  ) {
    return new Promise<boolean>((resolve, reject) => {
      this.client
        .post<any, any>("/location/update", { lat, lng, recordId, tableName })
        .then(() => {
          resolve(true);
        })
        .catch((err: any) => reject(err));
    });
  }

  addComment(table: "attach", id: number, comment: string, recordTable: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.client
        .post<Comment, Attachment>(`/${table}/comment?table=${recordTable}`, { id, comment })
        .then(() => {
          resolve(true);
        })
        .catch((err: any) => reject(err));
    });
  }

  getTagUsage(): Promise<Pair<string, number>[]> {
    return this._getListOfObjectsFromEndpoint<Pair<string, number>>(
      "/tag/tag-usage"
    );
  }

  rotate(tableName: string, imageId: number, rotation: number) {
    return new Promise((resolve, reject) => {
      this.client
        .request({
          url: `/attach/rotate`,
          data: { tableName, imageId, rotation },
          method: "POST"
        })
        .then(() => resolve("OK"))
        .catch(reject);
    });
  }

  clearAndAddTags(
    tableName: string,
    recordId: number,
    tags: string[]
  ): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      this.client
        .request({
          url: `/tag/clear-add`,
          data: { tableName, recordId, tags },
          method: "POST"
        })
        .then(() => resolve("OK"))
        .catch(reject);
    });
  }

  clearAndAddPunchTags(
    tableName: string,
    recordId: number,
    tags: string[]
  ): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      this.client
        .request({
          url: `/punch-tag/clear-add`,
          data: { tableName, recordId, tags },
          method: "POST"
        })
        .then(() => resolve("OK"))
        .catch(reject);
    });
  }
}
