
import { Component, Prop, Vue } from "vue-property-decorator";
import { actions, PunchItem, Tag } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import PunchTagInput from "@/components/PunchTagInput.vue";
import { equipmentForSelect } from "@/store";

@Component({
  components: { InputWithLabel, FileUploadInput, PunchTagInput }
  // validations: {
  //   punchItem: {
  //     name: { required }
  //   }
  // }
})
export default class PunchItemForm extends Vue {
  @Prop() facility?: number;
  @Prop() punchItemId?: number | null;
  @Prop() item: PunchItem | undefined;

  disabledBoolean = false;

  disable() {
    this.disabledBoolean = true;
  }

  enable() {
    this.disabledBoolean = false;
  }

  data() {
    const vals: any = {
      equipmentForSelect: equipmentForSelect(),
      punchItemImage: null,
      imageComment: "",
      tags: [],
      punchItem: {
        id: null,
        facility: this.facility,
        surveyor: this.$store.state.authentication.currentUser.username
      }
    };
    if (this.item !== undefined) {
      vals.tags = this.item.tags!.map((t: Tag) => t.tag);
      vals.punchItem = this.item;
      vals.punchItem.isCompleted = typeof this.item.completed === "number";
    }
    return vals;
  }

  onSubmit() {
    // this.$v.$touch();
    // if (this.$v.$invalid) {
    //   alert("Cannot save Punch Item");
    // } else {
    this.savePI();
    // }
  }

  savePI() {
    // const initialFunction: (item: PunchItem) => Promise<PunchItem> = this.$data
    //     .useCurrentLocation
    //     ? (item: punchItem) => addLocationToGeoLocatable<punchItem>(item)
    //     : (item: punchItem) => doNotAddLocationToGeoLocatable<punchItem>(item);
    this.disable();
    this.$bvToast.toast(`Saving...`, {
      title: "Saving Punch Item",
      variant: "info",
      autoHideDelay: 3000,
      appendToast: true
    });
    // initialFunction(this.$data.punchItem)
    //     .then((punchItem: punchItem) => {
    if (this.$data.punchItem.isCompleted) {
      this.$data.punchItem.completed = new Date();
    }
    if (typeof this.$data.punchItem.started !== "number") {
      this.$data.punchItem.started = new Date();
    }
    this.$store.dispatch(actions.CREATE_OR_UPDATE_PUNCH_ITEM, {
      toSave: { ...this.$data.punchItem, facility: this.$props.facility },
      tags: this.$data.tags,
      image: this.$data.punchItemImage,
      imageComment: this.$data.imageComment,
      success: () => {
        this.$bvToast.toast(`Success`, {
          title: "Punch Item Saved!",
          variant: "success",
          autoHideDelay: 3000,
          appendToast: true
        });
        this.$store.dispatch(actions.GET_PUNCH_ITEMS, {
          facility: this.$props.facility
        });
        this.$emit("complete");
        // const getData = () =>
        //     initialData(
        //         this.$store.state.manufacturers,
        //         this.$store.state.punchItem,
        //         parseInt(this.$route.params.id),
        //         this.$data.formKey + 1
        //     );
        // Object.assign(this.$data, getData.apply(this));
      }
    });
    // })
    // .catch(positionError => alert(`Error: ${positionError}`))
  }
}
