
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class VendorTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "name", sortable: true },
      {key: "actions"}
    ];
  }

  get vendors() {
    return this.$store.state.vendors;
  }
}
