
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";

@Component({
  components: {}
})
export default class FacilityProfileTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  get fields() {
    return [
      { key: "name", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get facilityProfiles() {
    return this.$store.state.facilityProfiles;
  }

  del(id: number){
    if(confirm('Are you sure you want to delete the facility profile?')){
      this.$store.dispatch(actions.DELETE_FACILITY_PROFILE, {id: id})
    }
  }
}
