
import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class ErrorDisplay extends Vue {
  get hasErrors() {
    return this.$store.state.errors.length;
  }

  get errors() {
    return this.$store.state.errors;
  }
}
