import Vue from "vue";
import Vuex from "vuex";
import {
  actions,
  Attachable,
  AttachableStateFields,
  AttachmentMeta,
  AttachmentType,
  CalendarEntry,
  Contact,
  ContactType,
  Equipment,
  EquipmentType,
  Facility, FacilityProfile,
  Fencing,
  Gate,
  GeoLocatable,
  LoginAttempt,
  Manufacturer,
  mutations, OauthSettings,
  Pair,
  PasswordResetDTO,
  PersonForMeeting,
  PunchFacility,
  PunchItem,
  SearchData,
  SearchResult, SelectConfig, SelectItem,
  SuccessMethod,
  SurveyState,
  Tag,
  User, Vendor
} from "@/survey";
import SurveyClient from "@/client";
import { BvToast } from "bootstrap-vue";

Vue.use(Vuex);
export const noop = () => {
  console.log("NOOP");
};

function addScript(src: string) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script');

    s.setAttribute('src', src);
    s.addEventListener('load', resolve);
    s.addEventListener('error', reject);

    document.body.appendChild(s);
  });
}

const browserUrl = window.location.href;
const [protocol, portAndUrl] = browserUrl.split("://");
const [domainAndPort] = portAndUrl.split("/");
let port = "80";
let domain = "";
if (domainAndPort.indexOf(":") > -1) {
  [domain, port] = domainAndPort.split(":");
}

export function doNotAddLocationToGeoLocatable<T extends GeoLocatable>(
  item: T
): Promise<T> {
  return new Promise<T>(resolve => resolve(item));
}

export function addLocationToGeoLocatable<T extends GeoLocatable>(
  item: T
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (success: Position) => {
        const lat = success.coords.latitude;
        const lng = success.coords.longitude;
        item.lat = lat;
        item.lng = lng;
        resolve(item);
      },
      positionError => {
        {
          item.lat = 0;
          item.lng = 0;
          resolve(item);
        }
        ;
      }
    );
  });
}

export const apiLocation =
  process.env.NODE_ENV === "production"
    ? `${protocol}://${domainAndPort}/api/`
    : "http://localhost:8081/";
export const surveyClient: SurveyClient = new SurveyClient(apiLocation);
Vue.prototype.$apiLocation = apiLocation;
Vue.prototype.$surveyClient = surveyClient;

const _saveImageAndTags = (
  tableName: string,
  image: any,
  tags: string[],
  success: () => void,
  imageComment = "",
  equipmentFile?: any,
  equipmentFileComment = "",
  toastGenerator: BvToast | null = null,
  punchTags: string[] = []
) => {
  return (result: any) => {
    {
      const promisesToWaitFor: Promise<any>[] = [];

      if (image !== undefined && image !== null) {
        promisesToWaitFor.push(
          surveyClient
            .uploadAttachment({
              file: image,
              uploadName: "" + result.name,
              tableName: tableName,
              recordId: "" + result.id,
              attachmentType: AttachmentType.MAIN_IMAGE,
              notes: imageComment,
              uploadProgressCallback: x => {
                if (toastGenerator != null) {
                  toastGenerator.toast(`Success`, {
                    title: `Image Upload Progress  ${x}%`,
                    variant: "success",
                    autoHideDelay: 1000,
                    appendToast: true
                  });
                }
              }
            })
            .then(() => {
              console.log("Done image uplaod");
            })
            .catch(e => alert(`Image upload error ${e}`))
        );
      }
      if (equipmentFile !== undefined && equipmentFile !== null) {
        promisesToWaitFor.push(
          surveyClient.uploadAttachment({
            file: equipmentFile,
            uploadName: "" + result.name,
            tableName: tableName,
            recordId: "" + result.id,
            attachmentType: AttachmentType.OTHER,
            notes: equipmentFileComment
          })
        );
      }
      if (tags != null && tags.length > 0) {
        const stringPromise: Promise<string> = surveyClient.clearAndAddTags(
          tableName,
          result.id!,
          tags
        );
        promisesToWaitFor.push(stringPromise);
      }
      if (punchTags != null && punchTags.length > 0) {
        const anotherStringPromise: Promise<string> = surveyClient.clearAndAddPunchTags(
          tableName,
          result.id!,
          punchTags
        );
        promisesToWaitFor.push(anotherStringPromise);
      }
      Promise.all(promisesToWaitFor)
        .then(() => {
          console.log(`Done waiting for ${promisesToWaitFor.length} promises`);
        })
        .catch(e => {
          alert(`Error saving: ${e}`);
        })
        .finally(() => success());
    }
  };
};

const initialState = {
  selectConfig: [],
  oauth: {
    redirectUri: process.env.VUE_APP_OAUTH_REDIRECT_URI,
    clientId: process.env.VUE_APP_OAUTH_CLIENT_ID,
    server: process.env.VUE_APP_OAUTH_SERVER,
    enabled: (process.env.VUE_APP_OAUTH_ENABLED === 'true')
  },
  loading: 0,
  facilityProfiles: [],
  equipment: [],
  allFencing: [],
  fencing: [],
  gates: [],
  facilities: [],
  punchFacility: {
    notes: "",
    qaComplete: false
  },
  punchItems: [],
  contacts: [],
  users: [],
  errors: [],
  tags: [],
  vendors: [],
  punchTags: [],
  manufacturers: [],
  equipmentTypes: [],
  tagUsage: [],
  calendar: [],
  contactTypes: [],
  searchResult: {},
  version: "1.0.dev",
  missedPings: 1,
  position: {
    timestamp: -1,
    coords: {
      latitude: -1,
      longitude: -1,
      accuracy: -1,
      altitude: -1,
      altitudeAccuracy: -1,
      heading: -1,
      speed: -1
    }
  },
  settings: {
    passwordLength: 8
  },
  authentication: {
    token: null,
    lastValid: null,
    currentUser: null,
    calendarEntries: [],
    loginState: "LOGGED_OUT"
  }
};
// console.log("State oauth", initialState.oauth)
// console.log("ENV", process.env)
const store = new Vuex.Store<SurveyState>({
  strict: process.env.NODE_ENV !== "production",
  state: JSON.parse(JSON.stringify(initialState)),
  mutations: {
    async [mutations.GOT_CONFIG](
      state: SurveyState,
      payload: OauthSettings
    ) {
      state.oauth = { ...payload };
      document.title = payload['web.title'];
      await addScript(`https://maps.googleapis.com/maps/api/js?key=${payload['google.client_maps_api_key']}&libraries=&v=weekly`);
    },
    [mutations.GOT_SEARCH_DATA](
      state: SurveyState,
      payload: { data: SearchResult }
    ) {
      state.searchResult = payload.data;
    },
    [mutations.DELETE_IMAGE](state: SurveyState, payload: { id: number }) {
      const valueArrays = [
        "gates",
        "fencing",
        "equipment",
        "facilities"
      ] as AttachableStateFields[];
      // This is state.facilities, for example
      valueArrays.forEach(fieldName => {
        const itemArray = state[fieldName] as Attachable[];
        // This is for each state.facilities as item, for example
        itemArray.forEach((item: Attachable, itemIdx: number) => {
          // This is for each state.facility.attachmentMeta
          if (item !== undefined && item.attachmentMeta !== undefined) {
            item.attachmentMeta.forEach(
              (meta: AttachmentMeta, metaIdx: number) => {
                if (meta.id === payload.id) {
                  //alert(`Need to remove image from ${fieldName} array position ${metaIdx}`)
                  const newArray = ([] as AttachmentMeta[]).concat(
                    item.attachmentMeta!
                  );
                  newArray.splice(metaIdx, 1);
                  state[fieldName][itemIdx].attachmentMeta = newArray;
                }
              }
            );
          }
        });
      });
    },
    [mutations.GOT_PUNCH_FACILITY](state: SurveyState, payload: PunchFacility) {
      state.punchFacility = payload;
    },
    [mutations.SET_FACILITY_QA_COMPLETE](state: SurveyState, payload: { facility: number, qaCompleted: boolean }) {
      for (const facility of state.facilities) {
        if (facility.id === payload.facility) {
          facility.qaCompleted = payload.qaCompleted;
        }
      }
    },
    [mutations.SET_PUNCH_FACILITY_NOTES](state: SurveyState, payload: string) {
      state.punchFacility.notes = payload;
    },
    [mutations.GOT_FACILITY_PROFILES](state: SurveyState, payload: FacilityProfile[]) {
      state.facilityProfiles = payload;
    },
    [mutations.GOT_SELECT_CONFIG](state: SurveyState, payload: SelectConfig[]) {
      state.selectConfig = payload;
    },
    [mutations.GOT_PUNCH_ITEMS](state: SurveyState, payload: PunchItem[]) {
      state.punchItems = payload;
    },
    [mutations.UPDATE_ATTACHMENT_NOTES](
      state: SurveyState,
      payload: {
        id: number;
        notes: string;
      }
    ) {
      let found = false;
      const recs = (state.gates as Attachable[])
        .concat(state.fencing as Attachable[])
        .concat(state.equipment as Attachable[])
        .concat(state.facilities as Attachable[]);
      recs.forEach((item: Attachable) => {
        if (item.attachmentMeta) {
          item.attachmentMeta.forEach((meta: AttachmentMeta) => {
            if (meta.id === payload.id) {
              meta.notes = payload.notes;
              found = true;
            }
          });
        }
      });
    },
    [mutations.LOGOUT](state: SurveyState) {
      Object.assign(state, JSON.parse(JSON.stringify(initialState)));
      state.authentication = {
        token: null,
        lastValid: null,
        currentUser: null,
        calendarEntries: [],
        loginState: "LOGGED_OUT"
      };
    },
    [mutations.LOADING](state: SurveyState) {
      state.loading++;
    },
    [mutations.LOADING_DONE](state: SurveyState) {
      state.loading--;
    },
    [mutations.GOT_ALL_CALENDAR_ENTRIES](
      state: SurveyState,
      payload: { entries: CalendarEntry[] }
    ) {
      state.calendar = payload.entries;
    },
    [mutations.GOT_POSITION](
      state: SurveyState,
      payload: { position: Position }
    ) {
      state.position = payload.position;
    },
    [mutations.GOT_TAG_USAGE](
      state: SurveyState,
      payload: { tagUsage: Pair<string, number>[] }
    ) {
      state.tagUsage = payload.tagUsage;
    },
    [mutations.PING_SUCCESS](
      state: SurveyState,
      payload: { "survey.password_length": number }
    ) {
      state.missedPings = 0;
      state.settings.passwordLength = payload['survey.password_length'];
    },
    [mutations.PING_MISS](state: SurveyState) {
      state.missedPings = state.missedPings + 1;
    },
    [mutations.CLEAR_ERRORS](state: SurveyState) {
      state.errors = [];
    },
    [mutations.ADD_ERROR](state: SurveyState, payload: { errors: string[] }) {
      state.errors = state.errors.concat(payload.errors);
    },
    [mutations.GOT_USERS](state: SurveyState, payload: { users: User[] }) {
      state.users = payload.users;
    },
    [mutations.GOT_FACILITIES](
      state: SurveyState,
      payload: { facilities: Facility[] }
    ) {
      state.facilities = payload.facilities;
    },
    [mutations.GOT_EQUIPMENT_TYPES](
      state: SurveyState,
      payload: { types: EquipmentType[] }
    ) {
      state.equipmentTypes = payload.types;
    },
    [mutations.GOT_FACILITY](
      state: SurveyState,
      payload: { facility: Facility }
    ) {
      let toReplace = -1;
      state.facilities.forEach((f, i) => {
        if (f.id === payload.facility.id) {
          toReplace = i;
        }
      });
      if (toReplace === -1) {
        console.error(
          "Cannot find facility to replace!",
          JSON.stringify(payload.facility)
        );
        return;
      }
      const newArr = state.facilities.concat([]);
      newArr.splice(toReplace, 1, payload.facility);
      state.facilities = newArr;
    },
    [mutations.GOT_CONTACT_TYPES](
      state: SurveyState,
      payload: { contactTypes: ContactType[] }
    ) {
      state.contactTypes = payload.contactTypes;
    },
    [mutations.GOT_CONTACTS](
      state: SurveyState,
      payload: { contacts: Contact[] }
    ) {
      state.contacts = payload.contacts;
    },
    [mutations.GOT_PUNCH_TAGS](state: SurveyState, payload: { tags: Tag[] }) {
      state.punchTags = payload.tags;
    },
    [mutations.GOT_TAGS](state: SurveyState, payload: { tags: Tag[] }) {
      state.tags = payload.tags;
    },
    [mutations.GOT_EQUIPMENT](
      state: SurveyState,
      payload: { equipment: Equipment[] }
    ) {
      state.equipment = payload.equipment;
    },
    [mutations.GOT_FENCING](
      state: SurveyState,
      payload: { fencing: Fencing[] }
    ) {
      state.fencing = payload.fencing;
    },
    [mutations.GOT_ALL_FENCING](
      state: SurveyState,
      payload: { fencing: Fencing[] }
    ) {
      state.allFencing = payload.fencing;
    },
    [mutations.GOT_GATES](state: SurveyState, payload: { gates: Gate[] }) {
      state.gates = payload.gates;
    },
    [mutations.GOT_VENDORS](state: SurveyState, payload: { vendors: Vendor[] }) {
      state.vendors = payload.vendors;
    },
    [mutations.GOT_MANUFACTURERS](
      state: SurveyState,
      payload: { manufacturers: Manufacturer[] }
    ) {
      state.manufacturers = payload.manufacturers;
    },
    [mutations.GOT_USER_CALENDAR_ENTRIES](
      state: SurveyState,
      payload: { calendarEntries: CalendarEntry[] }
    ) {
      state.authentication.calendarEntries = payload.calendarEntries;
    },
    [mutations.GOT_LOGIN_STATE](
      state: SurveyState,
      payload: { state: string }
    ) {
      state.authentication.loginState = payload.state;
    },
    [mutations.LOGIN_SUCCESS](
      state: SurveyState,
      // payload: { user: User; password: string }
      payload: { user: User }
    ) {
      state.authentication.currentUser = payload.user;
    },
    [mutations.UPDATED_LOCATION](
      state: SurveyState,
      payload: {
        lat: number;
        lng: number;
        recordId: number;
        tableName: "facility" | "equipment" | "gate" | "fencing";
      }
    ) {
      let positionToReplace = -1;
      let equipmentToReplace: Equipment | null = null;
      if (payload.tableName === "equipment") {
        state.equipment.forEach((equipment, idx) => {
          if (equipment.id === payload.recordId) {
            equipmentToReplace = { ...equipment };
            positionToReplace = idx;
          }
        });
        const newArr = state.equipment.concat([]);
        equipmentToReplace!.lat = payload.lat;
        equipmentToReplace!.lng = payload.lng;
        newArr.splice(positionToReplace, 1, equipmentToReplace!);
        state.equipment = newArr;
      }
    }
  },
  actions: {
    [actions.GET_FACILITY_PROFILES](context) {
      return surveyClient.getFacilityProfiles().then((c: FacilityProfile[]) => context.commit(mutations.GOT_FACILITY_PROFILES, c));
    },
    [actions.GET_SELECT_CONFIG](context) {
      return surveyClient.getSelectConfig().then((c: SelectConfig[]) => context.commit(mutations.GOT_SELECT_CONFIG, c));
    },
    [actions.GET_CONFIG](context) {
      return surveyClient.getConfig().then((c: OauthSettings) => context.commit(mutations.GOT_CONFIG, c));
    },
    [actions.CHANGE_PASSWORD](context, payload: PasswordResetDTO) {
      return surveyClient
        .changePassword(payload)
        .then(() => {
            context.dispatch(actions.TRY_GET_ME, { success: () => alert("Password changed") });
          }
        )
        .catch(() => alert("Could not change password"));
    },
    [actions.DELETE_SELECT_CONFIG](context, payload: { id: number }) {
      surveyClient.deleteSelectConfig(payload.id).then(() => {
        context.dispatch(actions.GET_SELECT_CONFIG);
      });
    },
    [actions.DELETE_FACILITY_PROFILE](context, payload: { id: number }) {
      surveyClient.deleteFacilitiyProfile(payload.id).then(() => {
        context.dispatch(actions.GET_FACILITY_PROFILES);
      });
    },
    [actions.DELETE_IMAGE](context, payload: { id: number }) {
      surveyClient.deleteAttachment(payload.id).then(() => {
        context.commit(mutations.DELETE_IMAGE, payload);
      });
    },
    [actions.DELETE_TAG](context, payload: { id: number }) {
      surveyClient.deleteTag(payload.id).then(() => {
        context.dispatch(
          actions.REFRESH_USER_DATA,
          context.state.authentication.currentUser!.id
        );
      });
    },
    [actions.DELETE_PUNCH_TAG](context, payload: { id: number }) {
      surveyClient.deletePunchTag(payload.id).then(() => {
        context.dispatch(
          actions.GET_PUNCH_TAGS
        );
      });
    },
    [actions.DELETE_VENDOR](context, payload: { id: number }) {
      surveyClient.deleteVendor(payload.id).then(() => {
        context.dispatch(
          actions.GET_VENDORS
        );
      });
    },
    [actions.DELETE_FACILITY](context, payload: { id: number }) {
      surveyClient.deleteFacility(payload.id).then(() => {
        context.dispatch(
          actions.GET_FACILITIES
        );
      });
    },
    [actions.DELETE_CALENDAR_ENTRY](
      context,
      payload: { success: () => void; id: number }
    ) {
      surveyClient.deleteCalendarEntry(payload.id).then(() => {
        context
          .dispatch(
            actions.REFRESH_USER_DATA,
            context.state.authentication.currentUser!.id
          )
          .then(payload.success);
      });
    },
    [actions.DELETE_GATE](
      context,
      payload: { id: number; facilityId: number }
    ) {
      surveyClient.deleteGate(payload.id).then(() => {
        context.dispatch(actions.GET_GATES, { facility: payload.facilityId });
      });
    },
    [actions.DELETE_EQUIPMENT](
      context,
      payload: { id: number; facilityId: number }
    ) {
      surveyClient.deleteEquipment(payload.id).then(() => {
        context.dispatch(actions.GET_EQUIPMENT, {
          facility: payload.facilityId
        });
      });
    },
    [actions.DELETE_FENCING](
      context,
      payload: { id: number; facilityId: number }
    ) {
      surveyClient
        .deleteFencing(payload.id)
        .then(() => {
          context.dispatch(actions.GET_FENCING, {
            facility: payload.facilityId
          });
        })
        .catch(e =>
          alert(
            "Cannot delete, make sure you do not have any gates for this fencing"
          )
        );
    },
    [actions.DELETE_USER](context, payload: { id: number }) {
      surveyClient.deleteUser(payload.id).then(() => {
        context.dispatch(
          actions.REFRESH_USER_DATA,
          context.state.authentication.currentUser!.id
        );
      });
    },
    [actions.DELETE_EQUIPMENT_TYPE](context, payload: { id: number }) {
      surveyClient.deleteEquipmentType(payload.id).then(() => {
        context.dispatch(
          actions.REFRESH_USER_DATA,
          context.state.authentication.currentUser!.id
        );
      });
    },
    [actions.LOGOUT](context) {
      surveyClient.logout();
      context.commit(mutations.LOGOUT);
      context.dispatch(actions.GET_CONFIG);
    },
    [actions.POSITION](context) {
      navigator.geolocation.getCurrentPosition(
        (position: Position) => {
          const pos = {
            coords: {
              accuracy: position.coords.accuracy,
              altitude: position.coords.altitude,
              altitudeAccuracy: position.coords.altitudeAccuracy,
              heading: position.coords.heading,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              speed: position.coords.speed
            },
            timestamp: position.timestamp
          };
          context.commit(mutations.GOT_POSITION, { position });
          console.log("Got position", pos);
        },
        (error: any) => {
          console.log("Error getting position", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    },
    [actions.PING](context) {
      return surveyClient
        .ping()
        .then(result => {
          context.commit(mutations.PING_SUCCESS, result);
          console.log("Ping result", result);
        })
        .catch(e => {
          context.commit(mutations.PING_MISS);
          console.warn("Ping bad result: " + e);
        });
    },
    [actions.CLEAR_ERRORS](context) {
      context.commit(mutations.CLEAR_ERRORS);
    },
    [actions.ADD_ERROR](context, payload: { errors: string[] }) {
      context.commit(mutations.ADD_ERROR, payload);
    },
    [actions.REFRESH_USER_DATA](context, userId: string) {
      context.commit(mutations.LOADING);
      if (context.state.authentication.currentUser != null && context.state.authentication.currentUser.admin) {
        context.dispatch(actions.GET_FACILITY_PROFILES);
      }
      return context.dispatch(actions.GET_FACILITIES)
        .then(() => context.dispatch(actions.GET_EQUIPMENT_TYPES))
        .then(() => context.dispatch(actions.GET_SELECT_CONFIG))
        .then(() => context.dispatch(actions.GET_USERS))
        .then(() => context.dispatch(actions.GET_VENDORS))
        .then(() => context.dispatch(actions.GET_CONTACT_TYPES))
        .then(() => context.dispatch(actions.GET_CONTACTS))
        .then(() => context.dispatch(actions.GET_TAGS))
        .then(() => context.dispatch(actions.GET_PUNCH_TAGS))
        .then(() => context.dispatch(actions.GET_MANUFACTURERS))
        .then(() => context.dispatch(actions.GET_TAG_USAGE))
        .then(() => context.dispatch(actions.GET_ALL_CALENDAR_ENTRIES))
        .then(() =>
          context.dispatch(actions.GET_USER_CALENDAR_ENTRIES, {
            userId: userId
          }).then(() => context.commit(mutations.LOADING_DONE))
        );
    },
    [actions.GOT_LOGGED_IN_USER](context, payload: { user: User }) {
      return context.dispatch(actions.REFRESH_USER_DATA, payload.user.id);
    },
    [actions.TRY_GET_ME](context, payload: { success: () => void }) {
      context.dispatch(mutations.CLEAR_ERRORS).then(() => console.log("noop"));
      return surveyClient
        .tryGetMe()
        .then(user => {
          context.commit(mutations.LOGIN_SUCCESS, {
            user
          });
          context
            .dispatch(actions.GOT_LOGGED_IN_USER, { user })
            .then(payload.success);
        })
        .catch(() => {
          context.commit(mutations.LOGIN_SUCCESS, { user: null });
        });
    },
    [actions.TRY_2FA](context, payload: { code: string; success: () => void }) {
      context.dispatch(mutations.CLEAR_ERRORS).then(() => console.log("noop"));
      return surveyClient
        .send2FaToken(payload.code)
        .then(loginState => {
          context.commit(mutations.GOT_LOGIN_STATE, { state: loginState });
          if (loginState === "LOGGED_IN") {
            surveyClient.tryGetMe().then(user => {
              context.commit(mutations.LOGIN_SUCCESS, {
                user
              });
              context
                .dispatch(actions.GOT_LOGGED_IN_USER, { user })
                .then(() => payload.success());
            });
          }
        })
        .catch(e => {
          context.commit(mutations.ADD_ERROR, { errors: ["Code error"] });
        });
    },
    [actions.TRY_OAUTH_LOGIN](context, payload: any) {
      context.dispatch(mutations.CLEAR_ERRORS).then(() => console.log("noop"));
      return surveyClient
        .tryOauthLogin(payload)
        .then(loginState => {
          context.commit(mutations.GOT_LOGIN_STATE, { state: loginState });
          if (loginState === "LOGGED_IN") {
            surveyClient.tryGetMe().then(user => {
              context.commit(mutations.LOGIN_SUCCESS, {
                user
              });
              context
                .dispatch(actions.GOT_LOGGED_IN_USER, { user })
                .then(() => payload.success());
            });
          }
        })
        .catch(e => {
          context.commit(mutations.LOGIN_SUCCESS, { user: null });
          context.commit(mutations.ADD_ERROR, { errors: ["Login error"] });
        });
    },
    [actions.TRY_LOGIN](context, payload: LoginAttempt) {
      context.dispatch(mutations.CLEAR_ERRORS).then(() => console.log("noop"));
      return surveyClient
        .tryLogin({
          username: payload.credentials.username,
          password: payload.credentials.password
        })
        .then(loginState => {
          context.commit(mutations.GOT_LOGIN_STATE, { state: loginState });
          if (loginState === "LOGGED_IN") {
            surveyClient.tryGetMe().then(user => {
              context.commit(mutations.LOGIN_SUCCESS, {
                user
              });
              context
                .dispatch(actions.GOT_LOGGED_IN_USER, { user })
                .then(() => payload.success());
            });
          }
        })
        .catch(e => {
          context.commit(mutations.LOGIN_SUCCESS, { user: null });
          context.commit(mutations.ADD_ERROR, { errors: ["Login error"] });
        });
    },
    [actions.GET_TAG_USAGE](context) {
      return surveyClient.getTagUsage().then(tagUsage => {
        context.commit(mutations.GOT_TAG_USAGE, { tagUsage });
      });
    },
    [actions.GET_ALL_CALENDAR_ENTRIES](context) {
      return surveyClient
        .getCalendarEntries()
        .then(entries =>
          context.commit(mutations.GOT_ALL_CALENDAR_ENTRIES, { entries })
        );
    },
    [actions.GET_FACILITY](context, { facilityId }: { facilityId: number }) {
      return surveyClient.getFacility(facilityId).then(facility => {
        context.commit(mutations.GOT_FACILITY, { facility });
      });
    },
    [actions.GET_EQUIPMENT_TYPES](context) {
      return surveyClient
        .getEquipmentTypes()
        .then(et =>
          context.commit(mutations.GOT_EQUIPMENT_TYPES, { types: et })
        );
    },
    [actions.GET_FACILITIES](context) {
      return surveyClient
        .getFacilities()
        .then(facilities =>
          context.commit(mutations.GOT_FACILITIES, { facilities })
        );
    },
    [actions.GET_VENDORS](context) {
      return surveyClient
        .getVendors()
        .then(vendors =>
          context.commit(mutations.GOT_VENDORS, { vendors })
        );
    },
    [actions.GET_EQUIPMENT](
      context,
      payload: { facility: number } | undefined
    ) {
      return surveyClient
        .getEquipment(payload)
        .then(equipment =>
          context.commit(mutations.GOT_EQUIPMENT, { equipment })
        );
    },
    [actions.SAVE_PUNCH_FACILITY](
      context,
      payload: { notes: string; facility: number }
    ) {
      const punchFacility = Object.assign({}, context.state.punchFacility);
      // alert(`Payload: ${JSON.stringify(payload)}`)
      punchFacility.notes = payload.notes;
      punchFacility.facility = payload.facility;
      punchFacility.id = punchFacility.id || null;
      return surveyClient
        .createOrUpdatePunchFacility(punchFacility)
        .then(pf => {
          context.commit(mutations.GOT_PUNCH_FACILITY, pf);
        });
    },
    [actions.GET_PUNCH_FACILITY](context, payload: { facility: number }) {
      if (!(context.state.authentication.currentUser?.punch === true)) {
        console.warn("No punch permission, not sending request");
        return;
      }
      return surveyClient.getPunchFacility(payload).then(pf => {
        context.commit(
          mutations.GOT_PUNCH_FACILITY,
          pf.length > 0 ? pf[0] : { notes: "" }
        );
      });
    },
    [actions.CREATE_OR_UPDATE_PUNCH_ITEM](
      context,
      payload: {
        image?: any;
        imageComment?: string;
        toSave: PunchItem;
        success: () => void;
        tags: string[];
      }
    ) {
      console.log("Creating/updating PunchItem", payload);
      const tableName = "punch_item";
      const success = () => {
        // context.dispatch(actions.GET_TAG_USAGE);
        payload.success();
      };

      return surveyClient
        .createOrUpdatePunchItem(payload.toSave)
        .then(
          _saveImageAndTags(
            tableName,
            payload.image,
            [],
            success,
            payload.imageComment,
            null,
            "",
            null,
            payload.tags
          )
        )
        .catch(error => alert("Error 211: " + error));
    },
    [actions.DELETE_PUNCH_ITEM](
      context,
      payload: { id: number; facilityId: number }
    ) {
      surveyClient
        .deletePunchItem(payload.id)
        .then(() => {
          context.dispatch(actions.GET_PUNCH_ITEMS, {
            facility: payload.facilityId
          });
        })
        .catch(e => alert("Cannot delete " + e + "!"));
    },
    [actions.GET_PUNCH_ITEMS](context, payload: { facility: number }) {
      if (!(context.state.authentication.currentUser?.punch === true)) {
        console.warn("No punch permission, not sending request");
        return;
      }
      return surveyClient.getPunchItems(payload).then(pi => {
        context.commit(mutations.GOT_PUNCH_ITEMS, pi);
      });
    },
    [actions.GET_FENCING](context, payload: { facility: number } | undefined) {
      return surveyClient.getFencing(payload).then(fencing => {
        if (payload !== undefined && payload.facility !== undefined) {
          context.commit(mutations.GOT_FENCING, { fencing });
        } else {
          context.commit(mutations.GOT_ALL_FENCING, { fencing });
        }
      });
    },
    [actions.GET_GATES](context, payload: { facility: number } | undefined) {
      return surveyClient
        .getGates(payload)
        .then(gates => context.commit(mutations.GOT_GATES, { gates }));
    },
    [actions.GET_USER_CALENDAR_ENTRIES](context, payload: { userId: number }) {
      return surveyClient
        .getCalendarEntriesForUser(payload.userId)
        .then(calendarEntries => {
          context.commit(mutations.GOT_USER_CALENDAR_ENTRIES, {
            calendarEntries
          });
        });
    },
    [actions.GET_USERS](context) {
      return surveyClient
        .getUsers()
        .then(users => context.commit(mutations.GOT_USERS, { users }));
    },
    [actions.GET_CONTACT_TYPES](context) {
      return surveyClient
        .getContactTypes()
        .then(contactTypes =>
          context.commit(mutations.GOT_CONTACT_TYPES, { contactTypes })
        );
    },
    [actions.GET_CONTACTS](context) {
      return surveyClient
        .getContacts()
        .then(contacts => context.commit(mutations.GOT_CONTACTS, { contacts }));
    },
    [actions.GET_TAGS](context) {
      return surveyClient
        .getTags()
        .then(tags => context.commit(mutations.GOT_TAGS, { tags }));
    },
    [actions.GET_PUNCH_TAGS](context) {
      if (!(context.state.authentication.currentUser?.punch === true)) {
        console.warn("No punch permission, not sending request");
        return;
      }
      return surveyClient
        .getPunchTags()
        .then(tags => context.commit(mutations.GOT_PUNCH_TAGS, { tags }));
    },
    [actions.GET_MANUFACTURERS](context) {
      return surveyClient
        .getManufacturers()
        .then(manufacturers =>
          context.commit(mutations.GOT_MANUFACTURERS, { manufacturers })
        );
    },
    [actions.UPDATE_LOCATION](
      context,
      payload: {
        lat: number;
        lng: number;
        recordId: number;
        tableName: "facility" | "equipment" | "gate" | "fencing";
      }
    ) {
      return surveyClient
        .updateItemLocation(
          payload.lat,
          payload.lng,
          payload.recordId,
          payload.tableName
        )
        .then(() => context.commit(mutations.UPDATED_LOCATION, payload));
    },
    [actions.CREATE_OR_UPDATE_FACILITY](
      context,
      payload: {
        sitePlan?: any;
        floorPlan: any;
        image?: any;
        imageComment?: string;
        toSave: Facility;
        success: () => void;
      }
    ) {
      if (payload.imageComment === undefined) {
        payload.imageComment = "";
      }
      return surveyClient
        .createOrUpdateFacility(payload.toSave)
        .then(result => {
          const promisesToWaitFor = [];
          if (payload.image !== undefined && payload.image !== null) {
            promisesToWaitFor.push(
              surveyClient.uploadAttachment({
                file: payload.image,
                uploadName: result.name,
                tableName: "facility",
                recordId: "" + result.id,
                attachmentType: AttachmentType.MAIN_IMAGE,
                notes: "" + payload.imageComment
              })
            );
          }
          if (payload.sitePlan !== undefined && payload.sitePlan !== null) {
            promisesToWaitFor.push(
              surveyClient.uploadAttachment({
                file: payload.sitePlan,
                uploadName: result.name,
                tableName: "facility",
                recordId: "" + result.id,
                attachmentType: AttachmentType.SITE_PLAN,
                notes: ""
              })
            );
          }
          if (payload.floorPlan !== undefined && payload.floorPlan !== null) {
            promisesToWaitFor.push(
              surveyClient.uploadAttachment({
                file: payload.floorPlan,
                uploadName: result.name,
                tableName: "facility",
                recordId: "" + result.id,
                attachmentType: AttachmentType.FLOOR_PLAN,
                notes: ""
              })
            );
          }
          Promise.all(promisesToWaitFor)
            .then(() =>
              context.dispatch(actions.GET_FACILITIES).then(payload.success)
            )
            .catch(err => alert("Error 101: " + err));
        })
        .catch(error => alert("Error 102: " + error));
    },
    [actions.CREATE_OR_UPDATE_EQUIPMENT](
      context,
      payload: {
        tags: string[];
        image?: any;
        imageComment?: string;
        toSave: Equipment;
        success: () => void;
        file?: any;
        fileComment?: string;
        toastGenerator?: BvToast;
      }
    ) {
      const tableName = "equipment";
      const success = () => {
        payload.success();
        context.dispatch(actions.GET_TAG_USAGE);
      };
      return surveyClient
        .createOrUpdateEquipment(payload.toSave)
        .then(
          _saveImageAndTags(
            tableName,
            payload.image,
            payload.tags,
            success,
            payload.imageComment,
            payload.file,
            payload.fileComment,
            payload.toastGenerator || null
          )
        )
        .catch(error => alert("Error 110: " + error));
    },
    [actions.CREATE_OR_UPDATE_SELECT_CONFIG](
      context, payload: {
        toSave: SelectConfig
        success: () => void;
      }
    ) {
      const success = () => {
        context.dispatch(actions.GET_SELECT_CONFIG);
        payload.success();
      };
      return surveyClient.createOrUpdateSelectConfig(payload.toSave).then(success).catch(error => alert("Error 811: " + error));
    },
    [actions.CREATE_OR_UPDATE_FENCING](
      context,
      payload: {
        image?: any;
        imageComment?: string;
        toSave: Fencing;
        success: () => void;
        tags: string[];
      }
    ) {
      const tableName = "fencing";
      const success = () => {
        context.dispatch(actions.GET_TAG_USAGE);
        payload.success();
      };

      return surveyClient
        .createOrUpdateFencing(payload.toSave)
        .then(
          _saveImageAndTags(
            tableName,
            payload.image,
            payload.tags,
            success,
            payload.imageComment
          )
        )
        .catch(error => alert("Error 111: " + error));
    },
    [actions.CREATE_OR_UPDATE_GATE](
      context,
      payload: {
        image?: any;
        imageComment?: string;
        toSave: Gate;
        success: () => void;
        tags: string[];
      }
    ) {
      const tableName = "gate";
      const success = () => {
        context.dispatch(actions.GET_TAG_USAGE);
        payload.success();
      };

      return surveyClient
        .createOrUpdateGate(payload.toSave)
        .then(
          _saveImageAndTags(
            tableName,
            payload.image,
            payload.tags,
            success,
            payload.imageComment
          )
        )
        .catch(error => alert("Error 103: " + error));
    },
    [actions.CREATE_OR_UPDATE_USER](
      context,
      payload: {
        image?: any;
        imageComment?: string;
        toSave: User;
        success: () => void;
      }
    ) {
      return surveyClient
        .createOrUpdateUser(payload.toSave)
        .then(result => {
          if (payload.image !== undefined && payload.image !== null) {
            surveyClient
              .uploadAttachment({
                file: payload.image,
                uploadName: result.username,
                tableName: "user",
                recordId: "" + result.id,
                attachmentType: AttachmentType.MAIN_IMAGE,
                notes: "" + payload.imageComment
              })
              .then(() =>
                context.dispatch(actions.GET_USERS).then(payload.success)
              );
          } else {
            context.dispatch(actions.GET_USERS).then(payload.success);
          }
        })
        .catch(error => alert("Error 104: " + error));
    },
    [actions.CREATE_OR_UPDATE_CONTACT](
      context,
      payload: { toSave: Contact; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateContact(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_CONTACTS).then(payload.success);
        })
        .catch(error => alert("Error 105: " + error));
    },
    [actions.CREATE_OR_UPDATE_CONTACT_TYPE](
      context,
      payload: { toSave: ContactType; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateContactType(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_CONTACT_TYPES).then(payload.success);
        })
        .catch(error => alert("Error 155: " + error));
    },
    [actions.DELETE_CONTACT_TYPE](
      context, payload: {id: number}
    ){
      return surveyClient.deleteContactType(payload.id).then((x) => {
        context.dispatch(actions.GET_CONTACT_TYPES)
      });
    },
    [actions.DELETE_CONTACT](
      context, payload: {id: number}
    ){
      return surveyClient.deleteContact(payload.id).then((x) => {
        context.dispatch(actions.GET_CONTACTS)
      });
    },
    [actions.CREATE_OR_UPDATE_CALENDAR_ENTRY](
      context,
      payload: {
        toSave: CalendarEntry;
        success: () => void;
        attendees?: PersonForMeeting[];
      }
    ) {
      return surveyClient
        .createOrUpdateCalendarEntry(payload.toSave)
        .then(result => {
          if (payload.attendees) {
            surveyClient
              .deleteCalendarAttendeesFor(result!.id as number)
              .then(() => {
                const promisesToWaitFor: Promise<any>[] = [];
                payload.attendees!.forEach((attendee: PersonForMeeting) => {
                  promisesToWaitFor.push(
                    surveyClient.createOrUpdateCalendarAttendee({
                      id: null,
                      calendarEntry: result.id!,
                      contact:
                        attendee.type === "contact" ? attendee.id : undefined,
                      user: attendee.type === "user" ? attendee.id : undefined,
                      tableName: "calendar_attendee"
                    })
                  );
                });
                Promise.all(promisesToWaitFor).then(() => {
                  context
                    .dispatch(actions.GET_ALL_CALENDAR_ENTRIES, {
                      userId: this.state.authentication.currentUser!.id
                    })
                    .then(() => console.log("noop"));
                  context
                    .dispatch(actions.GET_USER_CALENDAR_ENTRIES, {
                      userId: this.state.authentication.currentUser!.id
                    })
                    .then(payload.success);
                });
              });
          } else {
            context
              .dispatch(actions.GET_ALL_CALENDAR_ENTRIES, {
                userId: this.state.authentication.currentUser!.id
              })
              .then(() => console.log("noop"));
            context
              .dispatch(actions.GET_USER_CALENDAR_ENTRIES, {
                userId: this.state.authentication.currentUser!.id
              })
              .then(payload.success);
          }
        })
        .catch(error => alert("Error 106: " + error));
    },
    [actions.CREATE_OR_UPDATE_FACILITY_PROFILE](
      context,
      payload: { toSave: FacilityProfile, success: () => void }
    ) {
      return surveyClient.createOrUpdateFacilityProfile(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_FACILITY_PROFILES).then(payload.success);
        })
        .catch(error => alert("Error 307: " + error));
    },
    [actions.CREATE_OR_UPDATE_TAG](
      context,
      payload: { toSave: Tag; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateTag(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_TAGS).then(payload.success);
        })
        .catch(error => alert("Error 107: " + error));
    },
    [actions.CREATE_OR_UPDATE_PUNCH_TAG](
      context,
      payload: { toSave: Tag; success: () => void }
    ) {
      return surveyClient
        .createOrUpdatePunchTag(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_PUNCH_TAGS).then(payload.success);
        })
        .catch(error => alert("Error 1017: " + error));
    },
    [actions.CREATE_OR_UPDATE_EQUIPMENT_TYPE](
      context,
      payload: { toSave: EquipmentType; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateEquipmentType(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_EQUIPMENT_TYPES).then(payload.success);
        })
        .catch(error => alert("Error 108: " + error));
    },
    [actions.ADD_COMMENT](
      context,
      payload: {
        table: "attach";
        id: number;
        comment: string;
        recordTable: string;
      }
    ) {
      return surveyClient
        .addComment(payload.table, payload.id, payload.comment, payload.recordTable)
        .then(() => {
          context.commit(mutations.UPDATE_ATTACHMENT_NOTES, {
            id: payload.id,
            notes: payload.comment
          });
        });
    },
    //rotate(tableName: string, imageId: number, rotation: number){
    [actions.ROTATE_IMAGE](
      context,
      payload: { tableName: string, imageId: number, rotation: number }
    ) {
      return surveyClient.rotate(payload.tableName, payload.imageId, payload.rotation);
    },
    [actions.CREATE_OR_UPDATE_MANUFACTURER](
      context,
      payload: { toSave: Manufacturer; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateManufacturer(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_MANUFACTURERS).then(payload.success);
        })
        .catch(error => alert("Error 108: " + error));
    },
    [actions.CREATE_OR_UPDATE_VENDOR](
      context,
      payload: { toSave: Vendor; success: () => void }
    ) {
      return surveyClient
        .createOrUpdateVendor(payload.toSave)
        .then(() => {
          context.dispatch(actions.GET_VENDORS).then(payload.success);
        })
        .catch(error => alert("Error 198: " + error));
    },
    [actions.SEARCH](context, payload: SearchData & SuccessMethod) {
      surveyClient.search(payload).then(data => {
        context.commit(mutations.GOT_SEARCH_DATA, { data });
        payload.success();
      });
    },
    [actions.UPLOAD_FILE](
      context,
      payload: {
        image: any;
        imageComment?: string;
        uploadName: string;
        tableName: string;
        recordId: string | number;
        success: () => void;
        attachmentType?: AttachmentType;
        id?: number;
        rotation?: number;
      }
    ) {
      const at = payload.attachmentType
        ? payload.attachmentType
        : AttachmentType.MAIN_IMAGE;
      return surveyClient
        .uploadAttachment({
          file: payload.image,
          uploadName: payload.uploadName,
          tableName: payload.tableName,
          recordId: payload.recordId + "",
          attachmentType: at,
          notes: payload.imageComment ? payload.imageComment : "",
          id: payload.id
        })
        .then(() => {
          payload.success();
        })
        .catch(error => {
          console.error("Error 109", error);
          alert("Error 109: " + error);
        });
    }
  },
  getters: {
    canWrite(state){
      if(state.authentication.currentUser === null){
        return false;
      }
      return state.authentication.currentUser.admin || state.authentication.currentUser.edit
    },
    listNameFromKey(state) {
      return (key: string) => {
        const collector: any = {};
        state.selectConfig.forEach(
          (sc: SelectConfig) => collector[sc.key] = sc.name
        );
        return collector[key];
      };
    },
    listOfSelects(state) {
      const collector: any = {};
      state.selectConfig.forEach(
        (sc: SelectConfig) => collector[sc.key] = sc.name
      );
      return Object.keys(collector).map((k) => {
        return { name: collector[k], key: k };
      });
    },
    selectData(state) {
      return function (key: string) {
        return state.selectConfig.map((sc: SelectConfig) => {
          if (sc.key == key) {
            return {
              value: sc.value,
              text: sc.label,
            };
          }
        }).filter((x: any) => x !== undefined);
      };
    },
    listData(state) {
      return function (key: string) {
        return state.selectConfig.map((sc: SelectConfig) => {
          if (sc.key == key) {
            return sc;
          }
        }).filter((x: any) => x !== undefined);
      };
    }
  },
  modules: {}
});

export default store;

export function getFencingById(id: number): Fencing | undefined {
  let fencing = undefined;
  store.state.allFencing.forEach((c: Fencing) => {
    if (c.id === id) {
      fencing = { ...c };
    }
  });
  return fencing;
}

export function getFacilityById(id: number): Facility | undefined {
  let facility = undefined;
  store.state.facilities.forEach((c: Facility) => {
    if (c.id === id) {
      facility = { ...c };
    }
  });
  return facility;
}

export function getEquipmentType(id: number): EquipmentType {
  let et = { id: -1, name: `Unknown type` } as EquipmentType;
  store.state.equipmentTypes.forEach((t: EquipmentType) => {
    if (id === t.id) {
      et = t;
    }
  });
  return et;
}

export function
getEquipmentById(id: number): Equipment | undefined {
  let equipment = undefined;
  store.state.equipment.forEach((e: Equipment) => {
    if (e.id === id) {
      equipment = { ...e };
    }
  });
  if (equipment === undefined) {
    alert(`Cannot find equipment with id ${id}`);
  }
  return equipment;
}

export function getFacilityByFencingId(id: number): Facility | undefined {
  const fencing = getFencingById(id);
  if (fencing === undefined) {
    return fencing;
  }
  return getFacilityById(fencing.facility);
}

export function tagsForSelect() {
  return store.state.tags.map((tag: Tag) => {
    return {
      value: tag.id,
      text: tag.tag
    };
  });
}

export function getUserByUsername(username: string): User | undefined {
  let user = undefined;
  store.state.users.forEach((u: User) => {
    if (u.username === username) {
      user = u;
    }
  });
  return user;
}

export function getContactById(id: number): Contact | undefined {
  let contact = undefined;
  store.state.contacts.forEach((c: Contact) => {
    if (c.id === id) {
      contact = { ...c };
    }
  });
  return contact;
}

export function equipmentForSelect() {
  return store.state.equipment.map((e: Equipment) => {
    return {
      value: e.id,
      text: `${e.name}`
    };
  });
}

export function equipmentNameFromId(id: number | string) {
  let name = "unknown";
  store.state.equipment.forEach((e: Equipment) => {
    if (`${id}` === `${e.id}`) {
      name = e.name;
    }
  });
  return name;
}

export function contactTypesForSelect() {
  return store.state.contactTypes.map((type: ContactType) => {
    return {
      value: type.id,
      text: `${type.type}`
    };
  });
}

export function surveyorsForSelect() {
  return store.state.users.map((user: User) => {
    return {
      value: user.username,
      text: `${user.username} - ${user.firstName} ${user.lastName}`
    };
  });
}

export function contactsForSelect() {
  return store.state.contacts
    .map((contact: Contact) => {
      return {
        value: contact.id,
        text: `${contact.firstName} ${contact.lastName} - ${contact.company}`
      };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
}

export function facilitiesForSelect() {
  return store.state.facilities.map((facility: Facility) => {
    return {
      value: facility.id,
      text: `${facility.name} (${facility.city}, ${facility.state})`
    };
  });
}

export function manufacturersForSelect() {
  return store.state.manufacturers.map((manufacturer: Manufacturer) => {
    return {
      value: manufacturer.id,
      text: `${manufacturer.company}`
    };
  });
}

export function allFencingForSelect() {
  return store.state.allFencing.map((fencing: Fencing) => {
    return {
      value: fencing.id,
      text: `${getFacilityById(fencing.facility)?.name} - ${fencing.name}`
    };
  });
}

export function fencingForSelect() {
  return store.state.fencing.map((fencing: Fencing) => {
    return { value: fencing.id, text: fencing.name };
  });
}

export function getPeopleFromStore(): PersonForMeeting[] {
  return store.state.contacts
    .map((contact: Contact) => {
      return {
        name: `${contact.firstName} ${contact.lastName}`,
        company: contact.company,
        email: contact.email,
        type: "contact",
        id: contact.id,
        key: contact.id + "_C"
      } as PersonForMeeting;
    })
    .concat(
      store.state.users.map((user: User) => {
        return {
          name: `${user.firstName} ${user.lastName}`,
          company: "none",
          email: `${user.email}`,
          type: "user",
          id: user.id,
          key: user.id + "_U"
        } as PersonForMeeting;
      })
    );
}

export function gateOptypeChoicesStore() {
  return [
    { value: "motorized", text: "motorized" },
    { value: "manual", text: "manual" }
  ];
}

export function gateTypeChoicesStore() {
  return [
    { value: "vehicle", text: "vehicle" },
    { value: "pedestrian", text: "pedestrian" }
  ];
}

export function gateAcctypeChoicesStore() {
  return ["reader", "keypad", "key", "knox box", "punch key code", "other"].map(
    item => {
      return { value: item, text: item };
    }
  );
}
