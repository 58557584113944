
import { Component, Vue } from "vue-property-decorator";
import AddressDisplay from "@/components/AddressDisplay.vue";
import { getContactById } from "@/store/index";
import ContactDisplayNameEmailCall from "@/components/ContactDisplayNameEmailCall.vue";
import PdfGenerator from "@/export/pdf";
import {
  getContactFieldsForExport,
  getContactHeaderFields,
  getManufacturerFieldsForExport,
  getManufacturerHeaderFields
} from "@/export/util";
import { generateSpreadsheetSingleTableAndDownload } from "@/export/excel";

@Component({
  components: {
    ContactDisplayNameEmailCall,
    AddressDisplay
  }
})
export default class ManufacturerTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  getContact(id: number) {
    return getContactById(id);
  }

  get fields() {
    return [
      { key: "company", sortable: true },
      { key: "website", sortable: true },
      { key: "contact", sortable: true },
      { key: "contactEmail", sortable: true },
      { key: "mainPhone", sortable: true },
      { key: "address", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get manufacturers() {
    return this.$store.state.manufacturers;
  }

  exportTableData() {
    const table: any = this.$refs.theTable;
    new PdfGenerator()
      .getManufacturersTable(table.computedItems, this.$store.state.contacts)
      .then((pdfDoc: any) => {
        pdfDoc.download(`priplan-manufacturers.pdf`);
      });
  }

  getTableComputedItems() {
    const table: any = this.$refs.theTable;
    return table.computedItems;
  }

  exportTableDataExcel() {
    const data: string[][] = [getManufacturerHeaderFields()].concat(
      getManufacturerFieldsForExport(this.getTableComputedItems())
    );
    generateSpreadsheetSingleTableAndDownload(
      data,
      "priplan-manufacturers.xlsx"
    );
  }
}
