
import { Component, Vue } from "vue-property-decorator";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { actions } from "@/survey";

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput }
})
export default class AdminSettingsForm extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  client = Vue.prototype.$surveyClient;
  settings: any = null;

  valueUpdate(key: string, val: string) {
    // console.log(`${key} = ${val}`)
    Object.keys(this.settings).forEach((s) => {
      if (s === key) {
        this.settings[s].first = val;
      }
    });
  }

  get settingsArray() {
    if (this.settings === null) {
      return null;
    }
    return Object.keys(this.settings).map((k) => {
      return { key: k, value: this.settings[k] };
    });
  }

  async created() {
    this.settings = await this.client.getAdminConfig();
    // alert(JSON.stringify(this.settings))
  }

  async saveConfig() {
    this.settings = await this.client.saveAdminConfig(this.settings);
    const configSettings = this.$store.dispatch(actions.GET_CONFIG);
    alert("Saved settings");
  }
}
