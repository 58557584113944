import {
  Addressable,
  Contact,
  Equipment,
  EquipmentType,
  Facility,
  Fencing,
  Gate,
  Manufacturer,
  User
} from "@/survey";
import { getFacilityById, getFencingById } from "@/store";
import { dateFormatter, equipmentTypeFormatter } from "@/components/formatters";

export const getColumnLengths = (data: string[][]): number[] => {
  const vals = data[0].map(() => 0);
  data.forEach((row: string[]) => {
    row.forEach((str, col) => {
      if (str !== null && str.split) {
        str.split("\n").forEach(lineStr => {
          vals[col] = lineStr.length > vals[col] ? lineStr.length : vals[col];
        });
      }
    });
  });
  return vals;
};

export const getAddressAsText = (
  addressable: Addressable,
  newlines = true
): string => {
  const separator = newlines ? "\n" : ", ";
  return (
    addressable.address1 +
    (addressable.address2 ? separator + addressable.address2 : "") +
    separator +
    addressable.city +
    "," +
    addressable.state +
    " " +
    addressable.zip
  );
};

export const getFacilityFieldValuesForExport = (
  facilities: any[],
  contacts: Contact[],
  newlines = true
) => {
  return facilities.map(facility => {
    const contact = contacts.reduce((acc: Contact, current: Contact) => {
      if (current.id === facility.contact) {
        return current;
      }
      return acc;
    });

    return [
      facility.facilityCode,
      facility.name,
      facility.surveyTeam,
      facility.surveyor,
      `${contact.firstName} ${contact.lastName}`,
      facility.status,
      facility.priority,
      facility.size,
      getAddressAsText(facility, newlines),
      facility.ophours,
      facility.secstaffonsite.toString(),
      facility.visitorsignage.toString(),
      facility.privpropsignage.toString(),
      facility.lightingissues.toString(),
      facility.hasBathroom.toString(),
      facility.asbestosSignage.toString()
    ];
  });
};

export const getGateFieldsForExport = (gates: Gate[]) => {
  return gates.map(g => {
    return [
      `${g.name}`,
      `${getFacilityById(getFencingById(g.fencing!)!.facility)!.name}`,
      `${g.description}`,
      `${g.gatetype}`,
      `${g.optype}`,
      `${g.acctype}`
    ];
  });
};

export const getFencingFieldsForExport = (fencing: Fencing[]) => {
  return fencing.map(f => {
    return [
      `${f.name}`,
      `${getFacilityById(f.facility!)!.name}`,
      `${f.isfenced}`,
      `${f.fencedamaged}`,
      `${f.vegproblem}`,
      `${f.climbingaids}`
    ];
  });
};

export const getEquipmentFieldsForExport = (
  equipment: Equipment[],
  equipmentTypes: EquipmentType[]
) => {
  const equipmentTypeRenderer = equipmentTypeFormatter(equipmentTypes);
  return equipment.map(e => {
    return [
      equipmentTypeRenderer(e.equipmentType),
      getFacilityById(e.facility!)!.name,
      e.name,
      `${e.surveyTeam}`,
      `${e.surveyed === true ? "Y" : "N"}`,
      e.description,
      e.modelNo,
      e.serialNo,
      e.macAddress,
      e.location,
      e.installDate,
      `${e.isLabeled === true}`,
      `${(e.door === 2) === true}`,
      `${e.exterior === 2}`,
      `${e.public === 2}`,
      `${e.validRead === 2}`,
      `${e.doorForced === 2}`,
      `${e.doorHeld === 2}`,
      `${e.doorRex === 2}`
    ];
  });
};

export const getUserFieldsForExport = (users: User[]) => {
  return users.map(u => {
    return [
      `${u.username}`,
      `${u.firstName} ${u.lastName}`,
      `${u.email}`,
      `${u.phone}`,
      `${u.active}`,
      `${u.dateCreated}`,
      `${u.create}`,
      `${u.edit}`,
      `${u.view}`,
      `${u.delete}`,
      `${u.export}`,
      `${u.admin}`
    ];
  });
};
export const getFacilityHeaderFields = () => {
  return [
    "Code",
    "Name",
    "AKA",
    "Survey Team",
    "Surveyor",
    "Contact",
    "Status",
    "Priority",
    "Size",
    "Address",
    "Hours",
    "Security Onsite",
    "Visitor Signage",
    "Private Signage",
    "Lighting Issues",
    "Bathroom",
    "Asbestos Signage"
  ];
};

export const getGateHeaderFields = () => {
  return [
    "Name",
    "Facility",
    "Description",
    "Gate type",
    "Operation",
    "Access control"
  ];
};

export const getFencingHeaderFields = () => {
  return [
    "Name",
    "Facility",
    "Fenced",
    "Damaged",
    "Vegetation",
    "Climbing aids"
  ];
};

export const getEquipmentHeaderFields = () => {
  return [
    "Type",
    "Facility",
    "Name",
    "Survey Team",
    "Surveyed",
    "Description",
    "Model",
    "Serial",
    "MAC",
    "Location",
    "Installed",
    "Labeled",
    "Door",
    "Exterior",
    "Public",
    "Valid read",
    "Forced",
    "Held",
    "REX"
  ];
};

export const getUserActivityFieldsForExport = (activity: any[]) => {
  const df = dateFormatter();
  return activity.map(a => {
    return [
      a.user,
      a.action,
      a.objectType,
      df(a.happenedTimestamp)
    ];
  });
};
export const getContactFieldsForExport = (contacts: Contact[]) => {
  return contacts.map(c => {
    return [
      `${c.firstName} ${c.lastName}`,
      `${c.email}`,
      `${c.phone}`,
      `${c.phone2}`,
      `${c.company}`,
      `${c.title}`,
      `${getAddressAsText(c, false)}`,
      `${c.contactType}`,
      `${c.notes}`
    ];
  });
};

export const getManufacturerHeaderFields = () => {
  return [
    "Company",
    "Website",
    "Contact",
    "Contact Email",
    "Main Phone",
    "Address"
  ];
};
export const getManufacturerFieldsForExport = (
  manufacturers: Manufacturer[]
) => {
  return manufacturers.map(m => {
    return [
      `${m.company}`,
      `${m.website}`,
      `${m.contact}`,
      `${m.contactEmail}`,
      `${m.mainPhone}`,
      `${getAddressAsText(m, false)}`
    ];
  });
};
export const getContactHeaderFields = () => {
  return [
    "Name",
    "Email",
    "Phone",
    "Phone 2",
    "Company",
    "Title",
    "Address",
    "Type",
    "Notes"
  ];
};

export const getUserHeaderFields = () => {
  return [
    "Username",
    "Name",
    "Email",
    "Phone",
    "Active",
    "Created",
    "Create",
    "Edit",
    "View",
    "Delete",
    "Export",
    "Admin"
  ];
};
