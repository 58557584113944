
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class EquipmentTypeTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "name", sortable: true },
      { key: "iconSvg", sortable: false, label: "Icon" },
      { key: "actions", sortable: false },
    ];
  }

  get equipmentTypes() {
    return this.$store.state.equipmentTypes;
  }
}
