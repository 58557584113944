import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Calendar from "@/views/events/Calendar.vue";
import AdminFacilityForm from "@/views/admin/AdminFacilityForm.vue";
import FacilityTable from "@/views/admin/FacilityTable.vue";
import LoginForm from "@/components/LoginForm.vue";
import UserTable from "@/views/admin/UserTable.vue";
import AdminSection from "@/views/admin/AdminSection.vue";
import AdminUserForm from "@/views/admin/AdminUserForm.vue";
import { actions, UserRole } from "@/survey";
import FacilityList from "@/views/FacilityList.vue";
import SurveyFacility from "@/views/SurveyFacility.vue";
import store from "@/store";
import AdminContactForm from "@/views/admin/AdminContactForm.vue";
import ContactTable from "@/views/admin/ContactTable.vue";
import TagTable from "@/views/admin/TagTable.vue";
import PunchTagTable from "@/views/admin/PunchTagTable.vue";
import AdminTagForm from "@/views/admin/AdminTagForm.vue";
import ManufacturerTable from "@/views/admin/ManufacturerTable.vue";
import AdminManufacturerForm from "@/views/admin/AdminManufacturerForm.vue";
import EventList from "@/views/events/EventList.vue";
import EventForm from "@/views/events/EventForm.vue";
import ReportForm from "@/views/admin/ReportForm.vue";
import Me from "@/views/Me.vue";
import FileUploadMultiple from "@/components/FileUploadMultiple.vue";
import ReportDisplay from "@/views/admin/ReportDisplay.vue";
import FullPageChart from "@/views/FullPageChart.vue";
import FacilityPriorityBar from "@/views/charts/FacilityPriorityBar.vue";
import FacilitySizePie from "@/views/charts/FacilitySizePie.vue";
import FacilityStatusBar from "@/views/charts/FacilityStatusBar.vue";
import TagUsagePie from "@/views/charts/TagUsagePie.vue";
import EquipmentTypeTable from "@/views/admin/EquipmentTypeTable.vue";
import AdminEquipmentTypeForm from "@/views/admin/AdminEquipmentTypeForm.vue";
import AdminPunchTagForm from "@/views/admin/AdminPunchTagForm.vue";
import VendorTable from "@/views/admin/VendorTable.vue";
import AdminVendorForm from "@/views/admin/AdminVendorForm.vue";
import DBInfo from "@/views/admin/DBInfo.vue";
import AdminSettingsForm from "@/views/admin/AdminSettingsForm.vue";
import ListOfListsToEdit from "@/views/admin/ListOfListsToEdit.vue";
import ListEditor from "@/views/admin/ListEditor.vue";
import ListItemForm from "@/views/admin/ListItemForm.vue";
import AdminFacilityProfileForm from "@/views/admin/AdminFacilityProfileForm.vue";
import FacilityProfileTable from "@/views/admin/FacilityProfileTable.vue";
import MyFacilityMap from "@/views/MyFacilityMap.vue";
import UserInfo from "@/views/admin/UserInfo.vue";
import UserActivity from "@/views/admin/UserActivity.vue";
import About from "@/views/About.vue";
import ContactTypeTable from "@/views/admin/ContactTypeTable.vue";
import AdminContactTypeForm from "@/views/admin/AdminContactTypeForm.vue";

Vue.use(VueRouter);

const TUP = { template: "<TagUsagePie style='height: 100vh'/>" };

const routes: Array<RouteConfig> = [
  {
    path: "/facility-map",
    name: "My Facility Map",
    component: MyFacilityMap,
    meta: { requiresAuth: true }
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: "/auth",
    name: "Auth",
    component: LoginForm,
  },
  {
    path: "/me",
    name: "Me",
    component: Me,
    meta: { requiresAuth: true }
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: "/events",
    name: "Event List",
    component: EventList,
    meta: { requiresAuth: true }
  },
  {
    path: "/reports",
    name: "Reports",
    component: ReportForm,
    meta: {
      requiresAuth: true,
      preRoute: (to: Route, from: Route, next: Function) => {
        store.dispatch(actions.GET_FENCING);
        next();
      }
    }
  },
  {
    path: "/report-display",
    name: "ReportDisplay",
    component: ReportDisplay,
    meta: {
      requiresAuth: true,
      preRoute: (to: Route, from: Route, next: any) => {
        if (Object.keys(store.state.searchResult as any).length === 0) {
          next("/reports");
        } else {
          next();
        }
      }
    }
  },
  {
    path: "/event/new",
    name: "New Event",
    component: EventForm,
    meta: { requiresAuth: true, requiresRole: ["admin", "edit"] }
  },
  {
    path: "/event/edit/:id",
    name: "Edit Event",
    component: EventForm,
    meta: { requiresAuth: true, requiresRole: ["admin", "edit"] }
  },
  {
    path: "/choose-facility",
    name: "Choose Facility",
    component: FacilityList,
    meta: { requiresAuth: true }
  },
  {
    path: "/upload/:table/:recordId/:facilityId/:recordName/:backTo",
    name: "Upload Multiple",
    component: FileUploadMultiple,
    meta: { requiresAuth: true, requiresRole: ["admin", "edit"] }
  },
  {
    path: "/survey/:id",
    name: "Survey Facility",
    component: SurveyFacility,
    meta: {
      requiresAuth: true,
      preRoute: (to: Route, from: Route, next: Function) => {
        store.dispatch(actions.GET_EQUIPMENT, { facility: to.params.id });
        store.dispatch(actions.GET_FENCING, { facility: to.params.id });
        store.dispatch(actions.GET_GATES, { facility: to.params.id });
        store.dispatch(actions.GET_PUNCH_ITEMS, { facility: to.params.id });
        store.dispatch(actions.GET_PUNCH_FACILITY, { facility: to.params.id });
        next();
      }
    }
  },
  {
    path: "/login",
    name: "Login",
    component: LoginForm
  },
  {
    path: "/logout",
    meta: {
      requiresAuth: true,
      preRoute: (to: Route, from: Route, next: Function) => {
        if (confirm("Are you sure you want to logout?")) {
          store.dispatch(actions.LOGOUT).finally(() => next('/login'));
        } else {
          next(false);
        }
      }
    }
  },
  {
    path: "/chart",
    meta: { requiresAuth: true },
    component: FullPageChart,
    children: [
      {
        path: "priority",
        name: "Priority chart",
        component: FacilityPriorityBar,
        props: { height: "100vh" }
      },
      {
        path: "size",
        name: "Size chart",
        component: FacilitySizePie,
        props: { height: "100vh" }
      },
      {
        path: "status",
        name: "Status chart",
        component: FacilityStatusBar,
        props: { height: "100vh" }
      },
      {
        path: "tag",
        name: "Tag chart",
        component: TagUsagePie,
        props: { height: "100vh" }
      }
    ]
  },
  {
    path: "/admin",
    component: AdminSection,
    meta: { requiresAuth: true, requiresRole: ["admin"] },
    children: [
      {
        path: "user-activity",
        name: "User Activity",
        component: UserActivity
      },
      {
        path: "user-report/:username",
        name: "User Report",
        component: UserInfo
      },
      {
        path: "facility-profiles",
        name: "Facility Profiles",
        component: FacilityProfileTable
      },
      {
        path: "facility-profile/new",
        name: "New Facility Profile",
        component: AdminFacilityProfileForm
      },
      {
        path: "facility-profile/edit/:id",
        name: "Edit Facility Profile",
        component: AdminFacilityProfileForm
      },
      {
        path: "lists",
        name: "Admin Lists",
        component: ListOfListsToEdit,
      },
      {
        path: "lists/:key",
        name: "Admin List",
        component: ListEditor
      },
      {
        path: "lists/:key/new",
        name: "Admin List - New Item",
        component: ListItemForm
      },
      {
        path: "lists/:key/edit/:id",
        name: "Admin List - Edit Item",
        component: ListItemForm
      },
      {
        path: "settings",
        name: "Admin Settings",
        component: AdminSettingsForm
      },
      {
        path: "dbinfo",
        name: "Database Information",
        component: DBInfo
      },
      {
        path: "vendors",
        name: "Admin Vendor List",
        component: VendorTable
      },
      {
        path: "facilities",
        name: "Admin Facility List",
        component: FacilityTable
      },
      {
        path: "users",
        name: "Admin User List",
        component: UserTable
      },
      {
        path: "contacts",
        name: "Admin Contact List",
        component: ContactTable
      },
      {
        path: "contact-types",
        name: "Admin Contact Type List",
        component: ContactTypeTable
      },
      {
        path: "contact-type/new",
        name: "New Contact Type",
        component: AdminContactTypeForm
      },
      {
        path: "contact-type/edit/:id",
        name: "Edit Contact Type",
        component: AdminContactTypeForm
      },
      {
        path: "contact-type/delete/:id",
        name: "Delete Contact Type",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_CONTACT_TYPE, { id: to.params.id })
              .then(() => {
                next({ path: "contact-types" });
              });
          }
        }
      },
      {
        path: "tags",
        name: "Admin Tag List",
        component: TagTable
      },
      {
        path: "punch-tags",
        name: "Admin Punch Tag List",
        component: PunchTagTable
      },
      {
        path: "manufacturers",
        name: "Admin Manufacturer List",
        component: ManufacturerTable
      },
      {
        path: "equipment-types",
        name: "Admin Equipment Type List",
        component: EquipmentTypeTable
      },
      {
        path: "equipment-type/new",
        name: "New Equipment Type",
        component: AdminEquipmentTypeForm
      },
      {
        path: "equipment-type/edit/:id",
        name: "Edit Equipment Type",
        component: AdminEquipmentTypeForm
      },
      {
        path: "equipment-type/delete/:id",
        name: "Delete Equipment Type",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_EQUIPMENT_TYPE, { id: to.params.id })
              .then(() => {
                next({ path: "equipment-types" });
              });
          }
        }
      },
      {
        path: "facility/new",
        name: "New Facility",
        component: AdminFacilityForm
      },
      {
        path: "facility/edit/:id",
        name: "Edit Facility",
        component: AdminFacilityForm
      },
      {
        path: "facility/delete/:id",
        name: "Delete Facility",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_FACILITY, { id: to.params.id })
              .then(() => {
                next({ path: "facilities" });
              });
          }
        }
      },
      {
        path: "vendor/new",
        name: "New Vendor",
        component: AdminVendorForm
      },
      {
        path: "vendor/edit/:id",
        name: "Edit Vendor",
        component: AdminVendorForm
      },
      {
        path: "vendor/delete/:id",
        name: "Delete Vendor",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_VENDOR, { id: to.params.id })
              .then(() => {
                next({ path: "vendors" });
              });
          }
        }
      },
      {
        path: "user/new",
        name: "New User",
        component: AdminUserForm
      },
      {
        path: "user/edit/:id",
        name: "Edit User",
        component: AdminUserForm
      },
      {
        path: "user/delete/:id",
        name: "Delete User",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_USER, { id: to.params.id })
              .then(() => {
                next({ path: "users" });
              });
          }
        }
      },
      {
        path: "contact/delete/:id",
        name: "Delete Contact",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_CONTACT, { id: to.params.id })
              .then(() => {
                next({ path: "contacts" });
              });
          }
        }
      },
      {
        path: "contact/new",
        name: "New Contact",
        component: AdminContactForm
      },
      {
        path: "contact/edit/:id",
        name: "Edit Contact",
        component: AdminContactForm
      },
      {
        path: "tag/new",
        name: "New Tag",
        component: AdminTagForm
      },
      {
        path: "punch-tag/new",
        name: "New Punch Tag",
        component: AdminPunchTagForm
      },
      {
        path: "punch-tag/edit/:id",
        name: "Edit Punch Tag",
        component: AdminPunchTagForm
      },
      {
        path: "punch-tag/delete/:id",
        name: "Delete Punch Tag",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_PUNCH_TAG, { id: to.params.id })
              .then(() => {
                next({ path: "punch-tags" });
              });
          }
        }
      },

      {
        path: "tag/edit/:id",
        name: "Edit Tag",
        component: AdminTagForm
      },
      {
        path: "tag/delete/:id",
        name: "Delete Tag",
        beforeEnter: (to, from, next) => {
          if (confirm("Are you sure?")) {
            store
              .dispatch(actions.DELETE_TAG, { id: to.params.id })
              .then(() => {
                next({ path: "tags" });
              });
          }
        }
      },
      {
        path: "manufacturer/new",
        name: "New Manufacturer",
        component: AdminManufacturerForm
      },
      {
        path: "manufacturer/edit/:id",
        name: "Edit Manufacturer",
        component: AdminManufacturerForm
      }
    ]
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(
  (
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) => {
    if (to.matched.some(record => record.meta.preRoute)) {
      to.matched.some(record => record.meta.preRoute(to, from, next));
    } else {
      next();
    }
  }
);

router.beforeEach(
  (
    to: Route,
    from: Route,
    next: (to?: RawLocation | false | ((vm: Vue) => any) | void) => void
  ) => {
    if (store.state.authentication.currentUser !== null && to.path != "/me") {
      const user = store.state.authentication.currentUser;
      const needsPasswordChange = user.needsPasswordChange;
      const passwordCutoffDate = user.changedDate + 1000 * 60 * 60 * 24 * 90;
      const currentDate = new Date().getTime();

      if (user.localLoginEnabled && (needsPasswordChange || passwordCutoffDate <= currentDate)) {
        alert(`You need to change your password`);
        router.push("/me");
        return;
      }
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.authentication.currentUser === null) {
        next({
          path: "/login",
          query: { redirect: to.fullPath === "/logout" ? "/" : to.fullPath }
        });
      } else {
        if (
          to.matched.some(record => {
            return (
              Array.isArray(record.meta.requiresRole) &&
              record.meta.requiresRole.length > 0
            );
          })
        ) {
          let passed = true;
          to.matched.forEach(match => {
            if (
              Array.isArray(match.meta.requiresRole) &&
              match.meta.requiresRole.length > 0
            ) {
              let roleFound = false;
              // console.log("Check for role", match.meta.requiresRole.join(","));
              match.meta.requiresRole.forEach((role: UserRole) => {
                if (store.state.authentication.currentUser!![role]) {
                  roleFound = true;
                }
              });
              if (roleFound === false && passed === true) {
                // console.log("Failed")
                passed = false;
              }
            } else {
              // console.log("Role check not required for match", match);
            }
          });
          if (passed === true) {
            next();
          } else {
            alert("Missing required role!");
            next(false);
          }
        } else {
          next(); // No check for role needed
        }
      }
    } else {
      // console.log("Does not require auth");
      next(); // make sure to always call next()!
    }
  }
);

export default router;
