
import { Component, Vue } from "vue-property-decorator";
import { actions } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { contactTypesForSelect } from "@/store";
import { email, minLength, required } from "vuelidate/lib/validators";
import { validatePhone } from "@/validators";


@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput },
  validations: {
    contact: {
      firstName: { required },
      lastName: { required },
      contactType: { required, minLength: minLength(1) },
      company: { required },
      email: { required, email },
      phone: { required, validatePhone },
      phone2: { validatePhone }
    }
  }
})
export default class AdminContactForm extends Vue {
  get contactTypeSelectData() {
    return contactTypesForSelect();
  }

  data() {
    try {
      const contactId = parseInt(this.$route.params.id);
      for (const contact of this.$store.state.contacts) {
        if (contact.id === contactId) {
          return {
            contact: { ...contact }
          };
        }
      }
      throw "Cannot find contact for id " + contactId;
    } catch (e) {
      return {
        contact: {
          id: null,
          firstName: "",
          lastName: "",
          company: "",
          title: "",
          email: "",
          phone: "",
          phone2: "",
          contactType: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          zip: "",
          notes: ""
        }
      };
    }
  }

  backToList() {
    this.$router.push({ path: "/admin/contacts" });
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }
    this.$store.dispatch(actions.CREATE_OR_UPDATE_CONTACT, {
      toSave: this.$data.contact,
      success: () => this.backToList()
    });
  }
}
