
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TagTable extends Vue {
  apiLocation = Vue.prototype.$apiLocation;

  data() {
    return {
      filter: ""
    };
  }

  get fields() {
    return [
      { key: "tag", sortable: true },
      { key: "description", sortable: true },
      { key: "actions", sortable: false }
    ];
  }

  get tags() {
    return this.$store.state.tags;
  }
}
