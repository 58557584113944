
import { Component, Vue } from "vue-property-decorator";
import { actions, FacilityProfile } from "@/survey";
import FileUploadInput from "@/components/FileUploadInput.vue";
import InputWithLabel from "@/components/InputWithLabel.vue";
import { email, helpers, minLength, required } from "vuelidate/lib/validators";
import store from "@/store";
import { validatePhone } from "@/validators";

@Component({
  components: { InputWithLabel, FileUploadInput: FileUploadInput },
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(store.state.settings.passwordLength),
        letters: helpers.regex("alpha", /[a-z]+/),
        LETTERS: helpers.regex("alpha", /[A-Z]+/),
        numbers: helpers.regex("num", /[0-9]+/),
        special: helpers.regex("special", /[!@#$%^&*/?]+/)
      },
      username: { required },
      firstName: { required },
      lastName: { required },
      facilityProfile: { required },
      email: { required, email },
      phone: { validatePhone }
    }
  }
})
export default class AdminUserForm extends Vue {
  get facilityProfileOptions() {
    const opts: { value: number, text: string }[] = [];
    this.$store.state.facilityProfiles.forEach(function(fp: FacilityProfile) {
      opts.push({ value: fp.id!!, text: fp.name });
    });
    return opts;
  }

  data() {
    try {
      const userId = parseInt(this.$route.params.id);
      for (const user of this.$store.state.users) {
        if (user.id === userId) {
          return {
            user: { ...user, password: "" },
            userImage: null
          };
        }
      }
      throw "Cannot find user for id " + userId;
    } catch (e) {
      return {
        user: {
          id: null,
          username: "",
          password: "",
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          dateCreated: new Date().getTime(),
          active: true,
          create: true,
          edit: false,
          view: true,
          delete: false,
          export: false,
          admin: false,
          localLoginEnabled: true,
          notes: "",
          facilityProfile: 1,
          needsPasswordChange: true
        },
        userImage: null
      };
    }
  }

  onSubmit() {
    this.$v.$touch();
    if (this.$v.$invalid) {
      alert("Cannot save record, see form for error messages");
      return;
    }

    this.$store.dispatch(actions.CREATE_OR_UPDATE_USER, {
      toSave: this.$data.user,
      image: this.$data.userImage,
      success: () => this.$router.push("/admin/users")
    });
  }

  onCancel() {
    this.$router.push("/admin/users");
  }
}
