import moment from "moment";
import { EquipmentType, Facility } from "@/survey";

export const equipmentTypeFormatter = (equipmentTypes: EquipmentType[]) => (
  value: number
) => {
  let name = "None";
  equipmentTypes.forEach(t => {
    if (t.id === value) {
      name = t.name;
    }
  });
  return name;
};
export const booleanYNFormatter = (value: boolean) => {
  if (value === true) return "Y";
  return "N";
};

export const dateFormatter = (format = "MM/DD/YYYY HH:mm") => (
  value: number | Date
) => {
  if(typeof value === "object"){
    value = value.getTime();
  }

  if (typeof value === "number") {
    return moment(new Date(value)).format(format);
  } else {
    return "";
  }
};

export const facilityFormatter = (
  getFacilityForId: (id: number) => Facility | undefined
) => {
  return function(value: number) {
    let facilityName = "Unknown";
    const facility = getFacilityForId(value);
    if (facility !== undefined) {
      facilityName = facility.name;
    }
    return facilityName;
  };
};
