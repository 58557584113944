
import { Component, Vue } from "vue-property-decorator";
import { dateFormatter } from "../../components/formatters";

@Component({
  methods: { dateFormatter },
  components: {}
})
export default class UserInfo extends Vue {
  apiLocation = Vue.prototype.$apiLocation;
  client = Vue.prototype.$surveyClient;
  activity: null | any[] = null;

  get fields() {
    return [
      { key: "user", sortable: true },
      { key: "action", sortable: true },
      { key: "objectType", sortable: true },
      { key: "happenedTimestamp", sortable: true, label: "Date" }
    ];
  }

  data() {
    return { filter: '', };
  }

  get username() {
    return this.$route.params.username;
  }

  async created() {
    const userActivity = await this.client.getUserActivity(this.username);
    this.activity = userActivity;
  }

}
